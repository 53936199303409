<template>
  <div
    class="flexi-container page__internal page__unit-4-part-b"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderFourthUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-4/part-a')"
              class="sub-header__pagination--item"
              v-text="'Previous'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Next'"
              @click="goToRoute('/unit-4/part-c')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 0"
      :sections="unitsSections[0].fourthUnitPartBTabA"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 1"
      :sections="unitsSections[0].fourthUnitPartBTabB"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 2"
      :sections="unitsSections[0].fourthUnitPartBTabC"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 3"
      :sections="unitsSections[0].fourthUnitPartBTabD"
      :activeSection="activeSection"
    />

    <flexi-scrolling-slider
      v-if="inputData.internalPages && itemKey === 4"
      :sections="unitsSections[0].fourthUnitPartBTabE"
      :activeSection="activeSection"
    />

    <!-- ChooseTab Section -->
    <div id="tab-section__scroll" />
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].sections[5]"
      data-aos="fade-right"
      :data-aos-duration="!isMobile ? 1500 : 100"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          @click="chooseTabFun(value.id), selectActiveClass(key)"
          :class="{ active: itemKey === key }"
          class="tab-item"
        >
          <p v-text="value.title" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- Tab A Sections -->

    <!--First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[1]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/LVE_1634752320_video%208.png"
          />
        </div>
        <div class="flexi-wrapper__section--content--paragraph">
          <p v-text="slotProps.inputData.contentTitle" />
          <p v-text="slotProps.inputData.buttonText" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[7]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[8]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-text="slotProps.inputData.contentTitle" />
        </div>

        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionMode="'out-in'"
          :transitionName="'fade'"
          :prevButtonText="''"
          :nextButtonText="''"
        />

        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Fourth Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[17]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 0"
      :inputData="inputData.internalPages[10].sections[2]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[15].footerText" />
              <a
                v-text="slotProps.inputData.widgets[15].buttonText"
                :href="slotProps.inputData.widgets[15].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab B Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 1"
      :inputData="inputData.internalPages[10].sections[3]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--item"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpSecondQuestion"
          class="hidden"
          v-view="popUpSecondQuestion"
        />
        <div id="video2" class="video__wrapper" @click="togglePoster('video2')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/mZc_1634752320_video%209.png"
          />
        </div>
        <div class="flexi-wrapper__section--content--paragraph">
          <p v-text="slotProps.inputData.contentTitle" />
          <p v-text="slotProps.inputData.buttonText" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 1"
      :inputData="inputData.internalPages[10].sections[9]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 1"
      :inputData="inputData.internalPages[10].sections[10]"
      :data-aos="'fade-down'"
      :data-aos-duration="!isMobile ? 1500 : ''"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                v-text="value.subTitle"
                class="content--container__item--subtitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[5].footerText" />
              <a
                v-text="slotProps.inputData.widgets[5].buttonText"
                :href="slotProps.inputData.widgets[5].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab C Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 2"
      :inputData="inputData.internalPages[10].sections[11]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpThirdQuestion"
          class="hidden"
          v-view="popUpThirdQuestion"
        />
      </template>
    </flexi-wrapper>

    <!-- Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 2"
      :inputData="inputData.internalPages[10].sections[13]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <!-- Vocabulary Section -->
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                v-text="value.subTitle"
                class="content--container__item--subtitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[7].footerText" />
              <a
                v-text="slotProps.inputData.widgets[7].buttonText"
                :href="slotProps.inputData.widgets[7].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab D Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 3"
      :inputData="inputData.internalPages[10].sections[14]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpFourthQuestion"
          class="hidden"
          v-view="popUpFourthQuestion"
        />
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 3"
      :inputData="inputData.internalPages[10].sections[4]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 3"
      :inputData="inputData.internalPages[10].sections[6]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                v-text="value.subTitle"
                class="content--container__item--subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[6].footerText" />
              <a
                v-text="slotProps.inputData.widgets[6].buttonText"
                :href="slotProps.inputData.widgets[6].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab E Sections -->

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages && itemKey === 4"
      :inputData="inputData.internalPages[10].sections[15]"
      data-aos="fade-left"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="flexi-wrapper__section--content--paragraph"
        >
          <p v-html="value.paragraph" />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Tab Button -->
    <div id="tab-buttons">
      <div class="bottom-buttons">
        <button
          v-if="itemKey !== 0"
          v-text="textPrev()"
          @click="redirectPrev"
          class="flexi-btn"
        />
        <button
          v-if="itemKey !== 4"
          v-text="textNext()"
          @click="redirectNext"
          class="flexi-btn"
        />
      </div>
    </div>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[10].sections[0]"
    >
      <template v-slot:content="slotProps">
        <div class="bottom-buttons even">
          <div
            v-for="(value, key) in slotProps.inputData.widgets"
            :key="key"
            class="bottom-buttons"
          >
            <flexi-button
              :text="value.buttonText"
              @button-clicked="goToRoute(value.buttonLink)"
              class="flexi-btn"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content" :class="additionalModalClass">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[7]"
            @closeModal="closeModal"
          />
          <flexi-pop-up-question-multi
            v-if="showPopUpSecondQuestion"
            :question="inputData.quizzes[3].questions[8]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
          <flexi-pop-up-question-multi
            v-if="showPopUpThirdQuestion"
            :question="inputData.quizzes[3].questions[9]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
          <flexi-pop-up-question-multi
            v-if="showPopUpFourthQuestion"
            :question="inputData.quizzes[3].questions[10]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderFourthUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiPopUpQuestionMulti from "../../../components/FlexiPopUpQuestionMulti.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiSlider from "../../../components/FlexiSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "VERY VERIFIED - Unit 4. Misinformation and Manipulation",
      meta: [
        {
          name: "description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        {
          name: "og:title",
          content: "VERY VERIFIED - Unit 4. Misinformation and Manipulation"
        },
        {
          name: "og:description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/lvi_1638502006_seo_ks_u4b.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED - Unit 4. Misinformation and Manipulation"
        },
        {
          name: "twitter:description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/lvi_1638502006_seo_ks_u4b.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiPopUpQuestionMulti,
    FlexiScrollingSlider,
    FlexiSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      answeredPopUpSecondQuestion: false,
      answeredPopUpThirdQuestion: false,
      answeredPopUpFourthQuestion: false,
      chooseTab: "Headlines and manipulated photos",
      itemKey: 0,
      openContent: false,
      showPopUpFirstQuestion: false,
      showPopUpSecondQuestion: false,
      showPopUpThirdQuestion: false,
      showPopUpFourthQuestion: false,
      subHeaderFourthUnit: subHeaderFourthUnit,
      subHeaderKey: 1
    };
  },
  methods: {
    addModalClass(boolean) {
      if (boolean) {
        this.additionalModalClass = "correct-modal-score";
      } else {
        this.additionalModalClass = "wrong-modal-score";
      }
    },
    chooseTabFun(txt) {
      this.chooseTab = txt;
    },
    closeModal(data) {
      if (this.showPopUpFirstQuestion === true) {
        this.answeredPopUpFirstQuestion = true;
        location.hash = "#tab-a-headlines";
      } else if (this.showPopUpSecondQuestion === true) {
        this.answeredPopUpSecondQuestion = true;
        location.hash = "#tab-b-bots-and-trolls";
      } else if (this.showPopUpThirdQuestion === true) {
        this.answeredPopUpThirdQuestion = true;
        location.hash = "#tab-c-fake-expert-opinion";
      } else if (this.showPopUpFourthQuestion === true) {
        this.answeredPopUpFourthQuestion = true;
        location.hash = "#tab-d-manipulation-with-the-original-source";
      }

      if (!this.score.scorePogress.unit4PartB) {
        this.setSelectedAnswersPopUp(data);
      }

      this.$router.push("/unit-4/part-b");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit4PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpSecondQuestion = false;
            this.showPopUpThirdQuestion = false;
            this.showPopUpFourthQuestion = false;
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpSecondQuestion(e) {
      if (!this.score.scorePogress.unit4PartB) {
        if (!this.answeredPopUpSecondQuestion) {
          if (e.percentTop > 0.608 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpThirdQuestion = false;
            this.showPopUpFourthQuestion = false;
            this.showPopUpSecondQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpThirdQuestion(e) {
      if (!this.score.scorePogress.unit4PartB) {
        if (!this.answeredPopUpThirdQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpSecondQuestion = false;
            this.showPopUpFourthQuestion = false;
            this.showPopUpThirdQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    popUpFourthQuestion(e) {
      if (!this.score.scorePogress.unit4PartB) {
        if (!this.answeredPopUpFourthQuestion) {
          if (e.percentTop > 0.508 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = false;
            this.showPopUpSecondQuestion = false;
            this.showPopUpThirdQuestion = false;
            this.showPopUpFourthQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    },
    textPrev() {
      if (this.itemKey === 1) {
        return "Headlines & Manipulated Photos & Videos";
      } else if (this.itemKey === 2) {
        return "Bots & Trolls";
      } else if (this.itemKey === 3) {
        return "Fake Expert Opinion";
      } else if (this.itemKey === 4) {
        return "Manipulation with the Original Source & Advertorial";
      }
    },
    textNext() {
      if (this.itemKey === 0) {
        return "Bots & Trolls";
      } else if (this.itemKey === 1) {
        return "Fake Expert Opinion";
      } else if (this.itemKey === 2) {
        return "Manipulation with the Original Source & Advertorial";
      } else if (this.itemKey === 3) {
        return "Fact-checking Tools";
      }
    },
    redirectPrev() {
      this.smoothScroll("tab-section__scroll");
      if (this.itemKey === 1) {
        this.itemKey = 0;
      } else if (this.itemKey === 2) {
        this.itemKey = 1;
      } else if (this.itemKey === 3) {
        this.itemKey = 2;
      } else if (this.itemKey === 4) {
        this.itemKey = 3;
      }
    },
    redirectNext() {
      this.smoothScroll("tab-section__scroll");
      if (this.itemKey === 0) {
        this.itemKey = 1;
      } else if (this.itemKey === 1) {
        this.itemKey = 2;
      } else if (this.itemKey === 2) {
        this.itemKey = 3;
      } else if (this.itemKey === 3) {
        this.itemKey = 4;
      }
    },
    selectActiveClass(key) {
      this.itemKey = key;
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fourthUnitPartBTabA[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      this.answeredPopUpSecondQuestion &&
      this.answeredPopUpThirdQuestion &&
      this.answeredPopUpFourthQuestion &&
      !this.score.scorePogress.unit4PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit4PartB");
      this.setLastUnit("/unit-4/part-c");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
