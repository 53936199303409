export const subHeaderSecondUnit = [
  {
    name: "Ownership",
    href: "/unit-2/part-a",
    isPage: false
  },
  {
    name: "Agenda Setting",
    href: "/unit-2/part-b",
    isPage: false
  }
];

export const subHeaderThirdUnit = [
  {
    name: "How social media works",
    href: "/unit-3/part-a",
    isPage: false
  },
  {
    name: "Cognitive Distortions",
    href: "/unit-3/part-b",
    isPage: false
  }
];

export const subHeaderFourthUnit = [
  {
    name: "What is Disinformation?",
    href: "/unit-4/part-a",
    isPage: false
  },
  {
    name: "Manipulation",
    href: "/unit-4/part-b",
    isPage: false
  },
  {
    name: "Stereotypes and hate speech",
    href: "/unit-4/part-c",
    isPage: false
  },
  {
    name: "Mean World Syndrome",
    href: "/unit-4/part-d",
    isPage: false
  }
];
