import Vue from "vue";
import VeeValidate from "vee-validate";
import certificateValidator from "./uniqueCertificate";
import emailValidator from "./uniqueEMail";

Vue.use(VeeValidate);
certificateValidator.init();
emailValidator.init();

VeeValidate.Validator.localize({
  en: {
    messages: {
      required: () => "*This field is required",
      email: () => "*Email must be valid",
      min: () => "*This field must contain at least 1 character"
    }
  }
});
