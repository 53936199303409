<template>
  <div
    class="flexi-container page__internal page__unit-2-part-b"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderSecondUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-2/part-a')"
              class="sub-header__pagination--item"
              v-text="'Previous'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Next'"
              @click="goToRoute('/unit-3')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].secondUnitPartB"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[0]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
        <div
          class="flexi-wrapper__section--description"
          v-html="slotProps.inputData.description"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--description"
          v-html="slotProps.inputData.widgets[0].description"
        />
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div
          class="flexi-wrapper__section--content--paragraph"
          v-html="slotProps.inputData.widgets[0].paragraph"
        />
      </template>
    </flexi-wrapper>

    <!-- Third Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[2]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--title"
          v-text="slotProps.inputData.title"
        />
      </template>

      <template v-slot:content="slotProps">
        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionMode="'out-in'"
          :transitionName="'fade'"
          :prevButtonText="''"
          :nextButtonText="''"
        />

        <p
          v-html="inputData.internalPages[3].sections[2].description"
          class="flexi-wrapper__section--content--description"
        />
      </template>
    </flexi-wrapper>

    <!-- Fourth Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[3]"
      data-aos="fade-down"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
        <div
          class="flexi-wrapper__section--description"
          v-html="slotProps.inputData.description"
        />
      </template>
    </flexi-wrapper>

    <!-- Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[5]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[22].footerHeader" />
              <a
                v-text="slotProps.inputData.widgets[22].footerText"
                :href="slotProps.inputData.widgets[22].email"
              />
              <a
                v-text="slotProps.inputData.widgets[22].buttonText"
                :href="slotProps.inputData.widgets[22].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[3].sections[6]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[3]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderSecondUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";
import FlexiSlider from "../../../components/FlexiSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "VERY VERIFIED - Unit 2. Ownership and editorial policies",
      meta: [
        {
          name: "description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        {
          name: "og:title",
          content: "VERY VERIFIED - Unit 2. Ownership and editorial policies"
        },
        {
          name: "og:description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/Har_1638501998_seo_ks_u2b.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED - Unit 2. Ownership and editorial policies"
        },
        {
          name: "twitter:description",
          content:
            "What are editorial policies and editorial firewalls? Learn who determines which stories are covered in the media, and in what format."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/Har_1638501998_seo_ks_u2b.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiScrollingSlider,
    FlexiSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      subHeaderKey: 1,
      subHeaderSecondUnit: subHeaderSecondUnit
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit2PartB) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#how-does-a-story";
      this.$router.push("/unit-2/part-b");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit2PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].secondUnitPartB[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit2PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit2PartB");
      this.setLastUnit("/unit-3/part-a");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
