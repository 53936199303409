<template>
  <div
    class="flexi-container page__internal page__unit-3-part-b"
    v-show="showPage"
  >
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderThirdUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-3/part-a')"
              class="sub-header__pagination--item"
              v-text="'Previous'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Next'"
              @click="goToRoute('/unit-4')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].thirdUnitPartB"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p v-html="slotProps.inputData.description" />
        </div>
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <div id="video1" class="video__wrapper" @click="togglePoster('video1')">
          <iframe
            width="75%"
            height="515"
            :src="
              'https://www.youtube.com/embed/' + slotProps.inputData.contentLine
            "
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <img
            src="https://app.imgforce.com/images/user/uh2_1634752320_video%207.png"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[1]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
      </template>
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--description"
          v-html="slotProps.inputData.description"
          data-aos="fade-down"
          :data-aos-duration="!isMobile ? 1500 : 1000"
        />

        <div
          class="flexi-wrapper__section--content--content-title"
          v-html="slotProps.inputData.contentTitle"
        />

        <flexi-slider
          :inputData="slotProps.inputData.widgets"
          :turnTimer="false"
          :transitionMode="'out-in'"
          :transitionName="'fade'"
          :prevButtonText="''"
          :nextButtonText="''"
        >
          <p
            class="flexi-wrapper__section--content--content-paragraph"
            v-text="slotProps.inputData.paragraph"
          />
        </flexi-slider>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[2]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[6].footerText" />
              <a
                v-text="slotProps.inputData.widgets[6].buttonLink"
                :href="slotProps.inputData.widgets[6].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[6].sections[3]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content">
          <flexi-pop-up-question
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[5]"
            @closeModal="closeModal"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderThirdUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestion from "../../../components/FlexiPopUpQuestion.vue";
import FlexiSlider from "../../../components/FlexiSlider.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";

export default {
  name: "part-b",
  metaInfo() {
    return {
      title: "VERY VERIFIED - Unit 3. Social Media",
      meta: [
        {
          name: "description",
          content:
            "Who distorts the original meaning of content? Why can people be manipulated so easily? How does our mind work when we interact with information? What are the types of cognitive bias, and how can we overcome them?"
        },
        {
          name: "og:title",
          content: "VERY VERIFIED - Unit 3. Social Media"
        },
        {
          name: "og:description",
          content:
            "Who distorts the original meaning of content? Why can people be manipulated so easily? How does our mind work when we interact with information? What are the types of cognitive bias, and how can we overcome them?"
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/QYD_1638501998_seo_ks_u3b.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED - Unit 3. Social Media"
        },
        {
          name: "twitter:description",
          content:
            "Who distorts the original meaning of content? Why can people be manipulated so easily? How does our mind work when we interact with information? What are the types of cognitive bias, and how can we overcome them?"
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/QYD_1638501998_seo_ks_u3b.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestion,
    FlexiSlider,
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      answeredPopUpFirstQuestion: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      subHeaderKey: 1,
      subHeaderThirdUnit: subHeaderThirdUnit
    };
  },
  methods: {
    closeModal(data) {
      if (!this.score.scorePogress.unit3PartB) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#cognitive-distortions";
      this.$router.push("/unit-3/part-b");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit3PartB) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].thirdUnitPartB[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit3PartB
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit3PartB");
      this.setLastUnit("/unit-4/part-a");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
