<template>
  <div class="flexi-container page__internal page__final" v-show="showPage">
    <!-- Top Card / Subheader -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[13].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <span v-text="'Final test'" class="sub-header__unit" />
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-5')"
              class="sub-header__pagination--item"
              v-text="'E mëparshme.'"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.internalPages[13].sections[0]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div
          class="flexi-wrapper__section--content--block"
          v-html="slotProps.inputData.description"
        />
        <flexi-scorring-quiz :inputData="inputData.quizzes[3]" />
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[13].sections[1]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlexiScorringQuiz from "../components/FlexiScorringQuiz.vue";

export default {
  name: "final-test",
  metaInfo() {
    return {
      title: "VERY VERIFIED",
      meta: [
        {
          name: "description",
          content: "Online course on media literacy"
        },
        {
          name: "og:title",
          content: "VERY VERIFIED"
        },
        {
          name: "og:description",
          content: "Online course on media literacy"
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/lI0_1638501999_seo_ks_1.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED"
        },
        {
          name: "twitter:description",
          content: "Online course on media literacy"
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/lI0_1638501999_seo_ks_1.png"
        }
      ]
    };
  },
  components: {
    FlexiScorringQuiz
  },
  computed: {
    ...mapGetters(["inputData", "language", "user", "isMobile", "score"])
  },
  data() {
    return {
      openContent: false
    };
  },
  methods: {},
  mounted() {
    if (!this.user) {
      this.showPage = false;
      location.href = "#notregistered";
    } else if (this.score.progress < 70) {
      this.showPage = false;
      location.href = "#warning";
    } else {
      this.showPage = true;
    }
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  }
};
</script>
