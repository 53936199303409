export const unitsSections = [
  {
    firstUnit: [
      {
        title: "Welcome to the Course",
        string_id: "welcome-to-the-course",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Information Ecosystem",
        string_id: "information-ecosystem",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png"
      },
      {
        title: "Types of Content",
        string_id: "types-of-content",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png"
      },
      {
        title: "Facts vs Opinion",
        string_id: "facts-vs-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Consumption Log",
        string_id: "consumption-log",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png"
      },
      {
        title: "Name it to Tame it",
        string_id: "name-it-to-tame-it",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -50
      }
    ],
    secondUnitPartA: [
      {
        title: "Types of traditional media",
        string_id: "types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Ownership: Public, Private, and NGO Media",
        string_id: "private-non-private-media",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -550
      },
      {
        title: "Ownership: Public Broadcasting",
        string_id: "private-online-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Who owns the media in Kosovo",
        string_id: "what-types-of-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -100
      }
    ],
    secondUnitPartB: [
      {
        title: "How Does a Story Get to Us??",
        string_id: "how-does-a-story",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Journalistic Standards",
        string_id: "journalistic-standards",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Freedom of Press and Censorship",
        string_id: "freedom-of-press",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: 0
      }
    ],
    thirdUnitPartA: [
      {
        title: "Social media as a newssource",
        string_id: "social-media-as-a-newssource",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Personalized Algorithms and Filter Bubbles",
        string_id: "personalized-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -100
      },
      {
        title: "Quiz: Algorithms in social media",
        string_id: "quiz-algorithms",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "TED Talk: Beware Online Filter Bubbles",
        string_id: "ted-talk",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    thirdUnitPartB: [
      {
        title: "Cognitive distortions",
        string_id: "cognitive-distortions",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Common Biases",
        string_id: "common-biases",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartA: [
      {
        title: "Types of Information Disorder",
        string_id: "types-of-information-disorder",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Attention Hacking",
        string_id: "attention-hacking",
        img: "https://irex.flexicms.tech/images/cms/1634166987_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabA: [
      {
        title: "Headlines & Manipulated Photos & Videos",
        string_id: "tab-a-headlines",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -300
      },
      {
        title: "Headlines: Things to Look Out For",
        string_id: "tab-a-headlines-things-to-look-out-for",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -50
      },
      {
        title: "Manipulated Photos – Watch Out!",
        string_id: "tab-a-manipulated-photos-watch-out",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "There are three different types of photo manipulation:",
        string_id: "tab-a-types-of-manipulation",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartBTabB: [
      {
        title: "Bots & Trolls",
        string_id: "tab-b-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -500
      },
      {
        title: "Life Hacks: How to Detect Bots and Trolls",
        string_id: "tab-b-life-hacks-how-to-detect-bots-and-trolls",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -250
      }
    ],
    fourthUnitPartBTabC: [
      {
        title: "Fake Expert Opinion",
        string_id: "tab-c-fake-expert-opinion",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -300
      }
    ],
    fourthUnitPartBTabD: [
      {
        title: "Manipulation with the Original Source & Advertorial",
        string_id: "tab-d-manipulation-with-the-original-source",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -150
      },
      {
        title: "Advertorial",
        string_id: "tab-d-manipulation-in-social-media",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -50
      }
    ],
    fourthUnitPartBTabE: [
      {
        title: "Fact-checking Tools",
        string_id: "tab-e-fact-checking-tools",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      }
    ],
    fourthUnitPartC: [
      {
        title: "Hate speech vs Freedom of speech",
        string_id: "hate-speach",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "How to Report Hate Speech Online",
        string_id: "how-to-report-hate-speech",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -200
      },
      {
        title: "Quiz: Inclusive Language",
        string_id: "quizz-inclusive-language",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: -150
      }
    ],
    fourthUnitPartD: [
      {
        title: "What is Mean World Syndrome?",
        string_id: "what-is-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Case study",
        string_id: "the-fear-instinct",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: 0
      },
      {
        title: "Are We in Control of Our Decisions?",
        string_id: "what-should-we-do-to-overcome-mean-world-syndrome",
        img: "https://irex.flexicms.tech/images/cms/1634166999_flexi-cms.png",
        yOffSet: 0
      }
    ],
    fifthUnit: [
      {
        title: "Five tips to protect yourself from misinformation",
        string_id: "five-tips-to-protect-yourself",
        img: "https://irex.flexicms.tech/images/cms/1634166973_flexi-cms.png",
        yOffSet: -400
      },
      {
        title: "Keep in Mind!",
        string_id: "keep-in-mind",
        img: "https://irex.flexicms.tech/images/cms/1634166960_flexi-cms.png",
        yOffSet: -150
      }
    ]
  }
];
