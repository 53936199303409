<template>
  <div id="app">
    <!-- Entering, reload site transition -->
    <div class="app-wrapper">
      <!-- Header -->
      <flexi-header
        :inputData="inputData.navigationPages"
        :toggleTheme="toggleTheme"
        :changeLanguage="setLanguage"
        :navigateToRoute="smoothScroll"
      >
        <template v-slot:navigation="slotProps">
          <flexi-desktop-navigation
            v-if="currentRoute === 'home'"
            :navigation="slotProps.inputData"
            :navigateToExternalPage="slotProps.navigateToExternalPage"
            :navigateToRoute="slotProps.navigateToRoute"
            class="navigation-home"
          >
          </flexi-desktop-navigation>
          <flexi-desktop-navigation
            v-else
            :navigation="slotProps.inputData"
            :navigateToExternalPage="slotProps.navigateToExternalPage"
            :navigateToRoute="slotProps.navigateToRoute"
          >
            <template v-slot:navigation>
              <a
                class="flexi-desktop-navigation__wrapper--item"
                v-for="(navigationItem, index) in unitsNavigation"
                :key="'item-' + index"
              >
                <li
                  class="title"
                  v-text="navigationItem.name"
                  @mouseover="openDropdown(navigationItem.id)"
                  @click="goToRoute(navigationItem.href)"
                  @mouseleave="checkToCloseDropdown"
                />
                <ul
                  class="dropdown__wrapper"
                  v-if="
                    showDropdown &&
                      selectedMenuId === navigationItem.id &&
                      navigationItem.dropdown_menu
                  "
                  @mouseleave="showDropdown = false"
                >
                  <li
                    class="dropdown__wrapper--item"
                    v-for="(submenuItem,
                    submenuIndex) in navigationItem.dropdown_menu"
                    :key="submenuIndex"
                    v-text="submenuItem.name"
                    @click="goToRoute(submenuItem.href)"
                  />
                </ul>
              </a>
            </template>
          </flexi-desktop-navigation>
        </template>

        <template v-slot:mobile="slotProps">
          <flexi-mobile-navigation
            :navigation="unitsNavigation"
            :isNavOpen="isNavigationOpen"
            :toggleBurger="toggleBurgerMenu"
            :navigateToExternalPage="slotProps.navigateToExternalPage"
            :navigateToRoute="goToMobileRoute"
          />
        </template>

        <template v-slot:user>
          <font-awesome-icon
            v-if="user"
            icon="user-graduate"
            size="2x"
            v-tooltip="user.email"
            @mouseover="showLogOutDropDown = true"
          />
          <ul
            class="dropdown__wrapper--logout"
            v-if="showLogOutDropDown"
            @mouseleave="showLogOutDropDown = false"
          >
            <li
              class="dropdown__wrapper--logout--item"
              v-text="'Logout'"
              @click="logout"
            />
          </ul>
        </template>
      </flexi-header>

      <div v-if="currentRoute !== 'home'" class="score__wrapper">
        <span
          v-if="user"
          class="score__wrapper--item"
          v-text="score.progress + '%'"
        />
      </div>
      <!-- Router View + Transition -->
      <vue-page-transition name="overlay-up-down">
        <router-view />
      </vue-page-transition>
      <!-- Footer -->
      <flexi-footer
        v-if="inputData"
        :year="year"
        :inputData="inputData.homePage"
      >
        <template v-slot:footer="slotProps">
          <div
            v-if="slotProps.inputData.last_card"
            class="footer__wrapper--content"
          >
            <div class="title__wrapper">
              <div
                class="title__wrapper--item"
                v-text="slotProps.inputData.last_card.subTitle"
              />
              <div class="footer-logo">
                <img class="logo" src="./assets/img/logo.png" alt="IREX_logo" />
              </div>
              <div
                class="title__wrapper--item"
                v-text="slotProps.inputData.last_card.buttonLink"
              />
              <div class="footer-logo">
                <img
                  class="logo us-flag"
                  src="./assets/img/us_flag.png"
                  alt="US_flag"
                />
              </div>
            </div>
            <div class="footer-text">
              <div>
                <p
                  v-if="slotProps.inputData.last_card"
                  v-text="slotProps.inputData.last_card.paragraphHead"
                />
              </div>
              <div
                v-if="slotProps.inputData.last_card"
                v-html="slotProps.inputData.last_card.paragraph"
              />
            </div>
          </div>
          <div
            v-if="slotProps.inputData.last_card"
            class="footer__wrapper--navigation"
          >
            <div class="footer-btn">
              <button
                @click="openModal"
                v-text="slotProps.inputData.last_card.buttonText"
              />
            </div>
          </div>
          <div class="footer__wrapper--bottom">
            <div class="item-left">
              <span>© {{ year }} All Rights Reserved </span>
            </div>
            <div class="item-right">
              <span>
                Made by
                <a
                  class="footer-link"
                  href="https://akrinum.com"
                  target="_blank"
                  >Akrinum OÜ</a
                >
              </span>
            </div>
          </div>
        </template>
      </flexi-footer>
      <!-- Register Modal -->
      <flexi-modal
        :name="'register'"
        :modalData="modal"
        :modalArray="modalArrayData"
        :currentIndex="currentModalId"
      >
        <template v-slot:content>
          <transition name="fade" mode="in-out">
            <div v-if="!successRegistration" class="flexi-modal__content">
              <span
                class="flexi-modal__content--title"
                v-if="language === 'AL'"
                v-text="'Registration'"
              />
              <form
                novalidate
                data-vv-scope="modal-form"
                id="modal-form"
                @submit.prevent="registerClient"
              >
                <div class="modal-input">
                  <input
                    @paste.prevent
                    v-model="modalForm.email"
                    v-validate="{
                      required: true,
                      email: true,
                      uniqueEmail: true
                    }"
                    :class="{ 'valid-input': errors.has('modal-form.Email') }"
                    class="form-field__wrapper--input"
                    type="text"
                    name="Email"
                    placeholder="Your e-mail"
                    required
                  />
                  <validation-message
                    :message="errors.first('modal-form.Email')"
                    :show="errors.has('modal-form.Email')"
                  />
                </div>
                <div class="modal-input">
                  <input
                    @paste.prevent
                    v-model="modalForm.password"
                    v-validate="{
                      required: true,
                      min: 4,
                      max: 100
                    }"
                    :class="{
                      'valid-input': errors.has('modal-form.Password')
                    }"
                    class="form-field__wrapper--input"
                    type="password"
                    name="Password"
                    placeholder="Password"
                    required
                  />
                  <validation-message
                    :message="errors.first('modal-form.Password')"
                    :show="errors.has('modal-form.Password')"
                  />
                </div>
                <button class="flexi-btn">
                  <span v-if="!processing">Register!</span>
                  <spring-spinner
                    class="spinner"
                    v-else
                    :animation-duration="2000"
                    :size="25"
                    color="#fc5000"
                  />
                </button>
              </form>
            </div>
            <div v-else class="flexi-modal__content">
              <div class="checkmark">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 161.2 161.2"
                  enable-background="new 0 0 161.2 161.2"
                  xml:space="preserve"
                >
                  <path
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-miterlimit="10"
                    d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
    c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
    c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                  />
                  <circle
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    cx="80.6"
                    cy="80.6"
                    r="62.1"
                  />
                  <polyline
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="113,52.8 
    74.1,108.4 48.2,86.4 "
                  />

                  <circle
                    class="spin"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    stroke-dasharray="12.2175,12.2175"
                    cx="80.6"
                    cy="80.6"
                    r="73.9"
                  />
                </svg>

                <p>Successful registration</p>
              </div>
            </div>
          </transition>
          <div class="flexi-modal__content--pagination">
            <a @click="closeLoginModal" class="close" />
          </div>
        </template>
      </flexi-modal>
      <!-- Login Modal -->
      <flexi-modal
        :name="'login'"
        :modalData="modal"
        :modalArray="modalArrayData"
        :currentIndex="currentModalId"
      >
        <template v-slot:content>
          <transition name="fade" mode="in-out">
            <div v-if="!successLogin" class="flexi-modal__content">
              <span
                class="flexi-modal__content--title"
                v-if="language === 'AL'"
                v-text="'Login'"
              />
              <form
                novalidate
                data-vv-scope="login-form"
                id="login-form"
                @submit.prevent="loginClient"
              >
                <div class="modal-input">
                  <input
                    @paste.prevent
                    v-model="modalForm.email"
                    v-validate="{
                      required: true,
                      email: true
                    }"
                    :class="{ 'valid-input': errors.has('login-form.Email') }"
                    class="form-field__wrapper--input"
                    type="text"
                    name="Email"
                    placeholder="Enter your email"
                    required
                  />
                  <validation-message
                    :message="errors.first('login-form.Email')"
                    :show="errors.has('login-form.Email')"
                  />
                </div>
                <div class="modal-input">
                  <input
                    @paste.prevent
                    v-model="modalForm.password"
                    v-validate="{
                      required: true,
                      min: 4,
                      max: 100
                    }"
                    :class="{
                      'valid-input': errors.has('login-form.Password')
                    }"
                    class="form-field__wrapper--input"
                    type="password"
                    name="Password"
                    placeholder="Password"
                    required
                  />
                  <validation-message
                    :message="errors.first('login-form.Password')"
                    :show="errors.has('login-form.Password')"
                  />
                </div>
                <div class="forget" @click="openResetPasswordModal()">Forget your password?</div>
                <button class="flexi-btn">
                  <span v-if="!loginProcessing">Log in!</span>
                  <spring-spinner
                    class="spinner"
                    v-else
                    :animation-duration="2000"
                    :size="25"
                    color="#fc5000"
                  />
                </button>
              </form>

              <div class="warning-login__wrapper" v-if="showLoginWarning">
                <span
                  class="warning-login__wrapper--item"
                  v-text="'Please check your credentials!'"
                />
              </div>
            </div>
            <div v-else class="flexi-modal__content">
              <div class="checkmark">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 161.2 161.2"
                  enable-background="new 0 0 161.2 161.2"
                  xml:space="preserve"
                >
                  <path
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-miterlimit="10"
                    d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
    c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
    c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                  />
                  <circle
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    cx="80.6"
                    cy="80.6"
                    r="62.1"
                  />
                  <polyline
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="113,52.8 
    74.1,108.4 48.2,86.4 "
                  />

                  <circle
                    class="spin"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    stroke-dasharray="12.2175,12.2175"
                    cx="80.6"
                    cy="80.6"
                    r="73.9"
                  />
                </svg>

                <p>Hyrja e suksesshme!</p>
              </div>
            </div>
          </transition>
          <div class="flexi-modal__content--pagination">
            <a @click="closeLoginModal" class="close" />
          </div>
        </template>
      </flexi-modal>
      <!-- Warning on Final Test Modal -->
      <flexi-modal
        :name="'warning'"
        :modalData="modal"
        :modalArray="modalArrayData"
        :currentIndex="currentModalId"
      >
        <template v-slot:content>
          <div class="flexi-modal__content">
            <span
              class="flexi-modal__content--title"
              v-if="language === 'AL'"
              v-text="
                'Please pass at least 70% of the units to complete the final test!'
              "
            />
          </div>
          <div class="flexi-modal__content--pagination">
            <a @click="closeWarningModal" class="close" />
          </div>
        </template>
      </flexi-modal>
      <!-- Not Registered Final Test Modal -->
      <flexi-modal
        :name="'notregistered'"
        :modalData="modal"
        :modalArray="modalArrayData"
        :currentIndex="currentModalId"
      >
        <template v-slot:content>
          <div class="flexi-modal__content">
            <span
              class="flexi-modal__content--title"
              v-if="language === 'AL'"
              v-text="'You must be a registered user to receive a certificate!'"
            />
          </div>
          <div class="flexi-modal__content--pagination">
            <a @click="closeNotRegisteredModal" class="close" />
          </div>
        </template>
      </flexi-modal>
      <!-- Reset Password Modal -->
      <flexi-modal
        :name="'reset'"
        :modalData="modal"
        :modalArray="modalArrayData"
        :currentIndex="currentModalId"
      >
        <template v-slot:content>
          <transition name="fade" mode="in-out">
            <div v-if="!successReset" class="flexi-modal__content">
              <span
                class="flexi-modal__content--title"
                v-if="language === 'AL'"
                v-text="'Reset Password'"
              />
              <form
                novalidate
                data-vv-scope="reset-form"
                id="reset-form"
                @submit.prevent="resetPassword"
              >
                <div class="modal-input reset">
                  <input
                    @paste.prevent
                    v-model="modalForm.email"
                    v-validate="{
                      required: true,
                      email: true
                    }"
                    :class="{ 'valid-input': errors.has('reset-form.Email') }"
                    class="form-field__wrapper--input"
                    type="text"
                    name="Email"
                    placeholder="Enter your email"
                    required
                  />
                  <validation-message
                    :message="errors.first('reset-form.Email')"
                    :show="errors.has('reset-form.Email')"
                  />
                </div>
                <button class="flexi-btn">
                  <span v-if="!loginProcessing">Reset password</span>
                  <spring-spinner
                    class="spinner"
                    v-else
                    :animation-duration="2000"
                    :size="25"
                    color="#fc5000"
                  />
                </button>
              </form>

              <div class="warning-login__wrapper" v-if="showResetWarning">
                <span
                  class="warning-login__wrapper--item"
                  v-text="'Incorrect email address!'"
                />
              </div>
            </div>
            <div v-else class="flexi-modal__content">
              <div class="checkmark">
                <svg
                  version="1.1"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 161.2 161.2"
                  enable-background="new 0 0 161.2 161.2"
                  xml:space="preserve"
                >
                  <path
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-miterlimit="10"
                    d="M425.9,52.1L425.9,52.1c-2.2-2.6-6-2.6-8.3-0.1l-42.7,46.2l-14.3-16.4
    c-2.3-2.7-6.2-2.7-8.6-0.1c-1.9,2.1-2,5.6-0.1,7.7l17.6,20.3c0.2,0.3,0.4,0.6,0.6,0.9c1.8,2,4.4,2.5,6.6,1.4c0.7-0.3,1.4-0.8,2-1.5
    c0.3-0.3,0.5-0.6,0.7-0.9l46.3-50.1C427.7,57.5,427.7,54.2,425.9,52.1z"
                  />
                  <circle
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    cx="80.6"
                    cy="80.6"
                    r="62.1"
                  />
                  <polyline
                    class="path"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="6"
                    stroke-linecap="round"
                    stroke-miterlimit="10"
                    points="113,52.8 
    74.1,108.4 48.2,86.4 "
                  />

                  <circle
                    class="spin"
                    fill="none"
                    stroke="#7DB0D5"
                    stroke-width="4"
                    stroke-miterlimit="10"
                    stroke-dasharray="12.2175,12.2175"
                    cx="80.6"
                    cy="80.6"
                    r="73.9"
                  />
                </svg>

                
              </div>
              <p>Reset link has been successfull sent. Please check your inbox or spam folder.</p>
            </div>
          </transition>
          <div class="flexi-modal__content--pagination">
            <a @click="closeResetModal" class="close" />
          </div>
        </template>
      </flexi-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import FlexiHeader from "./components/FlexiHeader";
import { unitsNavigation } from "./static/data/unitsNavigation";

export default {
  components: {
    FlexiHeader
  },
  watch: {
    $route: {
      handler(to, from) {
        this.currentRoute = to.name;
        if (
          to.hash === "#register" ||
          to.hash === "#login" ||
          to.hash === "#pop-up-question" ||
          from.hash === "#register" ||
          from.hash === "#login" ||
          from.hash === "#pop-up-question" ||
          from.hash === "#reset"
        ) {
          document.querySelector(".flexi-header__wrapper").style.opacity = "1";
          document.querySelector(".footer__wrapper").style.opacity = "1";
          document.querySelector(".flexi-container").style.opacity = "1";
        } else {
          document.querySelector(".flexi-header__wrapper").style.opacity = "0";
          document.querySelector(".footer__wrapper").style.opacity = "0";
          document.querySelector(".flexi-container").style.opacity = "0";
          setTimeout(() => {
            this.animateTopCard();
          }, 1000);
        }

        document.querySelector(".score__wrapper").style.opacity = "0";
      },
      immediate: true,
      deep: true
    },
    windowWidth: {
      handler(newValue) {
        newValue <= 760 ? this.setMobile(true) : this.setMobile(false);
      },
      immediate: true
    }
  },
  data() {
    return {
      currentRoute: "",
      modalForm: {
        email: null,
        password: null
      },
      processing: false,
      loginProcessing: false,
      resetProcessing: false,
      showDropdown: false,
      showLogOutDropDown: false,
      showLoginWarning: false,
      showResetWarning: false,
      selectedMenuId: null,
      successRegistration: false,
      successLogin: false,
      successReset: false,
      unitsNavigation: unitsNavigation,
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapGetters([
      "inputData",
      "loadingScreen",
      "year",
      "theme",
      "language",
      "isMobile",
      "score",
      "user"
    ])
  },
  methods: {
    ...mapActions([
      "fetchData",
      "login",
      "restartScore",
      "register",
      "reset",
      "setYear",
      "setLanguage",
      "setMobile",
      "toggleTheme",
      "setUser",
      "getUser"
    ]),
    checkToCloseDropdown(e) {
      if (e.clientY < 26) {
        this.showDropdown = false;
        this.showLogOutDropDown = false;
      }
    },
    closeWarningModal() {
      location.hash = "#";
      this.$router.push(this.score.last_unit);
    },
    closeNotRegisteredModal() {
      this.setUser(null);
      location.hash = "/";
      this.$router.push("/");
    },
    closeRegisterModal() {
      location.hash = "/";
      this.$router.push("/");
    },
    closeResetModal() {
      location.hash = "/";
      this.showResetWarning = false;
      this.successReset = false;
      this.$router.push("/");
    },
    closeLoginModal() {
      this.resetModalForm();
      this.showLoginWarning = false;
      location.hash = "/";
      this.$router.push("/");
    },
    handleScroll(event) {
      if (event) {
        this.showDropdown = false;
      }
    },
    loginClient() {
      this.showLoginWarning = false;
      this.$validator.validateAll("login-form").then(result => {
        if (result) {
          this.loginProcessing = true;
          this.login(this.modalForm).then(result => {
            if (result) {
              this.successLogin = true;
              this.loginProcessing = false;
              this.resetModalForm();
              this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              });
              setTimeout(() => {
                this.closeLoginModal();
              }, 3000);
              setTimeout(() => {
                this.$router.push(this.score.last_unit);
              }, 3600);
            } else {
              this.showLoginWarning = true;
              this.loginProcessing = false;
            }
          });
        } else {
          this.loginProcessing = false;
        }
      });
    },
    logout() {
      this.setUser(null);
      this.restartScore();
      this.successRegistration = false;
      this.$router.push("/");
    },
    openDropdown(id) {
      this.showDropdown = true;
      this.selectedMenuId = id;
    },
    openResetPasswordModal() {
      location.href = "#reset";
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    resetModalForm() {
      this.modalForm.email = "";
      this.modalForm.password = "";
    },
    registerClient() {
      this.setUser(null);
      this.restartScore();
      this.$validator.validateAll("modal-form").then(result => {
        if (result) {
          this.processing = true;
          this.register(this.modalForm).then(result => {
            if (result) {
              this.successRegistration = true;
              this.processing = false;
              this.resetModalForm();
              this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              });
            }
            setTimeout(() => {
              this.closeRegisterModal();
            }, 3000);
          });
        }
      });
    },
    resetPassword() {
      this.showResetWarning = false;
      this.$validator.validateAll("reset-form").then(result => {
        if (result) {
          this.resetProcessing = true;
          this.reset(this.modalForm).then(result => {
            if (result) {
              this.successReset = true;
              this.resetProcessing = false;
              this.resetModalForm();
              this.$nextTick(() => {
                this.errors.clear();
                this.$nextTick(() => {
                  this.$validator.reset();
                });
              });
              setTimeout(() => {
                this.closeResetModal();
              }, 6000);
            } else {
              this.showResetWarning = true;
              this.resetProcessing = false;
            }
          });
        } else {
          this.loginProcessing = false;
        }
      });
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  mounted() {
    setTimeout(() => {
      this.animateTopCard();
    }, 1000);
    this.fetchData();
    this.setYear();

    if (this.user) {
      this.getUser({ email: this.user.email });

      if (this.user.certificate.certificate) {
        this.$router.push("/final-test");
      } else {
        this.$router.push(this.score.last_unit);
      }
    } else {
      this.setUser(null);
      this.restartScore();
    }

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="scss">
@import "assets/scss/application";
.overlay-left,
.overlay-right,
.overlay-top,
.overlay-bottom,
.overlay-up-down {
  z-index: 10003 !important;
  background: var(--brand-primary) !important;
}
</style>
