<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="906.7"
    viewBox="0 0 903.53 906.7"
  >
    <g id="Group_1" data-name="Group 1" transform="translate(-223.82 -36.23)">
      <path
        @click="changeColor('Path_3')"
        id="Path_3"
        data-name="Path 3"
        d="M514.38,17.23a30.187,30.187,0,0,1,4.13,4.25A201.45,201.45,0,0,1,540.7,54.31a317.159,317.159,0,0,1,26.41,63.59c.76,2.48,1.6,4.93,2.4,7.39l.16.54.27.94a12.306,12.306,0,0,0-4.92-1.78,368.937,368.937,0,0,0-86.85-1.58c-5.49.59-10.93,1.48-16.47,1.66,9.74-34.97,24.99-68.77,45.79-98.58A79.133,79.133,0,0,1,514.38,17.23Z"
        transform="translate(160 19)"
        fill="#ffe5a6"
      />
      <path
        id="Path_19"
        data-name="Path 19"
        d="M330.31,106.61c.84-.18,1.69-.32,2.54-.44a25.384,25.384,0,0,1-.04,6.8,28.252,28.252,0,0,1-2.67-1.1A16.878,16.878,0,0,1,330.31,106.61Z"
        transform="translate(160 19)"
        fill="#fff"
      />
      <path
        id="Path_20"
        data-name="Path 20"
        d="M662.24,106.28a20.937,20.937,0,0,1,2.25.27,48.867,48.867,0,0,1,.41,5.8c-.89.06-1.79.08-2.69.07A8.66,8.66,0,0,1,662.24,106.28Z"
        transform="translate(160 19)"
        fill="#fff"
      />
      <path
        @click="changeColor('Path_22')"
        id="Path_22"
        data-name="Path 22"
        d="M565.02,124.99a12.306,12.306,0,0,1,4.92,1.78l.3,1c-.61,1.62-.27,2.66,1.04,3.11.13,1.06.29,2.11.46,3.17a5.842,5.842,0,0,0,.81,4.16c.44.9.87,1.81,1.3,2.71-1.53.99-1.39,1.55.44,1.67.1,1.53.23,3.05.3,4.58a6.767,6.767,0,0,0,.96,4c.42.87.84,1.74,1.27,2.6-2.11,1.22-.76,3.01.33,4.4l.08,1.03a50.743,50.743,0,0,0,2.35,12.56l.09.54a72.1,72.1,0,0,0,1.8,12.47l-.14,1.15c-.6,2.17-.28,3.27.9,5.14l-.08,1.73c-.52,1.9-.47,2.85.97,4.28-.04.91-.09,1.83-.15,2.74a5.848,5.848,0,0,0,.68,4.27c.13.97.27,1.94.41,2.9a5.406,5.406,0,0,0,.26,3.22c-.11,1-.22,2-.34,3.01l-.13,1.85c-17.91-3.92-35.33-7.65-53.85-8.19-7.96-.83-15.87-1.39-23.87-.46-5.22.68-10.48.55-15.72,1.05-14.42,1.72-29.13,3.95-43.11,7.97-.3-1.35-.63-2.7-.98-4.05.18-.77.37-1.55.55-2.33a9.215,9.215,0,0,0,.08-4.09c.01-.96,0-1.92-.01-2.88,1.87-.75,1.34-2.68.88-4.18l.05-1.62c1.48-1.74,1.94-2.77.38-4.68.2-.86.4-1.71.61-2.57a9.512,9.512,0,0,0,.41-3.85c-.08-1.02-.18-2.03-.28-3.04,1.25-.42,1.57-1.49.95-3.23.16-.74.32-1.48.49-2.22a60.556,60.556,0,0,0,1.95-12.34l.34-1.15c1.07-1.71,1.42-2.73.5-4.61.36-.83.73-1.65,1.1-2.48a4.894,4.894,0,0,0,.6-4.24l.36-1.83a6.152,6.152,0,0,0,1.54-5.02l-.05-1.56a4.7,4.7,0,0,0,1.2-4.62l.2-1.76a2.764,2.764,0,0,0,.65-3.43c.47-1.18.93-2.35,1.39-3.53,1.13-1.24,1.27-2.38.42-3.4.37-.54.73-1.09,1.07-1.65,5.54-.18,10.98-1.07,16.47-1.66A368.937,368.937,0,0,1,565.02,124.99Z"
        transform="translate(160 19)"
        fill="#fed97d"
      />
      <path
        id="Path_23"
        data-name="Path 23"
        d="M459.88,125.13c.94,1.52-3.94,2.82-3.98.97C456.37,124.68,458.75,124.37,459.88,125.13Z"
        transform="translate(160 19)"
        fill="#878585"
      />
      <path
        id="Path_24"
        data-name="Path 24"
        d="M569.51,125.29c1.21-.6,3.96-.9,4.45.76-.46,1.95-3.3.49-4.29-.22Z"
        transform="translate(160 19)"
        fill="#908e8f"
      />
      <path
        id="Path_25"
        data-name="Path 25"
        d="M454.55,126.01a9.722,9.722,0,0,1-6.06,2.13C450.38,125.79,451.67,125.96,454.55,126.01Z"
        transform="translate(160 19)"
        fill="#969494"
      />
      <path
        id="Path_26"
        data-name="Path 26"
        d="M575.26,125.94a11.479,11.479,0,0,1,6.09,1.41C578.4,128.13,577.58,127.95,575.26,125.94Z"
        transform="translate(160 19)"
        fill="#969495"
      />
      <path
        id="Path_27"
        data-name="Path 27"
        d="M446.93,127.5c.95,1.64-4.08,2.55-3.88.83C443.84,127.44,445.86,126.85,446.93,127.5Z"
        transform="translate(160 19)"
        fill="#777475"
      />
      <path
        id="Path_28"
        data-name="Path 28"
        d="M460.63,126.72c.85,1.02.71,2.16-.42,3.4A2.958,2.958,0,0,1,460.63,126.72Z"
        transform="translate(160 19)"
        fill="#918366"
      />
      <path
        id="Path_29"
        data-name="Path 29"
        d="M586.98,128.85c-1.3.34-3.65.68-4.31-.86C583.14,126.03,586.36,127.66,586.98,128.85Z"
        transform="translate(160 19)"
        fill="#7a7879"
      />
      <path
        id="Path_30"
        data-name="Path 30"
        d="M570.24,127.77l.62.35a5.866,5.866,0,0,1,.42,2.76C569.97,130.43,569.63,129.39,570.24,127.77Z"
        transform="translate(160 19)"
        fill="#9a8863"
      />
      <path
        id="Path_31"
        data-name="Path 31"
        d="M440.56,129.17c.48,1.23-4.4,2.69-4.37.93C436.64,128.58,439.44,128.41,440.56,129.17Z"
        transform="translate(160 19)"
        fill="#8d8b8b"
      />
      <path
        id="Path_32"
        data-name="Path 32"
        d="M589,129.13c1.27-.37,3.67-.72,4.3.81C592.83,131.84,589.6,130.35,589,129.13Z"
        transform="translate(160 19)"
        fill="#797777"
      />
      <path
        id="Path_33"
        data-name="Path 33"
        d="M434.04,130.82c-.59,1.48-2.81,1.37-4.1,1.08C430.19,130.5,433.65,128.94,434.04,130.82Z"
        transform="translate(160 19)"
        fill="#7b7879"
      />
      <path
        id="Path_34"
        data-name="Path 34"
        d="M599.99,131.86c-1.35.3-3.56.52-4.22-1.02C596.23,128.86,599.45,130.61,599.99,131.86Z"
        transform="translate(160 19)"
        fill="#7e7c7d"
      />
      <path
        id="Path_35"
        data-name="Path 35"
        d="M424.25,132.28c.66-.4,3.42-.93,3.43.34-.76,1.07-3.11,1.93-4.28,1.17C423.14,133.26,423.42,132.75,424.25,132.28Z"
        transform="translate(160 19)"
        fill="#898687"
      />
      <path
        id="Path_36"
        data-name="Path 36"
        d="M602,132.1c1.18-.44,4.71-.33,4.01,1.65C604.65,134.59,602.7,133.33,602,132.1Z"
        transform="translate(160 19)"
        fill="#797677"
      />
      <path
        id="Path_37"
        data-name="Path 37"
        d="M613.37,135.99a51.467,51.467,0,0,1-5.75-1.59C610.31,133.56,611.62,133.31,613.37,135.99Z"
        transform="translate(160 19)"
        fill="#989697"
      />
      <path
        id="Path_38"
        data-name="Path 38"
        d="M421.07,134.62c-.74,1.26-2.84,1.44-4.16,1.24C416.77,133.85,420.64,132.52,421.07,134.62Z"
        transform="translate(160 19)"
        fill="#898787"
      />
      <path
        id="Path_39"
        data-name="Path 39"
        d="M458.82,133.65a2.764,2.764,0,0,1-.65,3.43A2.764,2.764,0,0,1,458.82,133.65Z"
        transform="translate(160 19)"
        fill="#9d927c"
      />
      <path
        id="Path_40"
        data-name="Path 40"
        d="M571.74,134.05c1.46,1.15,2,2.55.81,4.16A5.842,5.842,0,0,1,571.74,134.05Z"
        transform="translate(160 19)"
        fill="#9b9282"
      />
      <path
        id="Path_41"
        data-name="Path 41"
        d="M413.46,135.34c.87-.06,1.36.18,1.48.71-.35,1.22-3.08,2.07-4.1,1.36C410.1,136.14,412.58,135.38,413.46,135.34Z"
        transform="translate(160 19)"
        fill="#8d8b8c"
      />
      <path
        id="Path_42"
        data-name="Path 42"
        d="M615.06,135.59c1.03-.68,3.72.05,4.09,1.29C619.1,138.6,613.96,137.09,615.06,135.59Z"
        transform="translate(160 19)"
        fill="#878586"
      />
      <path
        id="Path_43"
        data-name="Path 43"
        d="M407.53,137.4c2.86.92-3.22,3.85-3.37,1.76C404.56,137.9,406.34,137.35,407.53,137.4Z"
        transform="translate(160 19)"
        fill="#8d8b8b"
      />
      <path
        id="Path_44"
        data-name="Path 44"
        d="M621.3,137.47c1-.75,3.74.25,4.01,1.48C625.17,140.7,620.36,138.92,621.3,137.47Z"
        transform="translate(160 19)"
        fill="#8d8b8c"
      />
      <path
        id="Path_45"
        data-name="Path 45"
        d="M457.97,138.84a4.7,4.7,0,0,1-1.2,4.62C456.08,141.31,456.11,140.32,457.97,138.84Z"
        transform="translate(160 19)"
        fill="#af9f78"
      />
      <path
        id="Path_46"
        data-name="Path 46"
        d="M401.87,140.06c.62,1.58-2.94,2.03-3.95,1.85A2.758,2.758,0,0,1,401.87,140.06Z"
        transform="translate(160 19)"
        fill="#7b7879"
      />
      <path
        id="Path_47"
        data-name="Path 47"
        d="M631.63,141.73c-1.16.59-2.89-.54-3.83-1.19C628.84,139.33,632,139.98,631.63,141.73Z"
        transform="translate(160 19)"
        fill="#747172"
      />
      <path
        id="Path_48"
        data-name="Path 48"
        d="M573.85,140.92l.44,1.67C572.46,142.47,572.32,141.91,573.85,140.92Z"
        transform="translate(160 19)"
        fill="#978458"
      />
      <path
        id="Path_49"
        data-name="Path 49"
        d="M396.01,142.07c-.67,1.41-2.47,2.68-3.99,1.82C391.34,142.1,394.88,141.75,396.01,142.07Z"
        transform="translate(160 19)"
        fill="#777575"
      />
      <path
        id="Path_50"
        data-name="Path 50"
        d="M634.12,142.13a3.327,3.327,0,0,1,3.88,1.24C638.04,145.05,633.27,143.46,634.12,142.13Z"
        transform="translate(160 19)"
        fill="#7d7b7b"
      />
      <path
        id="Path_51"
        data-name="Path 51"
        d="M388.33,144.13c3.9-.26-2.08,3.99-2.61,1.81A3.53,3.53,0,0,1,388.33,144.13Z"
        transform="translate(160 19)"
        fill="#828080"
      />
      <path
        id="Path_52"
        data-name="Path 52"
        d="M640.16,144.18c.98-.79,4.67.69,3.84,2.14C642.94,147.06,639.59,145.63,640.16,144.18Z"
        transform="translate(160 19)"
        fill="#8a8788"
      />
      <path
        id="Path_53"
        data-name="Path 53"
        d="M456.82,145.02a6.152,6.152,0,0,1-1.54,5.02C454.7,147.84,454.48,146.15,456.82,145.02Z"
        transform="translate(160 19)"
        fill="#b8ac90"
      />
      <path
        id="Path_54"
        data-name="Path 54"
        d="M384.33,147.46a18.287,18.287,0,0,1-5.89,1.74C380.72,147.4,381.41,146.32,384.33,147.46Z"
        transform="translate(160 19)"
        fill="#959494"
      />
      <path
        id="Path_55"
        data-name="Path 55"
        d="M646.17,146.81c1.08-.61,4.28.56,3.8,2.02C649.09,149.51,645.61,148.02,646.17,146.81Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        id="Path_56"
        data-name="Path 56"
        d="M574.59,147.17c1.81.59,1.94,2.61.96,4A6.767,6.767,0,0,1,574.59,147.17Z"
        transform="translate(160 19)"
        fill="#9f998c"
      />
      <path
        id="Path_57"
        data-name="Path 57"
        d="M376.48,149.26c2.73.87-3.17,4.06-3.35,1.96C373.56,149.97,375.25,149.28,376.48,149.26Z"
        transform="translate(160 19)"
        fill="#8b8989"
      />
      <path
        id="Path_58"
        data-name="Path 58"
        d="M652.46,149.33c.95-.59,4.34.83,3.64,2.14C655.07,152.06,651.89,150.73,652.46,149.33Z"
        transform="translate(160 19)"
        fill="#838081"
      />
      <path
        @click="changeColor('Path_59')"
        id="Path_59"
        data-name="Path 59"
        d="M834.88,150.48a148.61,148.61,0,0,1-3.17,26.4c-5.54,27.4-16.05,54.09-29.01,78.81-1.75,3.31-3.4,7.09-5.84,9.93l-.69.56c-7.49-11.28-16.6-21.64-25.51-31.81-12.05-12.7-24.4-25.02-38.13-35.91-3.61-3.05-7.55-5.71-11.08-8.84l-.43-.55a34.37,34.37,0,0,0,4.25-1.78C759.09,168.92,796.65,155.5,834.88,150.48Z"
        transform="translate(160 19)"
        fill="#d6e5bb"
      />
      <path
        @click="changeColor('Path_61')"
        id="Path_61"
        data-name="Path 61"
        d="M196.1,152.02c12.95.11,26.34,2.96,38.88,6.02a309.071,309.071,0,0,1,74.46,30.83c-9.35,7.49-18.77,14.85-27.61,22.96-6.85,6.47-13.52,13.14-20.06,19.94-9.93,10.31-18.41,21.66-27.38,32.75C215.21,229.66,201.3,191.57,196.1,152.02Z"
        transform="translate(160 19)"
        fill="#fdcd9c"
      />
      <path
        id="Path_62"
        data-name="Path 62"
        d="M370.92,152.15c.62,1.48-2.55,3.07-3.7,2.35C366.4,153.15,369.91,151.38,370.92,152.15Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_63"
        data-name="Path 63"
        d="M454.92,151.87a4.894,4.894,0,0,1-.6,4.24C453.17,154.59,453.68,153.13,454.92,151.87Z"
        transform="translate(160 19)"
        fill="#9e8e6c"
      />
      <path
        id="Path_64"
        data-name="Path 64"
        d="M658.74,152.04a3.131,3.131,0,0,1,3.42,1.98A2.918,2.918,0,0,1,658.74,152.04Z"
        transform="translate(160 19)"
        fill="#5e5b5c"
      />
      <path
        id="Path_66"
        data-name="Path 66"
        d="M576.82,153.77c.91,1.47.71,2.81.33,4.4C576.06,156.78,574.71,154.99,576.82,153.77Z"
        transform="translate(160 19)"
        fill="#b7ab8f"
      />
      <path
        id="Path_67"
        data-name="Path 67"
        d="M364.92,155.13c.83,1.43-3.41,3.58-3.87,1.9C361.24,155.77,363.82,154.42,364.92,155.13Z"
        transform="translate(160 19)"
        fill="#8b8889"
      />
      <path
        id="Path_75"
        data-name="Path 75"
        d="M664.51,155.1c.95-.59,3.94.51,3.49,1.82C667.06,157.55,664.3,156.32,664.51,155.1Z"
        transform="translate(160 19)"
        fill="#6f6d6d"
      />
      <path
        id="Path_76"
        data-name="Path 76"
        d="M494.77,156.89c.54,1.1,1.05,2.2,1.54,3.31-1.94.03-3.87.04-5.81.02C491.76,157.46,491.53,157,494.77,156.89Z"
        transform="translate(160 19)"
        fill="#fbd67b"
      />
      <path
        id="Path_77"
        data-name="Path 77"
        d="M510.31,157.09a9.355,9.355,0,0,1,3.18.21c.27,1.03.5,2.07.69,3.11-1.76-.04-3.52-.08-5.28-.14C509.34,159.19,509.77,158.12,510.31,157.09Z"
        transform="translate(160 19)"
        fill="#fed97d"
      />
      <path
        id="Path_78"
        data-name="Path 78"
        d="M358.93,158.12c.6,1.5-2.67,3-3.8,2.24C354.49,158.98,357.92,157.32,358.93,158.12Z"
        transform="translate(160 19)"
        fill="#8b8989"
      />
      <path
        id="Path_79"
        data-name="Path 79"
        d="M670.49,158.09c.95-.6,3.95.5,3.51,1.81C673.06,160.52,670.28,159.31,670.49,158.09Z"
        transform="translate(160 19)"
        fill="#6e6b6c"
      />
      <path
        id="Path_80"
        data-name="Path 80"
        d="M453.22,158.59c.92,1.88.57,2.9-.5,4.61C451.49,161.32,452,160.28,453.22,158.59Z"
        transform="translate(160 19)"
        fill="#b7aa8f"
      />
      <path
        id="Path_81"
        data-name="Path 81"
        d="M577.23,159.2a34.311,34.311,0,0,1,2.35,12.56A50.743,50.743,0,0,1,577.23,159.2Z"
        transform="translate(160 19)"
        fill="#c3b69b"
      />
      <path
        id="Path_82"
        data-name="Path 82"
        d="M353.08,161a2.851,2.851,0,0,1-3.27,2.14A3.043,3.043,0,0,1,353.08,161Z"
        transform="translate(160 19)"
        fill="#5d5a5a"
      />
      <path
        id="Path_83"
        data-name="Path 83"
        d="M676.47,161.05c1.5-.9,3.18.71,3.98,1.89C679.1,163.77,676.31,162.69,676.47,161.05Z"
        transform="translate(160 19)"
        fill="#7f7c7d"
      />
      <path
        id="Path_84"
        data-name="Path 84"
        d="M347.29,164.19c-.41,1.86-1.78,2.57-3.57,2.66C344.13,164.99,345.49,164.28,347.29,164.19Z"
        transform="translate(160 19)"
        fill="#726f70"
      />
      <path
        id="Path_85"
        data-name="Path 85"
        d="M682.28,164.14c1.68-.74,3.43,1.03,3.8,2.6C684.87,166.63,681.78,165.81,682.28,164.14Z"
        transform="translate(160 19)"
        fill="#797677"
      />
      <path
        id="Path_86"
        data-name="Path 86"
        d="M452.38,164.35a60.556,60.556,0,0,1-1.95,12.34,10.528,10.528,0,0,1,.52-5.9A16.777,16.777,0,0,1,452.38,164.35Z"
        transform="translate(160 19)"
        fill="#c0b49a"
      />
      <path
        id="Path_87"
        data-name="Path 87"
        d="M342.25,167c-1.46,2.33-2.2,3.3-5.1,3.13A9.606,9.606,0,0,1,342.25,167Z"
        transform="translate(160 19)"
        fill="#a3a1a1"
      />
      <path
        id="Path_88"
        data-name="Path 88"
        d="M687.65,166.8a9.538,9.538,0,0,1,5,3.5C690.26,169.58,688.37,169.59,687.65,166.8Z"
        transform="translate(160 19)"
        fill="#949293"
      />
      <path
        id="Path_89"
        data-name="Path 89"
        d="M693.92,170.22a7.463,7.463,0,0,1,4.32,3.65C696.58,172.93,693.42,172.78,693.92,170.22Z"
        transform="translate(160 19)"
        fill="#999797"
      />
      <path
        id="Path_90"
        data-name="Path 90"
        d="M335.99,170.99c-.45,1.49-2.24,3.28-3.88,2.61C331.3,171.67,334.54,170.68,335.99,170.99Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_91"
        data-name="Path 91"
        d="M579.67,172.3a16.19,16.19,0,0,1,1.46,6.51,12.537,12.537,0,0,1,.34,5.96A72.1,72.1,0,0,1,579.67,172.3Z"
        transform="translate(160 19)"
        fill="#c4b99f"
      />
      <path
        id="Path_92"
        data-name="Path 92"
        d="M330.14,174.39c-.25,1.46-1.63,3.12-3.24,2.52C326.29,175.44,329.06,174.51,330.14,174.39Z"
        transform="translate(160 19)"
        fill="#727070"
      />
      <path
        id="Path_93"
        data-name="Path 93"
        d="M699.51,174.24c1.02-.61,3.97,1.46,3.41,2.63C701.8,177.43,698.94,175.58,699.51,174.24Z"
        transform="translate(160 19)"
        fill="#7d7b7b"
      />
      <path
        id="Path_94"
        data-name="Path 94"
        d="M324.65,178.13c.51,1.67-2.45,2.64-3.69,2.78C321.3,179.42,322.98,177.38,324.65,178.13Z"
        transform="translate(160 19)"
        fill="#7b7979"
      />
      <path
        id="Path_95"
        data-name="Path 95"
        d="M705.13,178.1c1.15-.81,4.29,1.2,3.65,2.59C707.68,181.39,704.5,179.42,705.13,178.1Z"
        transform="translate(160 19)"
        fill="#868484"
      />
      <path
        id="Path_96"
        data-name="Path 96"
        d="M449.94,178.91c.62,1.74.3,2.81-.95,3.23A3.183,3.183,0,0,1,449.94,178.91Z"
        transform="translate(160 19)"
        fill="#918c84"
      />
      <path
        id="Path_97"
        data-name="Path 97"
        d="M319.02,181.92a3.055,3.055,0,0,1-3.22,2.24A2.88,2.88,0,0,1,319.02,181.92Z"
        transform="translate(160 19)"
        fill="#5f5b5c"
      />
      <path
        id="Path_98"
        data-name="Path 98"
        d="M710.91,181.85a3.753,3.753,0,0,1,3.55,2.09C712.64,184.87,711.25,183.67,710.91,181.85Z"
        transform="translate(160 19)"
        fill="#747172"
      />
      <path
        id="Path_99"
        data-name="Path 99"
        d="M314.2,185.09c-.92,1.26-1.92,2.46-2.94,3.64,1.1.82,2.2,1.64,3.3,2.45a47.459,47.459,0,0,1-5.09-2.3C310.3,186.49,312.09,185.96,314.2,185.09Z"
        transform="translate(160 19)"
        fill="#a49991"
      />
      <path
        id="Path_100"
        data-name="Path 100"
        d="M449.27,185.18a9.512,9.512,0,0,1-.41,3.85C447.14,188.11,447.66,185.91,449.27,185.18Z"
        transform="translate(160 19)"
        fill="#aaa7a6"
      />
      <path
        id="Path_101"
        data-name="Path 101"
        d="M716.07,185.41c1.03-.66,4.17,1.35,3.63,2.59C718.52,188.85,715.32,186.89,716.07,185.41Z"
        transform="translate(160 19)"
        fill="#8c8a8a"
      />
      <path
        id="Path_102"
        data-name="Path 102"
        d="M581.33,185.92c1.85,2.05,2.08,2.58.9,5.14C581.05,189.19,580.73,188.09,581.33,185.92Z"
        transform="translate(160 19)"
        fill="#c4b89d"
      />
      <path
        @click="changeColor('Path_103')"
        id="Path_103"
        data-name="Path 103"
        d="M309.878,188.87l.03.01a47.634,47.634,0,0,0,5.12,2.324,31.884,31.884,0,0,0,3.46,1.768c-.523,1.556,1.328,2.95,2.293,1.313.744.667,1.509,1.324,2.273,1.97a4.411,4.411,0,0,0,3.279,2.364c1.046.4,2.092.839,3.128,1.273.593,1.536,1.308,2.455,3.028,2.667.664.141,1.328.293,1.992.434a2.246,2.246,0,0,0,3.2,2.162,27.96,27.96,0,0,0,2.676,1.96c.02,2,1.6,2.334,3.259,2.354.624.6,1.237,1.192,1.841,1.8a5.942,5.942,0,0,0,3.259,2.233c1,.445,1.982.94,2.967,1.435.614,1.707,1.137,2.314,2.917,2.788.624.212,1.237.434,1.861.667.624,2,1.2,2.667,3.229,3.263.634.253,1.277.505,1.911.768.674,2.8,1.428,3.112,4.043,3.87.684.242,1.368.485,2.052.738.3,2.091.986,2.516,3.007,2.708l1.026.99c1.127,1.93,1.77,2.566,4.013,3.031.634.576,1.257,1.182,1.851,1.8a4.983,4.983,0,0,0,3.239,3.021c.644.273,1.277.556,1.921.828.513,1.455,1.006,2.708,2.605,3.223-5.452,2.162-10.4,6.082-15.319,9.275-7.272,4.738-13.961,10.174-20.74,15.579-10.29,8.871-20.288,17.913-29.008,28.4a279.216,279.216,0,0,0-32.177,45.08c-.976-.94-2-1.839-3.007-2.758-.493-.6-.986-1.2-1.458-1.819-.684-2.021-1.217-2.607-3.289-3.172-.262-.626-.523-1.253-.775-1.889-.583-1.748-1.287-3.011-3.319-3.061-.251-.677-.513-1.364-.764-2.051a6.115,6.115,0,0,0-3.088-3.971q-1.162-.818-2.354-1.637c.5-1.627-.322-2.748-1.66-3.577l-2.2-1.667c.563-1.687-.362-3.223-2.2-3.132-.211-.7-.422-1.394-.634-2.081-.493-2.233-1.267-2.93-3.229-3.96l-.855-1.2c-.342-2.2-.624-3.839-3.269-3.87-.211-.687-.412-1.374-.624-2.071-.443-2.162-1.287-2.96-3.128-4.031l-.8-1.2c-.151-2.122-.5-3-2.424-4.011-.533-.647-1.056-1.293-1.589-1.94.01-2.3-.342-3.142-2.535-4-.2-.626-.392-1.253-.573-1.889-.342-1.485-.845-2.859-2.565-3.031-.463-1.01-.976-2-1.489-2.991a4.183,4.183,0,0,0-1.821-3.647q-1.086-1.046-2.142-2.122c1.841-1.313.06-2.768-1.247-3.516l-.674-1.364c9.022-11.2,17.552-22.672,27.54-33.088,6.578-6.87,13.287-13.609,20.177-20.146C291,203.873,300.474,196.437,309.878,188.87Z"
        transform="translate(159.143 18.512)"
        fill="#fbb673"
      />
      <path
        id="Path_104"
        data-name="Path 104"
        d="M721.02,189.07l.43.55c-.66,1.07-1.84,1.55-3.56,1.45A3.57,3.57,0,0,1,721.02,189.07Z"
        transform="translate(160 19)"
        fill="#939b81"
      />
      <path
        @click="changeColor('Path_105')"
        id="Path_105"
        data-name="Path 105"
        d="M721.45,189.62c3.53,3.13,7.47,5.79,11.08,8.84,13.73,10.89,26.08,23.21,38.13,35.91,8.91,10.17,18.02,20.53,25.51,31.81a2.484,2.484,0,0,0,.68,2.06,23.494,23.494,0,0,0-2.94,4.25l-.41-.23-.23.41.38.24-.97,1.49-.13.18-.12.2c-.54.86-1.08,1.71-1.61,2.57l-.23.32v.1l-.83,1.52-.17.27-.18.26c-.75,1.01-1.5,2.03-2.26,3.04a4.186,4.186,0,0,0-2.43,3.19c-.19.61-.38,1.22-.58,1.83-1.65.63-2.75,1.66-1.92,3.5-.85.95-1.67,1.94-2.43,2.97l-.21.25v.13l-.85,1.55-.17.29-.18.26c-.74,1.04-1.5,2.08-2.19,3.15-1.75-.06-2.36,1.22-2.68,2.72-.33,1.06-.68,2.13-1.05,3.18-1.69-1.58-2.29.82-2.63,2.1-.41,1.05-.86,2.09-1.32,3.12-2.04.26-2.51.84-2.89,2.82-.4.95-.8,1.91-1.22,2.86-1.6-1.62-2.36.77-2.73,2.03-.39,1.02-.8,2.03-1.23,3.04-1.64-1.61-2.32.74-2.73,2.01-.47.99-.97,1.96-1.46,2.94a2.336,2.336,0,0,0-2.38,2.43l-1.63,1.78c-1.52.65-2.71,1.57-2.06,3.39-.78.48-1.56.97-2.34,1.45-2,1.01-2.47,1.95-3.01,4.05l-.64.68c-4.72-7.24-9.1-14.67-14.01-21.79a261.217,261.217,0,0,0-82.59-76.66c1.03-1.05,2.01-2.15,2.97-3.26.74-.83,1.45-1.7,2.17-2.55,1.06,1.78,2.26-.03,2.41-1.34.85-.89,1.71-1.79,2.55-2.7,1.1,1.63,2.77-.01,2.08-1.51,1.48-1.03,2.88-2.17,4.34-3.23l.26-.18.23-.18a27.742,27.742,0,0,1,3.29-2.1c1.85-.45,3.1-1.04,3.15-3.17.68-.2,1.37-.41,2.05-.61,1.21-.23,1.84-1.1,1.9-2.62,1.01-.47,2.03-.92,3.04-1.38,2-.26,2.66-.7,2.91-2.77,1.03-.47,2.06-.93,3.1-1.35,1.29-.35,3.68-.96,2.1-2.65,1.04-.37,2.1-.72,3.16-1.05,1.47-.34,2.74-.89,2.76-2.61a26.8,26.8,0,0,0,3.64-2.61c.91,1.67,2.68.19,2.27-1.29q1.155-.285,2.31-.54c1.52-.22,2.77-.93,2.72-2.63a27.584,27.584,0,0,0,3.71-2.53c.91,1.85,2.31.16,2.61-1.14.83-.64,1.66-1.28,2.48-1.93,1.17,1.64,4.1.17,2.3-1.49,1.26-.55,2.53-1.09,3.79-1.64C719.61,191.17,720.79,190.69,721.45,189.62Z"
        transform="translate(160 19)"
        fill="#c4db9c"
      />
      <path
        id="Path_106"
        data-name="Path 106"
        d="M448.25,191.6c1.56,1.91,1.1,2.94-.38,4.68C446.83,194.3,447.03,193.43,448.25,191.6Z"
        transform="translate(160 19)"
        fill="#c6b99c"
      />
      <path
        id="Path_107"
        data-name="Path 107"
        d="M318,192.93a5.353,5.353,0,0,1,2.28,1.3C319.32,195.85,317.48,194.47,318,192.93Z"
        transform="translate(160 19)"
        fill="#b28760"
      />
      <path
        id="Path_108"
        data-name="Path 108"
        d="M582.15,192.79c1.3,1.4,1.34,2.47.97,4.28C581.68,195.64,581.63,194.69,582.15,192.79Z"
        transform="translate(160 19)"
        fill="#a38e5f"
      />
      <path
        id="Path_109"
        data-name="Path 109"
        d="M714.1,192.71c1.8,1.66-1.13,3.13-2.3,1.49A7.823,7.823,0,0,1,714.1,192.71Z"
        transform="translate(160 19)"
        fill="#94a07b"
      />
      <path
        id="Path_110"
        data-name="Path 110"
        d="M322.54,196.18c1.65-.02,3.34.31,3.26,2.34A4.384,4.384,0,0,1,322.54,196.18Z"
        transform="translate(160 19)"
        fill="#aa8e75"
      />
      <path
        id="Path_111"
        data-name="Path 111"
        d="M709.32,196.13c-.3,1.3-1.7,2.99-2.61,1.14A4.066,4.066,0,0,1,709.32,196.13Z"
        transform="translate(160 19)"
        fill="#8e9978"
      />
      <path
        id="Path_112"
        data-name="Path 112"
        d="M447.82,197.9c.46,1.5.99,3.43-.88,4.18A5.066,5.066,0,0,1,447.82,197.9Z"
        transform="translate(160 19)"
        fill="#938361"
      />
      <path
        id="Path_113"
        data-name="Path 113"
        d="M328.91,199.78c1.53.31,3.09.74,3.01,2.64C330.21,202.21,329.5,201.3,328.91,199.78Z"
        transform="translate(160 19)"
        fill="#a48f7d"
      />
      <path
        id="Path_114"
        data-name="Path 114"
        d="M582.97,199.81c1.91.71,1.2,2.83.68,4.27A5.848,5.848,0,0,1,582.97,199.81Z"
        transform="translate(160 19)"
        fill="#918160"
      />
      <path
        id="Path_115"
        data-name="Path 115"
        d="M703,199.8c.05,1.7-1.2,2.41-2.72,2.63C700.22,200.66,701.55,200.19,703,199.8Z"
        transform="translate(160 19)"
        fill="#99a385"
      />
      <path
        id="Path_116"
        data-name="Path 116"
        d="M333.9,202.85a4.56,4.56,0,0,1,3.18,2.14A2.232,2.232,0,0,1,333.9,202.85Z"
        transform="translate(160 19)"
        fill="#ad8c6e"
      />
      <path
        id="Path_117"
        data-name="Path 117"
        d="M697.97,202.97c.41,1.48-1.36,2.96-2.27,1.29A4.094,4.094,0,0,1,697.97,202.97Z"
        transform="translate(160 19)"
        fill="#8f997a"
      />
      <path
        id="Path_118"
        data-name="Path 118"
        d="M446.95,204.96a9.215,9.215,0,0,1-.08,4.09C444.92,208.14,445.76,206.15,446.95,204.96Z"
        transform="translate(160 19)"
        fill="#8f8a82"
      />
      <path
        @click="changeColor('Path_119')"
        id="Path_119"
        data-name="Path 119"
        d="M506.13,206.41c8-.93,15.91-.37,23.87.46,18.52.54,35.94,4.27,53.85,8.19l1.26,1.15a29.176,29.176,0,0,0-.15,6.8c1.71,27.73,1.13,55.42-.69,83.12l-1.02-.12a179.168,179.168,0,0,0-135.1-2.81,522.465,522.465,0,0,1-1.98-87.37l1.13-.4c13.98-4.02,28.69-6.25,43.11-7.97C495.65,206.96,500.91,207.09,506.13,206.41Z"
        transform="translate(160 19)"
        fill="#fecf4c"
      />
      <path
        id="Path_120"
        data-name="Path 120"
        d="M339.74,206.93a3.9,3.9,0,0,1,3.24,2.33C341.33,209.24,339.76,208.91,339.74,206.93Z"
        transform="translate(160 19)"
        fill="#ac9076"
      />
      <path
        id="Path_121"
        data-name="Path 121"
        d="M584.06,206.98c1.46.89,1.68,2.14.26,3.22A5.406,5.406,0,0,1,584.06,206.98Z"
        transform="translate(160 19)"
        fill="#a19f9f"
      />
      <path
        id="Path_122"
        data-name="Path 122"
        d="M692.06,206.87c-.02,1.72-1.29,2.27-2.76,2.61C689.2,207.67,690.57,207.2,692.06,206.87Z"
        transform="translate(160 19)"
        fill="#969f83"
      />
      <path
        id="Path_123"
        data-name="Path 123"
        d="M344.81,211.04a2.263,2.263,0,0,1,3.24,2.21A5.909,5.909,0,0,1,344.81,211.04Z"
        transform="translate(160 19)"
        fill="#a78668"
      />
      <path
        id="Path_124"
        data-name="Path 124"
        d="M686.14,210.53c1.58,1.69-.81,2.3-2.1,2.65A2.845,2.845,0,0,1,686.14,210.53Z"
        transform="translate(160 19)"
        fill="#90997b"
      />
      <path
        id="Path_125"
        data-name="Path 125"
        d="M446.32,211.38c.35,1.35.68,2.7.98,4.05l-1.13.4c-1.51.5-3.03.9-4.58,1.25a30.1,30.1,0,0,1,4.02-2.8C445.85,213.32,446.09,212.35,446.32,211.38Z"
        transform="translate(160 19)"
        fill="#91866f"
      />
      <path
        id="Path_126"
        data-name="Path 126"
        d="M583.98,213.21a3.165,3.165,0,0,1,2.06,2.11l-.93.89-1.26-1.15Z"
        transform="translate(160 19)"
        fill="#7b6c4c"
      />
      <path
        id="Path_127"
        data-name="Path 127"
        d="M351,214.67c1.55.34,3.07.85,2.9,2.76A3.489,3.489,0,0,1,351,214.67Z"
        transform="translate(160 19)"
        fill="#a68d77"
      />
      <path
        id="Path_128"
        data-name="Path 128"
        d="M680.94,214.53c-.25,2.07-.91,2.51-2.91,2.77A3.679,3.679,0,0,1,680.94,214.53Z"
        transform="translate(160 19)"
        fill="#9ba08f"
      />
      <path
        id="Path_129"
        data-name="Path 129"
        d="M592.79,217.85c-1.28.78-3.14-.38-4.12-1.23C589.65,215.32,593.29,216.08,592.79,217.85Z"
        transform="translate(160 19)"
        fill="#7c7a7a"
      />
      <path
        id="Path_130"
        data-name="Path 130"
        d="M439.95,217.54c-.84,1.17-2.76,1.25-4.07,1.18C436.04,217.01,439.67,215.44,439.95,217.54Z"
        transform="translate(160 19)"
        fill="#7c7a7a"
      />
      <path
        id="Path_131"
        data-name="Path 131"
        d="M355.75,218.09c1.72.51,3.38,1.09,3.21,3.23C356.94,220.73,356.37,220.07,355.75,218.09Z"
        transform="translate(160 19)"
        fill="#ad8e71"
      />
      <path
        id="Path_132"
        data-name="Path 132"
        d="M595.14,218.15c1.46-.74,3.23.43,3.91,1.75C598.04,220.12,594.7,219.65,595.14,218.15Z"
        transform="translate(160 19)"
        fill="#666464"
      />
      <path
        id="Path_133"
        data-name="Path 133"
        d="M433.87,219.05a4.156,4.156,0,0,1-4.05,1.82C430.35,218.81,432.06,218.65,433.87,219.05Z"
        transform="translate(160 19)"
        fill="#6f6d6e"
      />
      <path
        id="Path_134"
        data-name="Path 134"
        d="M674.99,218.68c-.06,1.52-.69,2.39-1.9,2.62C672.78,220.1,673.42,219.23,674.99,218.68Z"
        transform="translate(160 19)"
        fill="#8b9477"
      />
      <path
        id="Path_135"
        data-name="Path 135"
        d="M601.29,220.16c1.21-.77,3.09.47,4.03,1.24C604.36,222.81,600.77,221.98,601.29,220.16Z"
        transform="translate(160 19)"
        fill="#7e7c7c"
      />
      <path
        id="Path_136"
        data-name="Path 136"
        d="M427.37,221.58c-.96.78-2.71,1.94-3.96,1.28C423.19,221.28,426.34,220.5,427.37,221.58Z"
        transform="translate(160 19)"
        fill="#706e6e"
      />
      <path
        id="Path_137"
        data-name="Path 137"
        d="M360.86,222.08c2.18.72,3.63,1.34,4.02,3.83C362.28,225.16,361.53,224.85,360.86,222.08Z"
        transform="translate(160 19)"
        fill="#c1a182"
      />
      <path
        id="Path_138"
        data-name="Path 138"
        d="M607.89,222.34c.97-.76,4.47.76,3.78,2.14C610.63,225.28,607.09,223.85,607.89,222.34Z"
        transform="translate(160 19)"
        fill="#918f8f"
      />
      <path
        id="Path_139"
        data-name="Path 139"
        d="M671.04,221.91c-.05,2.13-1.3,2.72-3.15,3.17A3.6,3.6,0,0,1,671.04,221.91Z"
        transform="translate(160 19)"
        fill="#949f7c"
      />
      <path
        id="Path_140"
        data-name="Path 140"
        d="M419.55,223.1c4.38-.42-1.55,4.29-2.51,1.92A3.507,3.507,0,0,1,419.55,223.1Z"
        transform="translate(160 19)"
        fill="#908e8e"
      />
      <path
        id="Path_141"
        data-name="Path 141"
        d="M614.06,225.12c1.44-.76,3.11.62,3.86,1.81C616.84,227.15,613.59,226.73,614.06,225.12Z"
        transform="translate(160 19)"
        fill="#666364"
      />
      <path
        id="Path_142"
        data-name="Path 142"
        d="M415.05,226.03c-.75,1.68-2.39,2.14-4.09,1.91C411.75,226.21,413.25,225.72,415.05,226.03Z"
        transform="translate(160 19)"
        fill="#656363"
      />
      <path
        id="Path_143"
        data-name="Path 143"
        d="M366.92,226.64a3.742,3.742,0,0,1,2.99,2.68C367.9,229.13,367.22,228.71,366.92,226.64Z"
        transform="translate(160 19)"
        fill="#b29071"
      />
      <path
        id="Path_144"
        data-name="Path 144"
        d="M620.04,227.48c1.05-.74,4.46.77,3.83,2.22C622.87,230.44,619.29,228.92,620.04,227.48Z"
        transform="translate(160 19)"
        fill="#888686"
      />
      <path
        id="Path_145"
        data-name="Path 145"
        d="M664.6,227.18l-.23.18-.26.18Z"
        transform="translate(160 19)"
        fill="#676b59"
      />
      <path
        id="Path_146"
        data-name="Path 146"
        d="M409.59,227.75c-1.27,2.21-2.87,2.78-5.17,3.58C405.53,228.89,407.26,228.54,409.59,227.75Z"
        transform="translate(160 19)"
        fill="#939091"
      />
      <path
        id="Path_147"
        data-name="Path 147"
        d="M370.93,230.3c2.11.24,3.92.39,3.99,3C372.69,232.84,372.05,232.21,370.93,230.3Z"
        transform="translate(160 19)"
        fill="#baa99a"
      />
      <path
        id="Path_148"
        data-name="Path 148"
        d="M626.14,230.2c.98-.57,4.21,1.04,3.74,2.31C628.75,233.07,625.71,231.62,626.14,230.2Z"
        transform="translate(160 19)"
        fill="#7d7b7b"
      />
      <path
        id="Path_149"
        data-name="Path 149"
        d="M402.85,231.23c.52,1.78-2.64,2.46-3.9,2.48C399.44,232.23,401.23,230.55,402.85,231.23Z"
        transform="translate(160 19)"
        fill="#7e7c7d"
      />
      <path
        id="Path_150"
        data-name="Path 150"
        d="M659.77,230.77c.69,1.5-.98,3.14-2.08,1.51A5.733,5.733,0,0,1,659.77,230.77Z"
        transform="translate(160 19)"
        fill="#8e9b74"
      />
      <path
        id="Path_151"
        data-name="Path 151"
        d="M632.1,233.17c1.02-.74,4.52,1.04,3.75,2.4C634.7,236.28,631.5,234.63,632.1,233.17Z"
        transform="translate(160 19)"
        fill="#8a8788"
      />
      <path
        id="Path_152"
        data-name="Path 152"
        d="M395.53,234.1c4.29-.58-1.95,4.53-2.56,2.1A3.7,3.7,0,0,1,395.53,234.1Z"
        transform="translate(160 19)"
        fill="#8a8888"
      />
      <path
        id="Path_153"
        data-name="Path 153"
        d="M376.76,235.08c2.09-.26,3.27.92,3.22,2.99A4.945,4.945,0,0,1,376.76,235.08Z"
        transform="translate(160 19)"
        fill="#b69270"
      />
      <path
        id="Path_154"
        data-name="Path 154"
        d="M655.14,234.98c-.15,1.31-1.35,3.12-2.41,1.34A3.261,3.261,0,0,1,655.14,234.98Z"
        transform="translate(160 19)"
        fill="#8a9572"
      />
      <path
        id="Path_155"
        data-name="Path 155"
        d="M638.1,236.16c1.06-.73,4.42,1.09,3.7,2.46C640.67,239.34,637.47,237.59,638.1,236.16Z"
        transform="translate(160 19)"
        fill="#8a8888"
      />
      <path
        id="Path_156"
        data-name="Path 156"
        d="M391.04,237.14c-.44,1.5-2.15,3.32-3.81,2.66C386.72,238.07,389.76,237.21,391.04,237.14Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        id="Path_157"
        data-name="Path 157"
        d="M381.89,238.89c1.8.55,2.89.9,3.22,2.94l-.63.25C382.89,241.57,382.4,240.33,381.89,238.89Z"
        transform="translate(160 19)"
        fill="#a2856b"
      />
      <path
        id="Path_158"
        data-name="Path 158"
        d="M643.31,239.02c1.34.48,2.69.97,4.03,1.47,1.08-.51,2.16-1.05,3.22-1.62-.96,1.11-1.94,2.21-2.97,3.26l-.68.36A15.023,15.023,0,0,1,643.31,239.02Z"
        transform="translate(160 19)"
        fill="#82837b"
      />
      <path
        @click="changeColor('Path_163')"
        id="Path_163"
        data-name="Path 163"
        d="M384.48,242.08l.63-.25c6.93,6.43,14.01,12.68,20.89,19.17,14.19,13.63,27.75,27.75,40.7,42.58-7.75,3.59-15.46,7.07-22.74,11.34-33.17,19.15-60.08,49.51-74.64,84.97-3.37-3.38-7.24-6.26-10.82-9.42a482.586,482.586,0,0,1-50.36-50.04l-.34-1.02a276.385,276.385,0,0,1,31.99-44.62c8.67-10.38,18.61-19.33,28.84-28.11,6.74-5.35,13.39-10.73,20.62-15.42C374.14,248.1,379.06,244.22,384.48,242.08Z"
        transform="translate(160 19)"
        fill="#f9a149"
      />
      <path
        @click="changeColor('Path_164')"
        id="Path_164"
        data-name="Path 164"
        d="M646.91,242.49l.68-.36a261.217,261.217,0,0,1,82.59,76.66c4.91,7.12,9.29,14.55,14.01,21.79a659.115,659.115,0,0,1-63.69,63.26l-.8.05-1.71.33-.57.18a176.344,176.344,0,0,0-88.97-95.79c-1.77-.79-3.58-1.44-5.4-2.1l.2-.5,1.02.12,1.36-.07c6.37-7.41,12.41-15.18,19.12-22.31,5.2-5.51,10.09-11.2,15.54-16.47C628.9,258.82,637.51,250.06,646.91,242.49Z"
        transform="translate(160 19)"
        fill="#b2d17e"
      />
      <path
        id="Path_180"
        data-name="Path 180"
        d="M721.19,244.09c1.7-.08,3.84-1,4.53,1.13.57,2.15-.08,4.72-.49,6.88l-4.11-.36A51.4,51.4,0,0,1,721.19,244.09Z"
        transform="translate(160 19)"
        fill="#c4db9c"
      />
      <path
        id="Path_184"
        data-name="Path 184"
        d="M742.92,247.81c.05,1.18.1,2.35.14,3.52-1.6.26-3.21.5-4.82.67-.07-.93-.11-1.87-.13-2.81C739.69,248.67,741.31,248.25,742.92,247.81Z"
        transform="translate(160 19)"
        fill="#c4db9c"
      />
      <path
        id="Path_189"
        data-name="Path 189"
        d="M234,265.01c.11,1.38-1.01,4.2-2.74,3.68C230.72,267.06,232.57,265.44,234,265.01Z"
        transform="translate(160 19)"
        fill="#797778"
      />
      <path
        id="Path_190"
        data-name="Path 190"
        d="M235.06,265.87c1.3.74,3.07,2.18,1.24,3.48A4.632,4.632,0,0,1,235.06,265.87Z"
        transform="translate(160 19)"
        fill="#a78567"
      />
      <path
        id="Path_191"
        data-name="Path 191"
        d="M796.17,266.18l.69-.56.73.41c.38.71.13,1.45-.74,2.21A2.484,2.484,0,0,1,796.17,266.18Z"
        transform="translate(160 19)"
        fill="#7b816d"
      />
      <path
        id="Path_192"
        data-name="Path 192"
        d="M799.01,269.24c1.28-.4,2.95,2.52,2.53,3.6C800.19,273.26,798.29,270.37,799.01,269.24Z"
        transform="translate(160 19)"
        fill="#807e7e"
      />
      <path
        id="Path_193"
        data-name="Path 193"
        d="M230.01,270.77c.03,1.18-.94,3.95-2.51,3.22C226.89,272.55,228.78,271.11,230.01,270.77Z"
        transform="translate(160 19)"
        fill="#737171"
      />
      <path
        id="Path_194"
        data-name="Path 194"
        d="M238.43,271.45a4.134,4.134,0,0,1,1.81,3.61C238.71,274.33,237.63,273.22,238.43,271.45Z"
        transform="translate(160 19)"
        fill="#aa8e74"
      />
      <path
        id="Path_195"
        data-name="Path 195"
        d="M793.5,272.26l.41.23-.26.42-.38-.24Z"
        transform="translate(160 19)"
        fill="#6c725c"
      />
      <path
        id="Path_196"
        data-name="Path 196"
        d="M792.55,274.58l.13-.18-.25.38Z"
        transform="translate(160 19)"
        fill="#75786b"
      />
      <path
        id="Path_197"
        data-name="Path 197"
        d="M802.81,274.94a2.92,2.92,0,0,1,2.19,3.22A2.92,2.92,0,0,1,802.81,274.94Z"
        transform="translate(160 19)"
        fill="#5e5c5c"
      />
      <path
        id="Path_198"
        data-name="Path 198"
        d="M226.55,276.09c.56,1.58-1.17,3.3-2.53,3.86C223.83,278.57,224.75,275.58,226.55,276.09Z"
        transform="translate(160 19)"
        fill="#7a7778"
      />
      <path
        id="Path_199"
        data-name="Path 199"
        d="M790.82,277.35l-.23.42v-.1Z"
        transform="translate(160 19)"
        fill="#747a65"
      />
      <path
        id="Path_200"
        data-name="Path 200"
        d="M241.72,278.02c1.71.17,2.21,1.53,2.55,3C242.44,280.99,242.01,279.54,241.72,278.02Z"
        transform="translate(160 19)"
        fill="#a48568"
      />
      <path
        id="Path_201"
        data-name="Path 201"
        d="M789.59,279.56l.17-.27-.16.29-.19.24Z"
        transform="translate(160 19)"
        fill="#75756f"
      />
      <path
        id="Path_202"
        data-name="Path 202"
        d="M806.14,280.28c1.68-.5,2.61,2.51,2.73,3.76C807.37,283.64,805.41,281.97,806.14,280.28Z"
        transform="translate(160 19)"
        fill="#7b7979"
      />
      <path
        id="Path_203"
        data-name="Path 203"
        d="M222.95,281.84c-.07,1.17-1.02,3.97-2.58,3.3C219.82,283.62,221.65,282.21,222.95,281.84Z"
        transform="translate(160 19)"
        fill="#737172"
      />
      <path
        id="Path_204"
        data-name="Path 204"
        d="M244.84,282.89c2.18.85,2.53,1.68,2.52,3.96A4.344,4.344,0,0,1,244.84,282.89Z"
        transform="translate(160 19)"
        fill="#b4a190"
      />
      <path
        id="Path_205"
        data-name="Path 205"
        d="M787.15,282.86c-.04,1.66-.43,3.26-2.43,3.19A4.186,4.186,0,0,1,787.15,282.86Z"
        transform="translate(160 19)"
        fill="#8d967b"
      />
      <path
        id="Path_206"
        data-name="Path 206"
        d="M809.85,286a3.047,3.047,0,0,1,2.15,3.32A3,3,0,0,1,809.85,286Z"
        transform="translate(160 19)"
        fill="#636161"
      />
      <path
        id="Path_207"
        data-name="Path 207"
        d="M219.04,287.66a3.118,3.118,0,0,1-2.11,3.36A2.972,2.972,0,0,1,219.04,287.66Z"
        transform="translate(160 19)"
        fill="#605d5e"
      />
      <path
        id="Path_208"
        data-name="Path 208"
        d="M784.14,287.88a4.5,4.5,0,0,1-1.92,3.5C781.39,289.54,782.49,288.51,784.14,287.88Z"
        transform="translate(160 19)"
        fill="#989e8a"
      />
      <path
        id="Path_209"
        data-name="Path 209"
        d="M248.94,288.77c1.91,1,2.26,1.87,2.41,3.97C249.37,291.71,248.86,291.05,248.94,288.77Z"
        transform="translate(160 19)"
        fill="#b19881"
      />
      <path
        @click="changeColor('Path_210')"
        id="Path_210"
        data-name="Path 210"
        d="M583.25,306.01l-.2.5q-32.34,74.355-64.85,148.66c-1.34,3.11-2.73,6.21-4.18,9.27q-1.47-.075-2.91-.09c-21.08-53.62-42.52-107.12-63.51-160.77l.55-.38a179.168,179.168,0,0,1,135.1,2.81Z"
        transform="translate(160 19)"
        fill="#fec50d"
      />
      <path
        id="Path_211"
        data-name="Path 211"
        d="M813.25,291.88c1.24-.44,2.77,2.18,2.36,3.23C814.37,295.6,812.83,292.92,813.25,291.88Z"
        transform="translate(160 19)"
        fill="#737071"
      />
      <path
        id="Path_212"
        data-name="Path 212"
        d="M215.95,292.98c.13,1.24-.75,4.4-2.43,3.91C212.89,295.25,214.44,293.49,215.95,292.98Z"
        transform="translate(160 19)"
        fill="#797677"
      />
      <path
        id="Path_213"
        data-name="Path 213"
        d="M252.15,293.93c1.83,1.06,2.67,1.85,3.11,3.99C252.66,297.83,252.42,296.07,252.15,293.93Z"
        transform="translate(160 19)"
        fill="#b49880"
      />
      <path
        id="Path_214"
        data-name="Path 214"
        d="M779.79,294.35l-.21.38v-.13Z"
        transform="translate(160 19)"
        fill="#767f62"
      />
      <path
        id="Path_217"
        data-name="Path 217"
        d="M778.56,296.57l.17-.29-.16.31-.19.24Z"
        transform="translate(160 19)"
        fill="#777771"
      />
      <path
        id="Path_219"
        data-name="Path 219"
        d="M816.31,297.43c1.29-.61,2.59,1.79,2.71,2.81.11,1.51-.81,1.34-1.6.44C816.83,299.9,815.82,298.43,816.31,297.43Z"
        transform="translate(160 19)"
        fill="#929090"
      />
      <path
        id="Path_226"
        data-name="Path 226"
        d="M212.85,298.93c-.08,1.25-.92,4.28-2.64,3.74C209.52,301.03,211.33,299.29,212.85,298.93Z"
        transform="translate(160 19)"
        fill="#7e7b7c"
      />
      <path
        id="Path_229"
        data-name="Path 229"
        d="M255.88,299.97c2.63.03,2.91,1.65,3.25,3.83C257.37,302.86,256.29,302.03,255.88,299.97Z"
        transform="translate(160 19)"
        fill="#b79b82"
      />
      <path
        id="Path_230"
        data-name="Path 230"
        d="M776.19,299.98c-.42,1.44-.91,2.8-2.68,2.72C773.83,301.2,774.44,299.92,776.19,299.98Z"
        transform="translate(160 19)"
        fill="#959e82"
      />
      <path
        id="Path_254"
        data-name="Path 254"
        d="M819.62,303.13c1.43-.64,3.11,2.69,2.29,3.76C820.48,307.52,818.87,304.22,819.62,303.13Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        @click="changeColor('Path_262')"
        id="Path_262"
        data-name="Path 262"
        d="M446.7,303.58h.9c20.99,53.65,42.43,107.15,63.51,160.77-.85.88-1.64,1.8-2.4,2.75-53.62-21.19-107.16-42.65-160.72-63.99.25-.58.49-1.16.73-1.74l.6-1.48c14.56-35.46,41.47-65.82,74.64-84.97C431.24,310.65,438.95,307.17,446.7,303.58Z"
        transform="translate(160 19)"
        fill="#f68c1f"
      />
      <path
        id="Path_263"
        data-name="Path 263"
        d="M209.23,305a2.958,2.958,0,0,1-2.17,3.32A3.131,3.131,0,0,1,209.23,305Z"
        transform="translate(160 19)"
        fill="#6c696a"
      />
      <path
        id="Path_264"
        data-name="Path 264"
        d="M259.98,304.99c1.95,1.02,2.72,1.71,3.21,3.92C260.67,308.71,260.27,307.18,259.98,304.99Z"
        transform="translate(160 19)"
        fill="#b89575"
      />
      <path
        id="Path_265"
        data-name="Path 265"
        d="M772.46,305.88a2.821,2.821,0,0,1-2.63,2.1C770.17,306.7,770.77,304.3,772.46,305.88Z"
        transform="translate(160 19)"
        fill="#909a7b"
      />
      <path
        @click="changeColor('Path_266')"
        id="Path_266"
        data-name="Path 266"
        d="M583.05,306.51c1.82.66,3.63,1.31,5.4,2.1a176.344,176.344,0,0,1,88.97,95.79c-53.41,20.7-107.25,41.79-160.66,62.61-.9-.87-1.79-1.74-2.74-2.57,1.45-3.06,2.84-6.16,4.18-9.27Q550.585,380.83,583.05,306.51Z"
        transform="translate(160 19)"
        fill="#9fc85e"
      />
      <path
        id="Path_268"
        data-name="Path 268"
        d="M325.88,307.82c.04,1.13.09,2.26.13,3.4-1.67.34-3.36.67-5.04.99.05-1.05.12-2.1.2-3.14C322.73,308.59,324.3,308.21,325.88,307.82Z"
        transform="translate(160 19)"
        fill="#f9a149"
      />
      <path
        id="Path_272"
        data-name="Path 272"
        d="M823.08,309.19c1.53-.4,2.08,2.76,1.88,3.83C823.69,312.36,822.33,310.64,823.08,309.19Z"
        transform="translate(160 19)"
        fill="#676465"
      />
      <path
        id="Path_274"
        data-name="Path 274"
        d="M206.32,310.93c.55.98-.88,4-2.14,3.61C203.42,313.57,204.98,310.1,206.32,310.93Z"
        transform="translate(160 19)"
        fill="#828080"
      />
      <path
        id="Path_275"
        data-name="Path 275"
        d="M263.82,310.97a2.175,2.175,0,0,1,2.19,3.1A4.474,4.474,0,0,1,263.82,310.97Z"
        transform="translate(160 19)"
        fill="#ac8b6d"
      />
      <path
        id="Path_276"
        data-name="Path 276"
        d="M768.51,311.1a3.485,3.485,0,0,1-2.89,2.82C766,311.94,766.47,311.36,768.51,311.1Z"
        transform="translate(160 19)"
        fill="#9ba18e"
      />
      <path
        id="Path_285"
        data-name="Path 285"
        d="M875.88,315.14a24.164,24.164,0,0,1,.24,5.77l-2.93-.12c-.03-2.05-.03-4.09-.02-6.14A27.46,27.46,0,0,1,875.88,315.14Z"
        transform="translate(160 19)"
        fill="#fff"
      />
      <path
        id="Path_287"
        data-name="Path 287"
        d="M825.92,315.02c1.38.57,2.84,2.43,1.99,3.92C826.13,319.55,825.62,316.21,825.92,315.02Z"
        transform="translate(160 19)"
        fill="#7d7b7c"
      />
      <path
        id="Path_288"
        data-name="Path 288"
        d="M203.93,315.93a8.178,8.178,0,0,1-3.16,5.5C201.27,318.9,201.56,317.28,203.93,315.93Z"
        transform="translate(160 19)"
        fill="#999797"
      />
      <path
        id="Path_289"
        data-name="Path 289"
        d="M268.2,315.72c1.33.82,2.15,1.93,1.65,3.54C268.49,318.49,267.67,317.31,268.2,315.72Z"
        transform="translate(160 19)"
        fill="#a78f7a"
      />
      <path
        id="Path_290"
        data-name="Path 290"
        d="M764.4,316.78a2.887,2.887,0,0,1-2.73,2.03C762.04,317.55,762.8,315.16,764.4,316.78Z"
        transform="translate(160 19)"
        fill="#959b85"
      />
      <path
        id="Path_291"
        data-name="Path 291"
        d="M272.19,320.88a6.057,6.057,0,0,1,3.07,3.93C272.83,324.56,271.91,323.28,272.19,320.88Z"
        transform="translate(160 19)"
        fill="#b5977c"
      />
      <path
        id="Path_292"
        data-name="Path 292"
        d="M760.44,321.85a2.848,2.848,0,0,1-2.73,2.01C758.12,322.59,758.8,320.24,760.44,321.85Z"
        transform="translate(160 19)"
        fill="#91997f"
      />
      <path
        id="Path_293"
        data-name="Path 293"
        d="M828.63,321.1c1.26-.45,2.83,2.84,2.2,3.79C829.41,325.33,828.05,322.2,828.63,321.1Z"
        transform="translate(160 19)"
        fill="#7a7878"
      />
      <path
        id="Path_294"
        data-name="Path 294"
        d="M200.55,322.91c.53,1.06-.92,4.1-2.29,3.6C197.64,325.51,199.25,322.26,200.55,322.91Z"
        transform="translate(160 19)"
        fill="#817f7f"
      />
      <path
        id="Path_295"
        data-name="Path 295"
        d="M276.02,326.84c2.02.05,2.72,1.3,3.3,3.03C277.34,329.9,276.53,328.55,276.02,326.84Z"
        transform="translate(160 19)"
        fill="#ad9076"
      />
      <path
        id="Path_296"
        data-name="Path 296"
        d="M756.25,326.8a2.921,2.921,0,0,1-2.38,2.43A2.336,2.336,0,0,1,756.25,326.8Z"
        transform="translate(160 19)"
        fill="#8b927b"
      />
      <path
        id="Path_297"
        data-name="Path 297"
        d="M831.42,327.15c1.37-.68,3,2.85,2.24,3.83C832.14,331.68,830.7,328.24,831.42,327.15Z"
        transform="translate(160 19)"
        fill="#8b8989"
      />
      <path
        id="Path_298"
        data-name="Path 298"
        d="M197.93,328.8a4.013,4.013,0,0,1-1.93,3.99C195.63,330.98,195.91,329.3,197.93,328.8Z"
        transform="translate(160 19)"
        fill="#706e6f"
      />
      <path
        id="Path_300"
        data-name="Path 300"
        d="M752.24,331.01c-.18,1.54-.58,2.66-2.06,3.39C749.53,332.58,750.72,331.66,752.24,331.01Z"
        transform="translate(160 19)"
        fill="#949d81"
      />
      <path
        id="Path_301"
        data-name="Path 301"
        d="M280.09,331.74c2.06.56,2.59,1.14,3.27,3.14C281.21,335.07,280.62,333.46,280.09,331.74Z"
        transform="translate(160 19)"
        fill="#ac927a"
      />
      <path
        id="Path_308"
        data-name="Path 308"
        d="M834.12,333.27c1.35-.49,2.51,2.79,1.78,3.72C834.48,337.58,833.38,334.21,834.12,333.27Z"
        transform="translate(160 19)"
        fill="#7a7778"
      />
      <path
        id="Path_309"
        data-name="Path 309"
        d="M195.26,335.03c.61,1.03-.68,4.19-2.05,3.76C192.58,337.9,193.95,334.31,195.26,335.03Z"
        transform="translate(160 19)"
        fill="#7b7879"
      />
      <path
        id="Path_310"
        data-name="Path 310"
        d="M489.91,334.7q1.47,0,2.94.09c.06,1.07.12,2.15.18,3.22-1.81-.02-3.62-.04-5.43-.08C488.2,336.77,488.19,334.68,489.91,334.7Z"
        transform="translate(160 19)"
        fill="#fec50d"
      />
      <path
        id="Path_311"
        data-name="Path 311"
        d="M747.84,335.85c.39,2.54-.86,3.28-3.01,4.05C745.37,337.8,745.84,336.86,747.84,335.85Z"
        transform="translate(160 19)"
        fill="#a1a892"
      />
      <path
        id="Path_312"
        data-name="Path 312"
        d="M284.81,336.68c1,.91,2.02,1.8,2.99,2.73l.34,1.02c-.96,1.09-1.95,2.15-2.95,3.21C285.72,341.03,285.99,339.18,284.81,336.68Z"
        transform="translate(160 19)"
        fill="#93857a"
      />
      <path
        id="Path_314"
        data-name="Path 314"
        d="M836.17,338.92c2.21,1.07,2.35,3.03,3.16,5.11-.77-.38-1.53-.77-2.29-1.17A37.379,37.379,0,0,1,836.17,338.92Z"
        transform="translate(160 19)"
        fill="#a19fa0"
      />
      <path
        id="Path_315"
        data-name="Path 315"
        d="M192.87,340.97c.11,1.06-.5,4.73-2.14,3.99C189.98,343.3,191.31,341.55,192.87,340.97Z"
        transform="translate(160 19)"
        fill="#7a7778"
      />
      <path
        id="Path_316"
        data-name="Path 316"
        d="M744.96,341.34c1.13.21,2.46,2.57,1.89,3.6C745.56,345.68,743.31,341.73,744.96,341.34Z"
        transform="translate(160 19)"
        fill="#848182"
      />
      <path
        id="Path_317"
        data-name="Path 317"
        d="M284.51,345.1c.64,1.68-.87,3.4-2.43,3.91C282.02,347.8,282.83,344.57,284.51,345.1Z"
        transform="translate(160 19)"
        fill="#7c797a"
      />
      <path
        id="Path_318"
        data-name="Path 318"
        d="M839.15,346.01c1.65-.54,2,2.8,1.74,3.88C839.73,349.11,838.43,347.44,839.15,346.01Z"
        transform="translate(160 19)"
        fill="#6a6868"
      />
      <path
        id="Path_319"
        data-name="Path 319"
        d="M190.86,346.51c-.65,1.65-.37,5.13-2.74,5.19C188.25,349.04,188.64,348.07,190.86,346.51Z"
        transform="translate(160 19)"
        fill="#a3a1a1"
      />
      <path
        id="Path_320"
        data-name="Path 320"
        d="M747.97,347.39c1.17-.18,2.44,2.64,1.88,3.56C748.5,351.34,747.36,348.4,747.97,347.39Z"
        transform="translate(160 19)"
        fill="#6a6768"
      />
      <path
        id="Path_321"
        data-name="Path 321"
        d="M282.22,350.42c-.79,2.26-1.24,3.96-3.51,5.14C279.41,353.1,279.87,351.69,282.22,350.42Z"
        transform="translate(160 19)"
        fill="#929091"
      />
      <path
        id="Path_322"
        data-name="Path 322"
        d="M841.19,352.11c1.67-.47,2.03,2.81,1.73,3.9C841.77,355.29,840.51,353.46,841.19,352.11Z"
        transform="translate(160 19)"
        fill="#666364"
      />
      <path
        id="Path_323"
        data-name="Path 323"
        d="M750.52,353.27c1.36-.82,3.07,2.7,2.32,3.69C751.36,357.61,749.77,354.41,750.52,353.27Z"
        transform="translate(160 19)"
        fill="#8e8c8c"
      />
      <path
        id="Path_324"
        data-name="Path 324"
        d="M187.42,353.66c1.42.97.66,4.58-1.22,3.99C185.44,356.47,186.68,354.59,187.42,353.66Z"
        transform="translate(160 19)"
        fill="#838181"
      />
      <path
        id="Path_325"
        data-name="Path 325"
        d="M279.32,356.39c-.8,2.4-1.2,4-3.64,5.17C276.49,359.24,277.09,357.68,279.32,356.39Z"
        transform="translate(160 19)"
        fill="#929191"
      />
      <path
        id="Path_326"
        data-name="Path 326"
        d="M843.28,358.31c1.76-.33,2.39,2.82,1.18,3.87C843.81,361.21,842.71,359.48,843.28,358.31Z"
        transform="translate(160 19)"
        fill="#726f70"
      />
      <path
        id="Path_327"
        data-name="Path 327"
        d="M752.89,358.74c2.79,1.39,3.04,2.62,2.73,5.53A19.407,19.407,0,0,1,752.89,358.74Z"
        transform="translate(160 19)"
        fill="#9a9898"
      />
      <path
        id="Path_328"
        data-name="Path 328"
        d="M185.93,359.93c.34,1.78-.09,3.31-1.83,4.1C183.91,362.35,184.22,360.66,185.93,359.93Z"
        transform="translate(160 19)"
        fill="#676465"
      />
      <path
        id="Path_329"
        data-name="Path 329"
        d="M275.37,363.14c1.53-.3-.17,4.37-1.28,3.69A3.3,3.3,0,0,1,275.37,363.14Z"
        transform="translate(160 19)"
        fill="#676566"
      />
      <path
        id="Path_330"
        data-name="Path 330"
        d="M756.17,365.35c1.55-.61,2.76,3.15,1.8,4.12C756.6,369.77,755.37,366.3,756.17,365.35Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_331"
        data-name="Path 331"
        d="M845.42,364.81c2.24-.7,2.47,3,1.54,4.34C846.01,368.14,844.49,366.21,845.42,364.81Z"
        transform="translate(160 19)"
        fill="#8f8d8e"
      />
      <path
        id="Path_332"
        data-name="Path 332"
        d="M183.89,366.01c.38,1.14.22,4.78-1.7,4.02C181.36,368.59,182.6,366.74,183.89,366.01Z"
        transform="translate(160 19)"
        fill="#7c7a7b"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M273.83,368.6c-.63,2.37-.64,4.15-3.26,5.16C271.3,371.64,271.91,369.92,273.83,368.6Z"
        transform="translate(160 19)"
        fill="#979696"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M847.27,371.16c1.63-.55,1.96,2.85,1.63,3.87C847.69,374.35,846.59,372.51,847.27,371.16Z"
        transform="translate(160 19)"
        fill="#696767"
      />
      <path
        id="Path_335"
        data-name="Path 335"
        d="M758.92,371.93c1.22.56,2.54,2.36,1.84,3.69C759.1,376.15,758.66,373.03,758.92,371.93Z"
        transform="translate(160 19)"
        fill="#757373"
      />
      <path
        id="Path_336"
        data-name="Path 336"
        d="M181.38,372.73c1.46.86,1,4.65-.96,4.04C179.65,375.63,180.71,373.72,181.38,372.73Z"
        transform="translate(160 19)"
        fill="#827f80"
      />
      <path
        id="Path_337"
        data-name="Path 337"
        d="M270.28,375.45c1.32.79.33,4.64-1.2,4.13C268.25,378.26,269.26,376.39,270.28,375.45Z"
        transform="translate(160 19)"
        fill="#898788"
      />
      <path
        id="Path_338"
        data-name="Path 338"
        d="M849.19,377.41c1.32-.75,2.85,4.37,1.13,4.33C848.98,381.16,848.41,378.58,849.19,377.41Z"
        transform="translate(160 19)"
        fill="#898787"
      />
      <path
        id="Path_339"
        data-name="Path 339"
        d="M761.12,378.06c1.79-.67,2.14,2.85,1.8,3.98C761.5,381.42,760.28,379.54,761.12,378.06Z"
        transform="translate(160 19)"
        fill="#767374"
      />
      <path
        id="Path_340"
        data-name="Path 340"
        d="M180.46,379.09c.72,1.01-.04,3.76-1.26,4.13C177.51,383.13,178.95,378.05,180.46,379.09Z"
        transform="translate(160 19)"
        fill="#888686"
      />
      <path
        id="Path_347"
        data-name="Path 347"
        d="M268.76,381.96c.84,1.37-.56,3.3-1.74,4.02C266.59,384.75,266.78,381.29,268.76,381.96Z"
        transform="translate(160 19)"
        fill="#7b7979"
      />
      <path
        id="Path_349"
        data-name="Path 349"
        d="M763.16,384.18c1.44-.81,2.96,3.96,1.19,3.84C763.3,387.33,762.54,385.33,763.16,384.18Z"
        transform="translate(160 19)"
        fill="#787576"
      />
      <path
        id="Path_350"
        data-name="Path 350"
        d="M851.64,383.88c.64,1.03,1.64,2.92.88,4.08C850.56,388.6,850.16,384.7,851.64,383.88Z"
        transform="translate(160 19)"
        fill="#797778"
      />
      <path
        id="Path_351"
        data-name="Path 351"
        d="M179.05,384.67a8.681,8.681,0,0,1-2.06,6C176.91,387.81,176.83,386.58,179.05,384.67Z"
        transform="translate(160 19)"
        fill="#959393"
      />
      <path
        id="Path_368"
        data-name="Path 368"
        d="M460.21,385.81c3.06.63,3.34.67,4.64,3.69-1.9.1-3.8.2-5.7.27C459.5,388.45,459.85,387.13,460.21,385.81Z"
        transform="translate(160 19)"
        fill="#ea851f"
      />
      <path
        id="Path_372"
        data-name="Path 372"
        d="M266.74,388.11c.78,1.23-.39,3.19-1.22,4.14C263.82,391.36,264.72,387.32,266.74,388.11Z"
        transform="translate(160 19)"
        fill="#8f8d8d"
      />
      <path
        id="Path_373"
        data-name="Path 373"
        d="M765.18,390.4c2.14-.7,2.22,3.1,1.75,4.38C765.67,393.91,764.16,391.92,765.18,390.4Z"
        transform="translate(160 19)"
        fill="#898788"
      />
      <path
        id="Path_374"
        data-name="Path 374"
        d="M852.25,390.42c1.6-.94,2.72,4.18,1.07,4.28C852.19,394.05,851.49,391.51,852.25,390.42Z"
        transform="translate(160 19)"
        fill="#8d8b8b"
      />
      <path
        id="Path_375"
        data-name="Path 375"
        d="M430.98,390.51c-.01,1.2-.03,2.41-.05,3.61-1.72.19-3.29.5-4.97-.12C425.36,390.69,428.84,391.24,430.98,390.51Z"
        transform="translate(160 19)"
        fill="#f38a1f"
      />
      <path
        id="Path_377"
        data-name="Path 377"
        d="M177.03,392.06c.47,1.31.36,3.2-.97,3.97C175.58,394.69,175.67,392.85,177.03,392.06Z"
        transform="translate(160 19)"
        fill="#534f50"
      />
      <path
        id="Path_378"
        data-name="Path 378"
        d="M264.53,394.78c.74,1.34-.4,3.05-1.38,3.96C262.79,397.72,262.7,394.07,264.53,394.78Z"
        transform="translate(160 19)"
        fill="#737071"
      />
      <path
        id="Path_379"
        data-name="Path 379"
        d="M767.12,397.09c1.75-.67,2.3,2.74,1.67,3.84l-.6.09C767.61,399.94,766.37,398.31,767.12,397.09Z"
        transform="translate(160 19)"
        fill="#777576"
      />
      <path
        id="Path_380"
        data-name="Path 380"
        d="M854.16,396.83c1.57.67,1.54,3.36.22,4.3C853.81,400.28,852.47,397.11,854.16,396.83Z"
        transform="translate(160 19)"
        fill="#797677"
      />
      <path
        id="Path_381"
        data-name="Path 381"
        d="M175.8,398.26c.36,1.39.85,4.07-.99,4.6C172.72,402.19,174.6,399.05,175.8,398.26Z"
        transform="translate(160 19)"
        fill="#8c8a8a"
      />
      <path
        @click="changeColor('Path_382')"
        id="Path_382"
        data-name="Path 382"
        d="M321.14,400.45c9.19.11,18.4,1,27.58.92-.24.58-.48,1.16-.73,1.74-17.21,42.74-16.42,91.93,1.42,134.35l.47,1.26h-.58a53.164,53.164,0,0,0-6.31.09A457.791,457.791,0,0,1,295,541.1c-9.98.22-19.92-.66-29.89-.54l-.99-.89c-3.35-13.42-6.37-26.88-7.91-40.64-1.23-7.02-.94-14.1-1.83-21.15a120.3,120.3,0,0,1,.51-24.88c.4-16.85,3.89-34.18,7.46-50.61l.56-1.27a24.748,24.748,0,0,0,5.09.22Q294.535,399.72,321.14,400.45Z"
        transform="translate(160 19)"
        fill="#f37743"
      />
      <path
        id="Path_383"
        data-name="Path 383"
        d="M262.91,401.12l-.56,1.27a7.909,7.909,0,0,1-3.37-.61A4.541,4.541,0,0,1,262.91,401.12Z"
        transform="translate(160 19)"
        fill="#826c65"
      />
      <path
        @click="changeColor('Path_384')"
        id="Path_384"
        data-name="Path 384"
        d="M712,401.53a510.89,510.89,0,0,1,56.44.4,289.323,289.323,0,0,1,7.23,41.1c.25,5.35.53,10.64,1.13,15.96a149.19,149.19,0,0,1-.58,24.01,242.071,242.071,0,0,1-5.7,41.59c-1,5.48-2.95,10.81-3.56,16.33l-.65-.03a79.448,79.448,0,0,0-8.31.13,665.765,665.765,0,0,1-79.28-.88l-.88-.01a1.955,1.955,0,0,0-2.67-1.99c7.84-17.22,12.05-36.34,13.71-55.14.42-8.64.18-17.34.13-26-.02-5.65-1.13-11.25-1.9-16.99-1.71-11.78-5.22-22.93-8.95-34.18,1.33-.03,1.84-.68,1.54-1.94l.8-.05C691.01,403.13,701.45,401.78,712,401.53Z"
        transform="translate(160 19)"
        fill="#82c093"
      />
      <path
        @click="changeColor('Path_385')"
        id="Path_385"
        data-name="Path 385"
        d="M768.19,401.02l.6-.09a27.524,27.524,0,0,1,6.13,1.53c1.33.83,3.26,1.62,4.09-.25,1.64.23,3.27.26,4.91.33-1.33,1.62.9,1.91,2.02,1.35,1.06-.31,2.12-.61,3.19-.9q.75,2.58,1.7.07c1.33.47,2.66.96,3.97,1.47a5.543,5.543,0,0,0,4.35.4c1.02-.01,2.04-.02,3.05-.05a1.382,1.382,0,0,0,2.73.29c1,.4,1.98.82,2.96,1.26a4.709,4.709,0,0,0,4.33.39c.91.12,1.82.23,2.72.35.79,1.18,2.65,1.93,2.97.02a38.071,38.071,0,0,0,4.21.8c.41,1.77.99,1.85,1.76.24a39.461,39.461,0,0,0,6.37,1.36c.18,1.13.76,1.61,1.77,1.44l3-.06c.65,1.3,2.46,2.16,2.89.25q1.515.495,3.03.96c1,1.53,1.62,1.51,1.84-.05a35.6,35.6,0,0,1,4.03,1.95,6.3,6.3,0,0,0,4.28,1.08,44.752,44.752,0,0,1,5.03.45c.18,1.21.78,1.68,1.77,1.4.01,7.42,1.56,14.61,1.96,21.99.39,8.05,1.65,15.9,1.02,23.99-.54,7.02.42,14.02-.08,21.02-.77,8.28-1.09,16.57-2.19,24.81-.5,5.64-2.04,11.44-1.74,17.04a2.964,2.964,0,0,0-2.15,1.06c-.92-.03-1.85-.06-2.77-.1a3,3,0,0,0-2.4.36l.43,1.18a24.029,24.029,0,0,0-6.07,1.47q-1.17-2.4-1.72.25c-1.04-.08-2.08-.17-3.11-.27a2.94,2.94,0,0,0-3.01,1.28,50.292,50.292,0,0,0-5.23,1.64q-1.23-2.385-1.72.3c-.99-.03-1.98-.04-2.98-.05a5.326,5.326,0,0,0-4.3.58c-.96.43-1.93.85-2.9,1.25-.47-1.86-2.23-1.04-2.89.25-1-.08-2.01-.16-3.02-.25a2.9,2.9,0,0,0-3.03,1.18c-1.34.26-2.68.52-4,.86-.9-1.63-1.47-1.54-1.73.27a37.847,37.847,0,0,0-5.29.75c-.3-1.93-2.16-1.18-3-.04l-2.93.33a6.123,6.123,0,0,0-4.11.07c-1.38.54-2.76,1.1-4.12,1.69q-.945-2.49-1.66.08c-1.03-.33-2.06-.65-3.1-.97-1.1-.5-2.09-.08-2.95,1.25-1.34-.03-2.68-.04-4.03-.06a4.5,4.5,0,0,0-4.1.08q-.915.69-1.77,1.44l-.24-.82c.61-5.52,2.56-10.85,3.56-16.33a242.071,242.071,0,0,0,5.7-41.59,149.19,149.19,0,0,0,.58-24.01c-.6-5.32-.88-10.61-1.13-15.96a289.323,289.323,0,0,0-7.23-41.1Z"
        transform="translate(160 19)"
        fill="#a2cdab"
      />
      <path
        id="Path_386"
        data-name="Path 386"
        d="M250.69,402.49A59.27,59.27,0,0,1,238.25,404,32.79,32.79,0,0,1,250.69,402.49Z"
        transform="translate(160 19)"
        fill="#b08d7f"
      />
      <path
        id="Path_387"
        data-name="Path 387"
        d="M256.11,401.88c-1.49,1.45-2.38,1.49-4.27.71A4.388,4.388,0,0,1,256.11,401.88Z"
        transform="translate(160 19)"
        fill="#b97c62"
      />
      <path
        @click="changeColor('Path_388')"
        id="Path_388"
        data-name="Path 388"
        d="M256.11,401.88c.96-.03,1.92-.06,2.87-.1a7.909,7.909,0,0,0,3.37.61c-3.57,16.43-7.06,33.76-7.46,50.61a120.3,120.3,0,0,0-.51,24.88c.89,7.05.6,14.13,1.83,21.15,1.54,13.76,4.56,27.22,7.91,40.64-1.24.09-2.47.2-3.7.33-1.2.03-2.39.08-3.59.13a1.375,1.375,0,0,0-2.73-.23q-1.53-.39-3.06-.75a10.641,10.641,0,0,0-3.85-.39l-3.12.09c-.75-1.2-2.61-1.98-2.96-.09-1.31-.37-2.63-.69-3.96-1a3.593,3.593,0,0,0-3.32-.74c-.83.09-1.66.19-2.5.27a5.146,5.146,0,0,0-4.41-1.29c-1.39.08-2.77.14-4.16.18q-.5-2.715-1.72-.27c-1.3-.39-2.63-.68-3.96-.97a3.215,3.215,0,0,0-3.17-1.04c-.98.13-1.96.25-2.94.38-.65-1.89-2.44-1.56-4-1.2l-1.28-.11a51.056,51.056,0,0,0-9.19-2.37c-1.03-.13-2.07-.25-3.1-.36l-2.19-.27a2.685,2.685,0,0,0-3.37-.92l-1.06-.4c-2.18-1.52-3.17-1.83-5.86-1.51l-1.77-.32q-1.725-1.83-3.35-.47c-.7-.38-1.42-.75-2.14-1.1-1.48-9.01-2.59-18.14-3.42-27.24-.34-8.16-1.83-15.61-1-23.75a25.95,25.95,0,0,0-.53-10.31c.58-6.33.1-12.64,1-18.96.52-9.51,1.65-18.93,2.92-28.37.67-.25,1.35-.5,2.02-.75a2.732,2.732,0,0,0,3.4-.79l1.91-.08a9.01,9.01,0,0,0,4.34-1.11c.84-.35,1.68-.7,2.53-1.04a2.438,2.438,0,0,0,3.36-.85c.91.01,1.83.03,2.74.05a6.727,6.727,0,0,0,4.43-1.3c.85-.25,1.69-.49,2.54-.74a2.662,2.662,0,0,0,3.34-.93c.88-.02,1.75-.05,2.63-.07,1.88.46,2.85.42,4.27-.99.91.01,1.82.04,2.73.07a3,3,0,0,0,3.15-1.26c.99.09,1.97.19,2.96.29,1.81.37,3.06-.29,4.45-1.37.82.07,1.63.16,2.45.25a2.813,2.813,0,0,0,3.11-1.27c1.02.13,2.04.27,3.06.41a4.145,4.145,0,0,0,4.09-1.03c.7-.04,1.4-.07,2.11-.11a59.27,59.27,0,0,0,12.44-1.51l1.15.1C253.73,403.37,254.62,403.33,256.11,401.88Z"
        transform="translate(160 19)"
        fill="#f7966b"
      />
      <path
        id="Path_389"
        data-name="Path 389"
        d="M774.92,402.46a5.417,5.417,0,0,1,4.09-.25C778.18,404.08,776.25,403.29,774.92,402.46Z"
        transform="translate(160 19)"
        fill="#6a796b"
      />
      <path
        @click="changeColor('Path_390')"
        id="Path_390"
        data-name="Path 390"
        d="M347.99,403.11c53.56,21.34,107.1,42.8,160.72,63.99-.04.93-.08,1.87-.11,2.8q-73.7,31.2-147.4,62.39c-3.96,1.67-7.93,3.27-11.79,5.17C331.57,495.04,330.78,445.85,347.99,403.11Z"
        transform="translate(160 19)"
        fill="#f15122"
      />
      <path
        id="Path_391"
        data-name="Path 391"
        d="M783.92,402.54a1.764,1.764,0,0,1,2.02,1.35C784.82,404.45,782.59,404.16,783.92,402.54Z"
        transform="translate(160 19)"
        fill="#85a08a"
      />
      <path
        id="Path_392"
        data-name="Path 392"
        d="M789.13,402.99l1.7.07Q789.885,405.565,789.13,402.99Z"
        transform="translate(160 19)"
        fill="#8a8a89"
      />
      <path
        id="Path_393"
        data-name="Path 393"
        d="M855.06,402.43a18.058,18.058,0,0,1,2.1,5.92C854.27,406.83,855.06,405.27,855.06,402.43Z"
        transform="translate(160 19)"
        fill="#959393"
      />
      <path
        id="Path_394"
        data-name="Path 394"
        d="M236.14,404.11a4.145,4.145,0,0,1-4.09,1.03C232.79,403.36,234.63,403.78,236.14,404.11Z"
        transform="translate(160 19)"
        fill="#977263"
      />
      <path
        @click="changeColor('Path_395')"
        id="Path_395"
        data-name="Path 395"
        d="M677.42,404.4l.57-.18.17,1.61c3.73,11.25,7.24,22.4,8.95,34.18.77,5.74,1.88,11.34,1.9,16.99.05,8.66.29,17.36-.13,26-1.66,18.8-5.87,37.92-13.71,55.14l-.34.71c-2.65-1.28-5.31-2.53-8.01-3.69-50.02-21.72-99.99-43.65-150.04-65.31-.01-.95-.02-1.89-.02-2.84C570.17,446.19,624.01,425.1,677.42,404.4Z"
        transform="translate(160 19)"
        fill="#62b47e"
      />
      <path
        id="Path_396"
        data-name="Path 396"
        d="M677.99,404.22l1.71-.33c.3,1.26-.21,1.91-1.54,1.94Z"
        transform="translate(160 19)"
        fill="#cbe4ca"
      />
      <path
        id="Path_397"
        data-name="Path 397"
        d="M794.8,404.53c1.52-1.19,3.02-.85,4.35.4A5.543,5.543,0,0,1,794.8,404.53Z"
        transform="translate(160 19)"
        fill="#707971"
      />
      <path
        id="Path_398"
        data-name="Path 398"
        d="M175.33,404.46a13.99,13.99,0,0,1-2.34,5.53C173.2,407.19,172.19,405.6,175.33,404.46Z"
        transform="translate(160 19)"
        fill="#969494"
      />
      <path
        id="Path_399"
        data-name="Path 399"
        d="M223.43,405.75c-1.39,1.08-2.64,1.74-4.45,1.37C219.74,404.94,221.63,405.34,223.43,405.75Z"
        transform="translate(160 19)"
        fill="#b38572"
      />
      <path
        id="Path_400"
        data-name="Path 400"
        d="M228.99,404.73a2.813,2.813,0,0,1-3.11,1.27C226.22,404.79,227.26,404.37,228.99,404.73Z"
        transform="translate(160 19)"
        fill="#a36b53"
      />
      <path
        id="Path_401"
        data-name="Path 401"
        d="M802.2,404.88a4.679,4.679,0,0,1,2.73.29A1.382,1.382,0,0,1,802.2,404.88Z"
        transform="translate(160 19)"
        fill="#69786b"
      />
      <path
        id="Path_402"
        data-name="Path 402"
        d="M807.89,406.43c1.52-1.1,2.97-.76,4.33.39A4.709,4.709,0,0,1,807.89,406.43Z"
        transform="translate(160 19)"
        fill="#737f74"
      />
      <path
        id="Path_403"
        data-name="Path 403"
        d="M216.02,406.83a3,3,0,0,1-3.15,1.26Q213.365,406.26,216.02,406.83Z"
        transform="translate(160 19)"
        fill="#916452"
      />
      <path
        id="Path_404"
        data-name="Path 404"
        d="M814.94,407.17a5.078,5.078,0,0,1,2.97.02C817.59,409.1,815.73,408.35,814.94,407.17Z"
        transform="translate(160 19)"
        fill="#6a756b"
      />
      <path
        id="Path_405"
        data-name="Path 405"
        d="M210.14,408.02c-1.42,1.41-2.39,1.45-4.27.99C207.26,407.64,208.31,407.63,210.14,408.02Z"
        transform="translate(160 19)"
        fill="#917063"
      />
      <path
        id="Path_406"
        data-name="Path 406"
        d="M822.12,407.99l1.76.24C823.11,409.84,822.53,409.76,822.12,407.99Z"
        transform="translate(160 19)"
        fill="#6c746d"
      />
      <path
        id="Path_407"
        data-name="Path 407"
        d="M203.24,409.08a2.662,2.662,0,0,1-3.34.93A2.736,2.736,0,0,1,203.24,409.08Z"
        transform="translate(160 19)"
        fill="#8c7a73"
      />
      <path
        id="Path_408"
        data-name="Path 408"
        d="M197.36,410.75a6.727,6.727,0,0,1-4.43,1.3C193.78,409.85,195.47,409.88,197.36,410.75Z"
        transform="translate(160 19)"
        fill="#ab887a"
      />
      <path
        id="Path_409"
        data-name="Path 409"
        d="M830.25,409.59q1.815-.045,1.77,1.44C831.01,411.2,830.43,410.72,830.25,409.59Z"
        transform="translate(160 19)"
        fill="#778c7a"
      />
      <path
        id="Path_410"
        data-name="Path 410"
        d="M856.08,409.97c1.71.16,3.09,3.71,1.05,4.21C855.54,413.66,855.72,411.26,856.08,409.97Z"
        transform="translate(160 19)"
        fill="#888687"
      />
      <path
        id="Path_411"
        data-name="Path 411"
        d="M835.02,410.97a5.069,5.069,0,0,1,2.89.25C837.48,413.13,835.67,412.27,835.02,410.97Z"
        transform="translate(160 19)"
        fill="#6e7a6f"
      />
      <path
        id="Path_412"
        data-name="Path 412"
        d="M172.97,411.72c1.91.54.51,3.66-.79,4.27C171.83,414.75,171.48,412.33,172.97,411.72Z"
        transform="translate(160 19)"
        fill="#7e7b7c"
      />
      <path
        id="Path_413"
        data-name="Path 413"
        d="M190.19,412a2.438,2.438,0,0,1-3.36.85A3.092,3.092,0,0,1,190.19,412Z"
        transform="translate(160 19)"
        fill="#887167"
      />
      <path
        id="Path_414"
        data-name="Path 414"
        d="M840.94,412.18l1.84-.05C842.56,413.69,841.94,413.71,840.94,412.18Z"
        transform="translate(160 19)"
        fill="#7b7f7b"
      />
      <path
        id="Path_415"
        data-name="Path 415"
        d="M184.3,413.89a9.01,9.01,0,0,1-4.34,1.11C180.82,413.19,182.75,412.2,184.3,413.89Z"
        transform="translate(160 19)"
        fill="#ab8575"
      />
      <path
        id="Path_416"
        data-name="Path 416"
        d="M851.09,415.16a6.3,6.3,0,0,1-4.28-1.08C848.41,413.02,850.5,413,851.09,415.16Z"
        transform="translate(160 19)"
        fill="#a0a7a1"
      />
      <path
        id="Path_417"
        data-name="Path 417"
        d="M178.05,415.08a2.732,2.732,0,0,1-3.4.79A2.782,2.782,0,0,1,178.05,415.08Z"
        transform="translate(160 19)"
        fill="#997d72"
      />
      <path
        id="Path_418"
        data-name="Path 418"
        d="M856.12,415.61c1.18-.01,1.77.46,1.77,1.4C856.9,417.29,856.3,416.82,856.12,415.61Z"
        transform="translate(160 19)"
        fill="#737f74"
      />
      <path
        @click="changeColor('Path_419')"
        id="Path_419"
        data-name="Path 419"
        d="M172.63,416.62c-1.27,9.44-2.4,18.86-2.92,28.37-.9,6.32-.42,12.63-1,18.96-2.38.22-5.91-1.17-7.03,1.76-.61,2.47-.75,5.82.37,8.16,2.05,2.07,4.73.83,7.19.39-.83,8.14.66,15.59,1,23.75.83,9.1,1.94,18.23,3.42,27.24-39.62-11.2-77.03-28.36-109.84-53.42a174.606,174.606,0,0,1,29.3-21.69,310.059,310.059,0,0,1,67.28-29.73C164.46,419.08,168.61,418.06,172.63,416.62Z"
        transform="translate(160 19)"
        fill="#fab693"
      />
      <path
        @click="changeColor('Path_420')"
        id="Path_420"
        data-name="Path 420"
        d="M857.89,417.01c39.18,10.75,77.12,28.43,109.46,53.15-8.77,8.64-19.49,15.82-29.97,22.22-25.23,15.18-52.27,25.45-80.52,33.48-.3-5.6,1.24-11.4,1.74-17.04,1.1-8.24,1.42-16.53,2.19-24.81.5-7-.46-14,.08-21.02.63-8.09-.63-15.94-1.02-23.99C859.45,431.62,857.9,424.43,857.89,417.01Z"
        transform="translate(160 19)"
        fill="#c0dcc5"
      />
      <path
        id="Path_433"
        data-name="Path 433"
        d="M173.42,464.2c1.563,9.641,1.547,18.59.863,28.48-4.006,1.215-8.369,4.641-11.707-1.077-1.824-6.464-1.6-15.718-.6-22.541C163.8,460.967,169.545,464.807,173.42,464.2Z"
        transform="translate(154.787 9.191)"
        fill="#fab693"
      />
      <path
        id="Path_447"
        data-name="Path 447"
        d="M511.11,464.35q1.44.015,2.91.09c.95.83,1.84,1.7,2.74,2.57,0,.95.01,1.89.02,2.84-.96.82-1.92,1.64-2.92,2.4-.86.08-1.71.17-2.57.26-.89-.87-1.79-1.74-2.69-2.61.03-.93.07-1.87.11-2.8C509.47,466.15,510.26,465.23,511.11,464.35Z"
        transform="translate(160 19)"
        fill="#231f20"
      />
      <path
        id="Path_464"
        data-name="Path 464"
        d="M219.06,465.09c2.3-.25,2.16,1.86,3.12,3.39-1.83.07-3.66.13-5.49.17C217.12,467.58,217.33,464.63,219.06,465.09Z"
        transform="translate(160 19)"
        fill="#f7966b"
      />
      <path
        id="Path_466"
        data-name="Path 466"
        d="M399.33,465.34a5.715,5.715,0,0,1,3.3.05c1.17,2.24.84,5.21.39,7.61-1.58.54-3.03.21-4.62,0C398.61,470.66,397.94,467.35,399.33,465.34Z"
        transform="translate(160 19)"
        fill="#f15122"
      />
      <path
        id="Path_467"
        data-name="Path 467"
        d="M412.05,465.09c2.36-.28,2.18,1.87,3.15,3.42-1.84.07-3.68.12-5.51.16C410.12,467.61,410.32,464.61,412.05,465.09Z"
        transform="translate(160 19)"
        fill="#f15122"
      />
      <path
        id="Path_469"
        data-name="Path 469"
        d="M724.93,465.07c1.74-.37,1.95,2.52,2.43,3.61-1.9,0-3.81-.01-5.71-.05C722.55,466.92,722.34,464.61,724.93,465.07Z"
        transform="translate(160 19)"
        fill="#82c093"
      />
      <path
        id="Path_470"
        data-name="Path 470"
        d="M828.58,466.4a13.537,13.537,0,0,1-.07,6.17c-.99,1.5-3.04.59-4.55.65-.03-2.48-.07-4.95,0-7.42C825.78,465.39,827.4,464.25,828.58,466.4Z"
        transform="translate(160 19)"
        fill="#a2cdab"
      />
      <path
        id="Path_473"
        data-name="Path 473"
        d="M918.77,466.29a18.221,18.221,0,0,1-.06,6.42c-1.37,1.35-2.97.55-4.68.52-.03-2.46-.07-4.91-.01-7.37C915.83,465.26,917.44,464.29,918.77,466.29Z"
        transform="translate(160 19)"
        fill="#c0dcc5"
      />
      <path
        id="Path_474"
        data-name="Path 474"
        d="M133.88,469.5c.09,1.2.17,2.4.23,3.6-2.22.23-3.46.66-5.31-.96C130,469.94,131.72,469.98,133.88,469.5Z"
        transform="translate(160 19)"
        fill="#f0af8e"
      />
      <path
        id="Path_475"
        data-name="Path 475"
        d="M280.61,469.36c-.01,1.19-.01,2.38-.02,3.57a29.351,29.351,0,0,1-4.17.63c-1.06-1.02-.63-1.95-.53-3.24C277.46,469.97,279.03,469.67,280.61,469.36Z"
        transform="translate(160 19)"
        fill="#f37743"
      />
      <path
        @click="changeColor('Path_477')"
        id="Path_477"
        data-name="Path 477"
        d="M508.6,469.9c.9.87,1.8,1.74,2.69,2.61Q481.035,547.1,450.71,621.69a105.548,105.548,0,0,0-4.36,11.54c-2.95-1.68-6.04-3.06-9.14-4.45a177.427,177.427,0,0,1-74.69-66.3,205.435,205.435,0,0,1-12.64-23.76l-.47-1.26c3.86-1.9,7.83-3.5,11.79-5.17Q434.895,501.09,508.6,469.9Z"
        transform="translate(160 19)"
        fill="#8651a0"
      />
      <path
        @click="changeColor('Path_478')"
        id="Path_478"
        data-name="Path 478"
        d="M516.78,469.85c50.05,21.66,100.02,43.59,150.04,65.31,2.7,1.16,5.36,2.41,8.01,3.69l.1.8c-4.7,9.58-9.36,19.05-15.43,27.87a177.09,177.09,0,0,1-45.63,46.32c-9.99,7.12-20.78,12.72-31.83,17.98l-1.76.18c-1.88-5.91-4.61-11.56-6.94-17.35-19.82-47.4-39.54-95.08-59.48-142.4C514.86,471.49,515.82,470.67,516.78,469.85Z"
        transform="translate(160 19)"
        fill="#91b4ba"
      />
      <path
        id="Path_479"
        data-name="Path 479"
        d="M737.99,469.42c-.03,1.24-.06,2.47-.09,3.71-2.14.11-3.63,1.02-5.12-1.12C733.84,469.72,735.88,470.09,737.99,469.42Z"
        transform="translate(160 19)"
        fill="#82c093"
      />
      <path
        @click="changeColor('Path_480')"
        id="Path_480"
        data-name="Path 480"
        d="M511.29,472.51c.86-.09,1.71-.18,2.57-.26,19.94,47.32,39.66,95,59.48,142.4,2.33,5.79,5.06,11.44,6.94,17.35a113.081,113.081,0,0,1-17.73,6.51,182.278,182.278,0,0,1-103.1-1c-4.33-1.37-8.49-3.14-12.92-4.23l-.18-.05a105.548,105.548,0,0,1,4.36-11.54Q480.98,547.1,511.29,472.51Z"
        transform="translate(160 19)"
        fill="#3d5ba9"
      />
      <path
        id="Path_481"
        data-name="Path 481"
        d="M173.67,525.93a3.281,3.281,0,0,1,1,3.96C172.84,531.02,171.87,526.01,173.67,525.93Z"
        transform="translate(160 19)"
        fill="#868485"
      />
      <path
        id="Path_482"
        data-name="Path 482"
        d="M175.8,526.35q1.62-1.365,3.35.47A3.124,3.124,0,0,1,175.8,526.35Z"
        transform="translate(160 19)"
        fill="#8f7368"
      />
      <path
        id="Path_483"
        data-name="Path 483"
        d="M856.86,525.86c.93,2.13-.84,2.13-2.15,1.06A2.964,2.964,0,0,1,856.86,525.86Z"
        transform="translate(160 19)"
        fill="#525150"
      />
      <path
        id="Path_484"
        data-name="Path 484"
        d="M180.92,527.14c2.69-.32,3.68-.01,5.86,1.51C183.95,529.3,182.96,529.4,180.92,527.14Z"
        transform="translate(160 19)"
        fill="#bf9684"
      />
      <path
        id="Path_485"
        data-name="Path 485"
        d="M849.54,527.18a3,3,0,0,1,2.4-.36,1.805,1.805,0,0,1-1.97,1.54Z"
        transform="translate(160 19)"
        fill="#748576"
      />
      <path
        id="Path_486"
        data-name="Path 486"
        d="M187.84,529.05a2.685,2.685,0,0,1,3.37.92A2.836,2.836,0,0,1,187.84,529.05Z"
        transform="translate(160 19)"
        fill="#876b61"
      />
      <path
        id="Path_487"
        data-name="Path 487"
        d="M843.9,529.83l-1.72.25Q842.735,527.425,843.9,529.83Z"
        transform="translate(160 19)"
        fill="#6d756e"
      />
      <path
        id="Path_488"
        data-name="Path 488"
        d="M193.4,530.24c1.03.11,2.07.23,3.1.36a51.056,51.056,0,0,1,9.19,2.37,24.621,24.621,0,0,1-5.91-.94A21.742,21.742,0,0,1,193.4,530.24Z"
        transform="translate(160 19)"
        fill="#ba9788"
      />
      <path
        id="Path_489"
        data-name="Path 489"
        d="M839.07,529.81c-.19,1.3-1.2,1.72-3.01,1.28A2.94,2.94,0,0,1,839.07,529.81Z"
        transform="translate(160 19)"
        fill="#708273"
      />
      <path
        id="Path_490"
        data-name="Path 490"
        d="M855.91,529.3c-.09,2.63,1.02,4.49-1.8,5.86A21.87,21.87,0,0,1,855.91,529.3Z"
        transform="translate(160 19)"
        fill="#949293"
      />
      <path
        id="Path_491"
        data-name="Path 491"
        d="M173.98,531.42c1.73,2.06,2.08,3.4,2.08,6.11C174.07,535.45,173.86,534.32,173.98,531.42Z"
        transform="translate(160 19)"
        fill="#969394"
      />
      <path
        id="Path_492"
        data-name="Path 492"
        d="M830.83,532.73l-1.72.3Q829.605,530.345,830.83,532.73Z"
        transform="translate(160 19)"
        fill="#828381"
      />
      <path
        id="Path_493"
        data-name="Path 493"
        d="M206.97,533.08c1.56-.36,3.35-.69,4,1.2A4.535,4.535,0,0,1,206.97,533.08Z"
        transform="translate(160 19)"
        fill="#a37867"
      />
      <path
        id="Path_494"
        data-name="Path 494"
        d="M826.13,532.98c-1.28,1.32-2.72,1.7-4.3.58A5.326,5.326,0,0,1,826.13,532.98Z"
        transform="translate(160 19)"
        fill="#757e76"
      />
      <path
        id="Path_495"
        data-name="Path 495"
        d="M213.91,533.9a3.215,3.215,0,0,1,3.17,1.04C215.29,535.54,214.24,535.19,213.91,533.9Z"
        transform="translate(160 19)"
        fill="#976652"
      />
      <path
        id="Path_496"
        data-name="Path 496"
        d="M818.93,534.81a4.308,4.308,0,0,1-2.89.25C816.7,533.77,818.46,532.95,818.93,534.81Z"
        transform="translate(160 19)"
        fill="#69776b"
      />
      <path
        id="Path_497"
        data-name="Path 497"
        d="M222.76,536.18l-1.72-.27Q222.27,533.465,222.76,536.18Z"
        transform="translate(160 19)"
        fill="#96634d"
      />
      <path
        id="Path_498"
        data-name="Path 498"
        d="M813.02,534.81c-.23,1.29-1.24,1.69-3.03,1.18A2.9,2.9,0,0,1,813.02,534.81Z"
        transform="translate(160 19)"
        fill="#6e7c6f"
      />
      <path
        id="Path_499"
        data-name="Path 499"
        d="M226.92,536a5.146,5.146,0,0,1,4.41,1.29C229.52,537.81,227.7,538.22,226.92,536Z"
        transform="translate(160 19)"
        fill="#b29183"
      />
      <path
        id="Path_500"
        data-name="Path 500"
        d="M805.99,536.85l-1.73.27C804.52,535.31,805.09,535.22,805.99,536.85Z"
        transform="translate(160 19)"
        fill="#687a6b"
      />
      <path
        id="Path_501"
        data-name="Path 501"
        d="M233.83,537.02a3.593,3.593,0,0,1,3.32.74C235.39,538.53,234.28,538.29,233.83,537.02Z"
        transform="translate(160 19)"
        fill="#8e604d"
      />
      <path
        id="Path_506"
        data-name="Path 506"
        d="M798.97,537.87a5.381,5.381,0,0,1-3-.04C796.81,536.69,798.67,535.94,798.97,537.87Z"
        transform="translate(160 19)"
        fill="#657568"
      />
      <path
        id="Path_507"
        data-name="Path 507"
        d="M854.85,537.12a2.849,2.849,0,0,1-1.76,3.92C852.77,540,853.16,536.38,854.85,537.12Z"
        transform="translate(160 19)"
        fill="#7b7879"
      />
      <path
        id="Path_508"
        data-name="Path 508"
        d="M244.07,538.85a4.939,4.939,0,0,1-2.96-.09C241.46,536.87,243.32,537.65,244.07,538.85Z"
        transform="translate(160 19)"
        fill="#826a61"
      />
      <path
        id="Path_510"
        data-name="Path 510"
        d="M675.17,538.14a1.955,1.955,0,0,1,2.67,1.99,5.608,5.608,0,0,1-2.91-.48l-.1-.8Z"
        transform="translate(160 19)"
        fill="#daebe0"
      />
      <path
        id="Path_511"
        data-name="Path 511"
        d="M788.93,538.23a6.123,6.123,0,0,1,4.11-.07C791.82,539.15,789.86,540.05,788.93,538.23Z"
        transform="translate(160 19)"
        fill="#7b807b"
      />
      <path
        id="Path_512"
        data-name="Path 512"
        d="M175.66,539c1.77-.55,1.62,3.01,1.23,3.99C175.82,542.17,174.86,540.25,175.66,539Z"
        transform="translate(160 19)"
        fill="#686566"
      />
      <path
        id="Path_513"
        data-name="Path 513"
        d="M247.19,538.76a10.641,10.641,0,0,1,3.85.39C250.15,540.89,247.91,540.4,247.19,538.76Z"
        transform="translate(160 19)"
        fill="#a7a3a2"
      />
      <path
        id="Path_514"
        data-name="Path 514"
        d="M256.83,540.13a6.319,6.319,0,0,1-2.73-.23A1.375,1.375,0,0,1,256.83,540.13Z"
        transform="translate(160 19)"
        fill="#9e664f"
      />
      <path
        @click="changeColor('Path_515')"
        id="Path_515"
        data-name="Path 515"
        d="M349.3,538.72h.58a205.435,205.435,0,0,0,12.64,23.76,177.427,177.427,0,0,0,74.69,66.3c3.1,1.39,6.19,2.77,9.14,4.45l.17.09-.42,1.69a514.4,514.4,0,0,1-57.48,60.86c-.54.41-.56,1.09-.06,2.03-1.3.42-2.59.85-3.89,1.28.88-1.13,1.76-2.26,2.63-3.39-15.46-9.22-30.31-19.33-43.79-31.32-16.79-15.04-32-31.67-44.48-50.5-3.38-4.97-6.66-10.01-9.7-15.21a613.376,613.376,0,0,1,48.33-49.09C341.45,545.92,345.88,542.78,349.3,538.72Z"
        transform="translate(160 19)"
        fill="#986eaf"
      />
      <path
        id="Path_516"
        data-name="Path 516"
        d="M780.05,539.03c-.28,1.13-1.26,1.55-2.95,1.25C777.96,538.95,778.95,538.53,780.05,539.03Z"
        transform="translate(160 19)"
        fill="#7c9480"
      />
      <path
        id="Path_517"
        data-name="Path 517"
        d="M784.81,539.92l-.81.37-.85-.29Q783.87,537.435,784.81,539.92Z"
        transform="translate(160 19)"
        fill="#919090"
      />
      <path
        id="Path_518"
        data-name="Path 518"
        d="M260.42,540c1.23-.13,2.46-.24,3.7-.33l.99.89a18.238,18.238,0,0,1-.31,2.22C263.35,541.83,261.97,540.83,260.42,540Z"
        transform="translate(160 19)"
        fill="#6e6664"
      />
      <path
        @click="changeColor('Path_527')"
        id="Path_527"
        data-name="Path 527"
        d="M674.93,539.65a5.608,5.608,0,0,0,2.91.48l.88.01a516.416,516.416,0,0,1,61.42,57.51c.34.75.67,1.49,1,2.24-22.88,38.39-55,70.98-93.64,93.55a15.048,15.048,0,0,0-4.6,3.91l-1.32-1.01c-15.85-14.8-31.51-29.74-45.99-45.9-4.7-4.9-8.91-10.21-13.75-14.97-.32-.34-.65-.68-.98-1.03a2.645,2.645,0,0,0,1.18-2.62c11.05-5.26,21.84-10.86,31.83-17.98a177.09,177.09,0,0,0,45.63-46.32C665.57,558.7,670.23,549.23,674.93,539.65Z"
        transform="translate(160 19)"
        fill="#a5c0c5"
      />
      <path
        id="Path_528"
        data-name="Path 528"
        d="M768.97,540.3a4.5,4.5,0,0,1,4.1-.08C771.81,541.12,770.03,541.92,768.97,540.3Z"
        transform="translate(160 19)"
        fill="#7b807b"
      />
      <path
        id="Path_534"
        data-name="Path 534"
        d="M611.9,541.2c1.81-.23,2.01,2.41,2.52,3.59-1.94.01-3.88,0-5.83-.03C609.49,543.07,609.36,540.78,611.9,541.2Z"
        transform="translate(160 19)"
        fill="#91b4ba"
      />
      <path
        id="Path_535"
        data-name="Path 535"
        d="M640.08,541.23c2.24-.3,2.17,1.94,3.12,3.4q-2.85.135-5.7.21C637.99,543.57,638.14,540.93,640.08,541.23Z"
        transform="translate(160 19)"
        fill="#91b4ba"
      />
      <path
        id="Path_536"
        data-name="Path 536"
        d="M766.31,540.89l.65.03.24.82a4.055,4.055,0,0,1-1.07,2.4C764.68,544.09,765.73,541.46,766.31,540.89Z"
        transform="translate(160 19)"
        fill="#696767"
      />
      <path
        id="Path_537"
        data-name="Path 537"
        d="M400.43,542.4c1.45-.18,2.85.05,3.1,1.73.18,1.94.62,5-.87,6.52-1.79.21-3.6.8-3.82-1.63C398.59,547.26,397.99,542.78,400.43,542.4Z"
        transform="translate(160 19)"
        fill="#8651a0"
      />
      <path
        id="Path_538"
        data-name="Path 538"
        d="M455.02,543.01c1.24-.86,3.14-.08,4.58-.01a53.816,53.816,0,0,1-.3,6.6c-.71,1.27-3.02,1.12-4.25,1.53C454.77,548.49,454.11,545.56,455.02,543.01Z"
        transform="translate(160 19)"
        fill="#8651a0"
      />
      <path
        id="Path_539"
        data-name="Path 539"
        d="M852.9,543.81c.36.82.62,3.85-.82,3.65C851.2,546.39,851.83,544.53,852.9,543.81Z"
        transform="translate(160 19)"
        fill="#656262"
      />
      <path
        id="Path_540"
        data-name="Path 540"
        d="M177.18,545.17c1.32.66,3.09,3.95,1.09,4.6C176.52,549.15,176.84,546.6,177.18,545.17Z"
        transform="translate(160 19)"
        fill="#8b8989"
      />
      <path
        id="Path_541"
        data-name="Path 541"
        d="M265.26,545.84c1.99-.74,2.13,2.81,1.73,4.02C265.82,549.08,264.39,547.26,265.26,545.84Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        id="Path_544"
        data-name="Path 544"
        d="M765.52,546.39c.8,1.01-.2,3.72-1.42,4.04C762.45,550.22,764,545.44,765.52,546.39Z"
        transform="translate(160 19)"
        fill="#929090"
      />
      <path
        id="Path_546"
        data-name="Path 546"
        d="M851.85,550.12a2.833,2.833,0,0,1-1.76,3.92C849.77,553.01,850.17,549.38,851.85,550.12Z"
        transform="translate(160 19)"
        fill="#7b7979"
      />
      <path
        id="Path_547"
        data-name="Path 547"
        d="M178.53,552.02c1.96-.69,2.31,3.29.84,4.1C178.74,555.07,177.79,553.18,178.53,552.02Z"
        transform="translate(160 19)"
        fill="#787677"
      />
      <path
        id="Path_548"
        data-name="Path 548"
        d="M267.48,552.07c1.43-.55,2.55,2.88,1.93,3.85C268.08,556.43,266.86,552.98,267.48,552.07Z"
        transform="translate(160 19)"
        fill="#7e7c7c"
      />
      <path
        id="Path_549"
        data-name="Path 549"
        d="M762.48,553.1c1.63-.19.22,4.34-1.14,3.62A3.694,3.694,0,0,1,762.48,553.1Z"
        transform="translate(160 19)"
        fill="#726f70"
      />
      <path
        id="Path_550"
        data-name="Path 550"
        d="M849.94,556.78c.3.87.12,3.98-1.35,3.35A2.66,2.66,0,0,1,849.94,556.78Z"
        transform="translate(160 19)"
        fill="#666363"
      />
      <path
        id="Path_551"
        data-name="Path 551"
        d="M180.18,558.34c1.84-.56,2.54,3.09,1.21,4.06C180.61,561.44,179.39,559.55,180.18,558.34Z"
        transform="translate(160 19)"
        fill="#817e7f"
      />
      <path
        id="Path_552"
        data-name="Path 552"
        d="M269.92,558.09c1.19.69,2.68,2.6,1.83,3.98C269.79,562.74,269.52,559.33,269.92,558.09Z"
        transform="translate(160 19)"
        fill="#7f7c7d"
      />
      <path
        id="Path_553"
        data-name="Path 553"
        d="M760.92,558.96c.34,1.76-.03,3.42-1.85,4.12A3.783,3.783,0,0,1,760.92,558.96Z"
        transform="translate(160 19)"
        fill="#625f60"
      />
      <path
        id="Path_554"
        data-name="Path 554"
        d="M848.38,562.92c.73.99-.12,3.77-1.34,4.1C845.41,566.82,846.85,561.9,848.38,562.92Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_555"
        data-name="Path 555"
        d="M272.16,564.43c1.26-.3,2.35,2.61,1.72,3.53C272.61,568.36,271.54,565.34,272.16,564.43Z"
        transform="translate(160 19)"
        fill="#767374"
      />
      <path
        id="Path_556"
        data-name="Path 556"
        d="M182.13,565.05c1.8-.71,2.12,2.87,1.78,3.98C182.48,568.4,181.29,566.55,182.13,565.05Z"
        transform="translate(160 19)"
        fill="#787676"
      />
      <path
        id="Path_557"
        data-name="Path 557"
        d="M759.24,564.36c-.53,2.53-.98,4.15-3.12,5.72C756.6,567.53,756.87,565.84,759.24,564.36Z"
        transform="translate(160 19)"
        fill="#a2a0a0"
      />
      <path
        id="Path_558"
        data-name="Path 558"
        d="M846.56,569.13c.73,1.16-.31,3.05-.97,4.05C844.05,572.35,844.66,568.55,846.56,569.13Z"
        transform="translate(160 19)"
        fill="#7f7d7e"
      />
      <path
        id="Path_559"
        data-name="Path 559"
        d="M184.1,570.99c1.31.71,2.54,2.53,1.73,4C183.93,575.77,183.72,572.14,184.1,570.99Z"
        transform="translate(160 19)"
        fill="#7e7b7c"
      />
      <path
        id="Path_560"
        data-name="Path 560"
        d="M274.86,570.8c1.62.16,2.98,2.54,1.99,3.93C275.04,575.48,274.39,571.98,274.86,570.8Z"
        transform="translate(160 19)"
        fill="#939192"
      />
      <path
        id="Path_561"
        data-name="Path 561"
        d="M755.92,571.16c.3,1.14-.15,4.52-1.8,4.24C753.14,573.8,754.5,571.95,755.92,571.16Z"
        transform="translate(160 19)"
        fill="#7b7979"
      />
      <path
        id="Path_562"
        data-name="Path 562"
        d="M843.45,575.97c2.75-1.72.85,4.67-.84,3.68C841.96,578.56,842.65,576.81,843.45,575.97Z"
        transform="translate(160 19)"
        fill="#929091"
      />
      <path
        id="Path_563"
        data-name="Path 563"
        d="M186.18,577.46c1.47-.79,2.96,4.28,1.21,4.26C186.22,581.04,185.38,578.64,186.18,577.46Z"
        transform="translate(160 19)"
        fill="#888687"
      />
      <path
        id="Path_564"
        data-name="Path 564"
        d="M277.23,576.98c1.41-.75,3.19,2.56,2.47,3.66C278.3,581.32,276.51,578.07,277.23,576.98Z"
        transform="translate(160 19)"
        fill="#8d8b8c"
      />
      <path
        id="Path_565"
        data-name="Path 565"
        d="M753.15,577.91a2.823,2.823,0,0,1-2.06,3.33A3.093,3.093,0,0,1,753.15,577.91Z"
        transform="translate(160 19)"
        fill="#6b6868"
      />
      <path
        id="Path_566"
        data-name="Path 566"
        d="M842.06,582.13c.82.96-.2,4.31-1.71,3.71C839.67,584.97,840.74,581.65,842.06,582.13Z"
        transform="translate(160 19)"
        fill="#817f7f"
      />
      <path
        id="Path_567"
        data-name="Path 567"
        d="M280.25,582.99c1.35-.53,2.92,2.47,2.42,3.56C281.32,587.09,279.67,584.06,280.25,582.99Z"
        transform="translate(160 19)"
        fill="#838181"
      />
      <path
        id="Path_568"
        data-name="Path 568"
        d="M750.98,582.78c-.65,2.3-1.24,3.79-3.33,5.19C748.31,585.53,748.73,584.07,750.98,582.78Z"
        transform="translate(160 19)"
        fill="#989697"
      />
      <path
        id="Path_569"
        data-name="Path 569"
        d="M188.22,583.98c1.96-.65,2.24,2.72,1.84,3.98C188.85,587.28,187.39,585.36,188.22,583.98Z"
        transform="translate(160 19)"
        fill="#7c7a7a"
      />
      <path
        id="Path_570"
        data-name="Path 570"
        d="M839.9,588.01c.22,1.45.26,2.93-.86,4.04-.58.43-.95.37-1.09-.19A3.73,3.73,0,0,1,839.9,588.01Z"
        transform="translate(160 19)"
        fill="#7d7b7b"
      />
      <path
        id="Path_571"
        data-name="Path 571"
        d="M283.22,588.93c1.41-.76,3.23,2.6,2.39,3.67C284.21,593.23,282.5,589.99,283.22,588.93Z"
        transform="translate(160 19)"
        fill="#918f8f"
      />
      <path
        id="Path_572"
        data-name="Path 572"
        d="M747.99,588.74c-.65,2.32-1.27,3.73-3.32,5.18C745.32,591.47,745.73,590.03,747.99,588.74Z"
        transform="translate(160 19)"
        fill="#979595"
      />
      <path
        id="Path_573"
        data-name="Path 573"
        d="M190.07,589.64c2.33.74,2.27,3.01,3.12,4.96C190.71,593.66,190.63,591.9,190.07,589.64Z"
        transform="translate(160 19)"
        fill="#939192"
      />
      <path
        id="Path_576"
        data-name="Path 576"
        d="M838.22,593.39a10.764,10.764,0,0,1-2.43,5.76C835.62,596.31,836.11,595.29,838.22,593.39Z"
        transform="translate(160 19)"
        fill="#989697"
      />
      <path
        id="Path_577"
        data-name="Path 577"
        d="M286.28,594.78c1.43-.89,3.3,2.12,2.57,3.32C287.46,598.93,285.59,595.95,286.28,594.78Z"
        transform="translate(160 19)"
        fill="#949192"
      />
      <path
        id="Path_578"
        data-name="Path 578"
        d="M744.09,595.18c.15,1.74-.32,3.24-2,4.01l-.37-.46A4.639,4.639,0,0,1,744.09,595.18Z"
        transform="translate(160 19)"
        fill="#6a6869"
      />
      <path
        id="Path_579"
        data-name="Path 579"
        d="M193.12,596.35c1.22-.3,2.33,2.71,1.74,3.6C193.55,600.34,192.53,597.28,193.12,596.35Z"
        transform="translate(160 19)"
        fill="#696667"
      />
      <path
        id="Path_585"
        data-name="Path 585"
        d="M520.31,598.16a15.824,15.824,0,0,1,3.37-.01c.39,1.06.76,2.12,1.1,3.2-1.76-.03-3.52-.09-5.27-.18C519.74,600.16,520.01,599.15,520.31,598.16Z"
        transform="translate(160 19)"
        fill="#3c59a4"
      />
      <path
        @click="changeColor('Path_586')"
        id="Path_586"
        data-name="Path 586"
        d="M740.14,597.65l1.58,1.08.37.46c.26.59.51,1.19.74,1.79.52,1.83,1.25,3.03,3.34,2.99.27.65.55,1.29.83,1.93a6.32,6.32,0,0,0,2.84,3.35c.71.56,1.41,1.12,2.11,1.68,0,1.47.63,4.14,2.45,2.33.45.98.88,1.96,1.29,2.95.41,1.28,1.19,3.59,2.78,1.94.44,1,.86,2.01,1.27,3.02a5.594,5.594,0,0,0,3.11,3.9c1.02.83,2,1.71,2.96,2.61-1.61.97-.18,2.63,1.3,2.17.18.75.37,1.51.57,2.26.19,1.17,1.08,1.78,2.68,1.81.5,1.08.98,2.17,1.41,3.27.35,1.29,1.04,3.73,2.68,2.01.5,1.24.97,2.48,1.39,3.75.22,1.29.9,3.87,2.57,2.21a22.78,22.78,0,0,0,2.26,3.56c-1.78,1.2.03,2.64,1.18,3.43.78.75,1.53,1.52,2.29,2.3a2.51,2.51,0,0,0,1.66,3.01c.67.73,1.34,1.46,2.01,2.18-1.57.97-.3,2.8,1.26,2.27a15.558,15.558,0,0,0,2.52,3.86c-1.9,1.39.01,2.78,1.51,3.27.24.72.48,1.44.71,2.17l.47,1.42c-14.71,19.17-30.22,37.24-48.46,53.18-9.53,8.78-19.77,16.57-30.1,24.37l-1.7-.21c-.96-.6-1.92-1.19-2.88-1.79a3.543,3.543,0,0,0-3.07-2.54c-.66-.18-1.32-.37-1.98-.56-.19-1.73-1.58-2.02-3.03-2.31-1.04-.43-2.05-.92-3.07-1.4-.44-1.65-1.02-2.55-2.81-2.79-.73-.17-1.47-.35-2.2-.53.42-1.44-1.32-3.01-2.24-1.35a14.826,14.826,0,0,0-3.74-2.69c.53-1.51-1.32-3.02-2.26-1.35a34.107,34.107,0,0,0-3.43-2.29c1.76-1.65-.81-2.39-2.13-2.63-1.1-.42-2.18-.88-3.25-1.37-.02-1.58-.62-2.47-1.82-2.66-.75-.21-1.5-.41-2.25-.61.43-1.47-1.25-2.93-2.21-1.31-.82-.96-1.63-1.94-2.39-2.95-.93-1.53-1.62-3.32-3.66-1.9-.45-.7-.9-1.4-1.34-2.1-.9-1.48-1.74-3.35-3.69-1.92-.44-.69-.88-1.4-1.31-2.1-.9-1.54-1.74-3.27-3.71-1.87-.43-.7-.86-1.4-1.28-2.11-.85-1.56-1.66-3.52-3.67-1.99-.8-.89-1.63-1.76-2.46-2.63a3.019,3.019,0,0,0-2.12-2.54c-1.15-.52-2.29-1.07-3.45-1.58l.33-.75a15.048,15.048,0,0,1,4.6-3.91c38.64-22.57,70.76-55.16,93.64-93.55C740.81,599.14,740.48,598.4,740.14,597.65Z"
        transform="translate(160 19)"
        fill="#bacdd2"
      />
      <path
        @click="changeColor('Path_587')"
        id="Path_587"
        data-name="Path 587"
        d="M289.33,598.76c3.04,5.2,6.32,10.24,9.7,15.21,12.48,18.83,27.69,35.46,44.48,50.5,13.48,11.99,28.33,22.1,43.79,31.32-.87,1.13-1.75,2.26-2.63,3.39-.46.57-.92,1.14-1.39,1.72a2.274,2.274,0,0,0-2.38,2.34c-4.34,4.09-9.33,7.39-13.68,11.44-1.02-1.84-2.3-.06-2.44,1.3-.87.89-1.72,1.79-2.58,2.68-.94-1.63-2.71-.15-2.27,1.33-1.09.75-2.13,1.58-3.15,2.42l-.38.3a41.694,41.694,0,0,0-5.17,4c-.98-1.85-2.34-.08-2.44,1.29a18.624,18.624,0,0,1-6.4,4.68c-.87.59-1.73,1.18-2.59,1.77l-.24.15-.2.15c-1.36.98-2.77,1.92-4.1,2.94-.97-1.65-2.82-.27-2.25,1.31a14.656,14.656,0,0,0-3.83,2.65c-.87-1.65-2.73-.21-2.31,1.28-.9.56-1.78,1.15-2.61,1.79-1.03-1.61-2.84-.2-2.19,1.37a35.634,35.634,0,0,0-3.86,2.05,2.339,2.339,0,0,0-3.32,1.88l-.58-.02a344.607,344.607,0,0,1-72.56-69.7,30.518,30.518,0,0,0-6.44-6.93l-.13-.38c1.49.27,2.48-1.48.92-2.22.78-.76,1.56-1.52,2.36-2.25,1.29-.95,2.07-2.06,1.41-3.68.82-1.02,1.56-2.1,2.29-3.19l.23-.31.01-.12.84-1.47.23-.3.18-.3c.69-1.07,1.38-2.13,2.07-3.19a1.634,1.634,0,0,0,1.1-2.21c.8-.81,1.6-1.61,2.42-2.4a4.984,4.984,0,0,0,2.01-3.35c.47-1,.98-1.97,1.49-2.95,1.79-.52,2.3-.94,2.77-2.78.38-1.08.79-2.16,1.2-3.23a2.406,2.406,0,0,0,2.51-2.08,27.725,27.725,0,0,1,1.81-3.51l.17-.25.01-.04c1.08-1.76,2.34-3.42,3.52-5.12a2.5,2.5,0,0,0,2.57-1.96c.4-1.08.84-2.15,1.3-3.21a2.347,2.347,0,0,0,2.61-1.89c1.06-3.42,3.51-5.43,5.66-8.12,1.5.59,3.03-1.08,1.43-2.13.93-.81,1.89-1.6,2.88-2.35a2.918,2.918,0,0,0,1.85-2.72q1.365-1.815,2.68-3.66a2.636,2.636,0,0,0,2.68-1.84C288.69,599.69,289,599.22,289.33,598.76Z"
        transform="translate(160 19)"
        fill="#ac8cc0"
      />
      <path
        id="Path_588"
        data-name="Path 588"
        d="M288.39,600.16a2.636,2.636,0,0,1-2.68,1.84C286.27,600.36,287.16,599.75,288.39,600.16Z"
        transform="translate(160 19)"
        fill="#827389"
      />
      <path
        id="Path_589"
        data-name="Path 589"
        d="M742.83,600.98c1.9.09,2.76,1.34,3.34,2.99C744.08,604.01,743.35,602.81,742.83,600.98Z"
        transform="translate(160 19)"
        fill="#8a9497"
      />
      <path
        id="Path_590"
        data-name="Path 590"
        d="M834.39,600.61c1.61,1.53.22,3.28-1.34,4.1A5.105,5.105,0,0,1,834.39,600.61Z"
        transform="translate(160 19)"
        fill="#737071"
      />
      <path
        id="Path_593"
        data-name="Path 593"
        d="M195.83,602.88c1.47.35,2.01,2.05,1.89,3.4A2.773,2.773,0,0,1,195.83,602.88Z"
        transform="translate(160 19)"
        fill="#696666"
      />
      <path
        id="Path_600"
        data-name="Path 600"
        d="M283.03,605.66a2.918,2.918,0,0,1-1.85,2.72C280.74,607.1,281.36,606.2,283.03,605.66Z"
        transform="translate(160 19)"
        fill="#7a6883"
      />
      <path
        id="Path_601"
        data-name="Path 601"
        d="M747,605.9c2.19-.1,3.28,1.21,2.84,3.35A6.32,6.32,0,0,1,747,605.9Z"
        transform="translate(160 19)"
        fill="#989ea0"
      />
      <path
        id="Path_602"
        data-name="Path 602"
        d="M832.6,606.88c.72,1.01-.74,4.42-2.17,3.76C829.6,609.61,831.14,606.07,832.6,606.88Z"
        transform="translate(160 19)"
        fill="#8f8d8e"
      />
      <path
        id="Path_610"
        data-name="Path 610"
        d="M198.1,608.68c1.63.27,2.97,2.42,1.97,3.87C198.53,612.94,197.89,609.76,198.1,608.68Z"
        transform="translate(160 19)"
        fill="#848283"
      />
      <path
        id="Path_612"
        data-name="Path 612"
        d="M278.3,610.73c1.6,1.05.07,2.72-1.43,2.13A4.3,4.3,0,0,1,278.3,610.73Z"
        transform="translate(160 19)"
        fill="#7d6a87"
      />
      <path
        id="Path_613"
        data-name="Path 613"
        d="M751.95,610.93a4.9,4.9,0,0,1,2.45,2.33C752.58,615.07,751.95,612.4,751.95,610.93Z"
        transform="translate(160 19)"
        fill="#929799"
      />
      <path
        id="Path_625"
        data-name="Path 625"
        d="M829.96,612.88a3.137,3.137,0,0,1-2.02,3.37A2.845,2.845,0,0,1,829.96,612.88Z"
        transform="translate(160 19)"
        fill="#5f5c5d"
      />
      <path
        id="Path_627"
        data-name="Path 627"
        d="M698.37,614.2a25.065,25.065,0,0,1,3.36-.24c.22,1.07.42,2.14.62,3.21-1.87-.1-3.75-.21-5.62-.36C697.24,615.92,697.79,615.05,698.37,614.2Z"
        transform="translate(160 19)"
        fill="#9db6bb"
      />
      <path
        id="Path_629"
        data-name="Path 629"
        d="M201.02,614.83a2.96,2.96,0,0,1,1.98,3.42A3.086,3.086,0,0,1,201.02,614.83Z"
        transform="translate(160 19)"
        fill="#5b5859"
      />
      <path
        id="Path_635"
        data-name="Path 635"
        d="M755.69,616.21a2.887,2.887,0,0,1,2.78,1.94C756.88,619.8,756.1,617.49,755.69,616.21Z"
        transform="translate(160 19)"
        fill="#93999b"
      />
      <path
        id="Path_637"
        data-name="Path 637"
        d="M357.97,617.84c.07,2.46.03,4.93.01,7.38-1.64-.01-3.28-.04-4.92-.07a42.456,42.456,0,0,1,.21-6.86C354.68,616.25,356.08,617.36,357.97,617.84Z"
        transform="translate(160 19)"
        fill="#986eaf"
      />
      <path
        id="Path_638"
        data-name="Path 638"
        d="M826.94,618.87c.08,1.04-.54,3.96-1.98,3.67C824.1,621.05,825.5,619.32,826.94,618.87Z"
        transform="translate(160 19)"
        fill="#6e6c6c"
      />
      <path
        id="Path_639"
        data-name="Path 639"
        d="M204.51,620.57c1,.64,1.89,2.37,1.38,3.52C204.63,624.82,202.94,620.43,204.51,620.57Z"
        transform="translate(160 19)"
        fill="#726f70"
      />
      <path
        id="Path_640"
        data-name="Path 640"
        d="M271.21,620.98a2.347,2.347,0,0,1-2.61,1.89Q269.4,620.545,271.21,620.98Z"
        transform="translate(160 19)"
        fill="#908892"
      />
      <path
        id="Path_641"
        data-name="Path 641"
        d="M759.74,621.17c2.4.2,3.29,1.57,3.11,3.9A5.594,5.594,0,0,1,759.74,621.17Z"
        transform="translate(160 19)"
        fill="#9da2a4"
      />
      <path
        id="Path_642"
        data-name="Path 642"
        d="M823.97,624.82c.21,1.36-.28,3.84-1.92,4.07C820.26,627.93,822.35,624.88,823.97,624.82Z"
        transform="translate(160 19)"
        fill="#8f8d8d"
      />
      <path
        id="Path_643"
        data-name="Path 643"
        d="M207.5,626.57c.98.64,1.88,2.38,1.38,3.52C207.6,630.8,205.91,626.44,207.5,626.57Z"
        transform="translate(160 19)"
        fill="#716e6f"
      />
      <path
        id="Path_644"
        data-name="Path 644"
        d="M267.3,626.08a2.5,2.5,0,0,1-2.57,1.96C265.22,626.4,266.08,625.74,267.3,626.08Z"
        transform="translate(160 19)"
        fill="#7d6c85"
      />
      <path
        id="Path_645"
        data-name="Path 645"
        d="M765.81,627.68l1.21,1.42.09.75C765.63,630.31,764.2,628.65,765.81,627.68Z"
        transform="translate(160 19)"
        fill="#8a9396"
      />
      <path
        id="Path_646"
        data-name="Path 646"
        d="M820.34,630.8c1.94.11-.43,4.74-1.86,3.76C817.73,633.36,819.16,631.21,820.34,630.8Z"
        transform="translate(160 19)"
        fill="#918f8f"
      />
      <path
        id="Path_647"
        data-name="Path 647"
        d="M210.12,632.5c1.12-.38,2.86,2.62,2.27,3.53C210.95,636.82,209.36,633.58,210.12,632.5Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        @click="changeColor('Path_648')"
        id="Path_648"
        data-name="Path 648"
        d="M580.28,632a1.874,1.874,0,0,0,.58,2.44c.33.35.66.69.98,1.03a504.917,504.917,0,0,1,2.33,84.46c-15.82,4.67-32.43,7.13-48.85,8.52-6.85.82-13.44.37-20.32.46-23.07.57-46.44-3.12-68.73-8.93-.26,1.28-.53,2.56-.77,3.84-.05-.71-.11-1.41-.18-2.12-.85-.74-1.71-1.48-2.57-2.22l2.27-.99a75.224,75.224,0,0,0-.24-8.49c-.75-17.98-.83-36.03.02-54,.3-6.99.45-14.04,1.3-20.99l.42-1.69.01-.04c4.43,1.09,8.59,2.86,12.92,4.23a182.278,182.278,0,0,0,103.1,1A113.081,113.081,0,0,0,580.28,632Z"
        transform="translate(160 19)"
        fill="#6371b6"
      />
      <path
        id="Path_649"
        data-name="Path 649"
        d="M580.28,632l1.76-.18a2.645,2.645,0,0,1-1.18,2.62A1.874,1.874,0,0,1,580.28,632Z"
        transform="translate(160 19)"
        fill="#c5d0df"
      />
      <path
        id="Path_650"
        data-name="Path 650"
        d="M767.68,632.11c1.2-.31,2.1.29,2.68,1.81C768.76,633.89,767.87,633.28,767.68,632.11Z"
        transform="translate(160 19)"
        fill="#858c8f"
      />
      <path
        id="Path_651"
        data-name="Path 651"
        d="M261.21,633.16l-.01.04-.17.25Z"
        transform="translate(160 19)"
        fill="#6c5b74"
      />
      <path
        id="Path_652"
        data-name="Path 652"
        d="M446.35,633.23l.18.05-.01.04Z"
        transform="translate(160 19)"
        fill="#f3f0f7"
      />
      <path
        id="Path_653"
        data-name="Path 653"
        d="M817.37,636.38c1.7.19-.91,4.4-2.11,3.62C814.82,638.84,816.18,636.66,817.37,636.38Z"
        transform="translate(160 19)"
        fill="#7f7c7d"
      />
      <path
        id="Path_654"
        data-name="Path 654"
        d="M259.22,636.96a2.406,2.406,0,0,1-2.51,2.08C257.18,637.44,258.02,636.75,259.22,636.96Z"
        transform="translate(160 19)"
        fill="#7f6f87"
      />
      <path
        id="Path_655"
        data-name="Path 655"
        d="M771.77,637.19a2.878,2.878,0,0,1,2.68,2.01C772.81,640.92,772.12,638.48,771.77,637.19Z"
        transform="translate(160 19)"
        fill="#959a9c"
      />
      <path
        id="Path_656"
        data-name="Path 656"
        d="M213.2,638.31c1.71-.53,2.58,2.49,2.67,3.75C214.36,641.68,212.51,639.95,213.2,638.31Z"
        transform="translate(160 19)"
        fill="#7d7b7b"
      />
      <path
        id="Path_657"
        data-name="Path 657"
        d="M255.51,642.27c-.47,1.84-.98,2.26-2.77,2.78C253.14,643.57,253.65,642.05,255.51,642.27Z"
        transform="translate(160 19)"
        fill="#897892"
      />
      <path
        id="Path_658"
        data-name="Path 658"
        d="M814.43,642.16c.64,1.61-.87,3.44-2.41,3.88C811.84,644.79,812.78,641.74,814.43,642.16Z"
        transform="translate(160 19)"
        fill="#7a7778"
      />
      <path
        id="Path_659"
        data-name="Path 659"
        d="M775.84,642.95a3.164,3.164,0,0,1,2.57,2.21C776.74,646.82,776.06,644.24,775.84,642.95Z"
        transform="translate(160 19)"
        fill="#8b9496"
      />
      <path
        id="Path_660"
        data-name="Path 660"
        d="M216.47,644.11c1.85-.87,2.83,2.57,2.51,3.92C217.41,647.58,215.78,645.77,216.47,644.11Z"
        transform="translate(160 19)"
        fill="#888686"
      />
      <path
        id="Path_661"
        data-name="Path 661"
        d="M251.25,648a4.984,4.984,0,0,1-2.01,3.35A2.31,2.31,0,0,1,251.25,648Z"
        transform="translate(160 19)"
        fill="#887e8d"
      />
      <path
        id="Path_662"
        data-name="Path 662"
        d="M810.69,647.41l.83.97a8.056,8.056,0,0,1-3.75,4.06A16.775,16.775,0,0,1,810.69,647.41Z"
        transform="translate(160 19)"
        fill="#9f9d9e"
      />
      <path
        id="Path_663"
        data-name="Path 663"
        d="M780.67,648.72a3.493,3.493,0,0,1,1.18,3.43C780.7,651.36,778.89,649.92,780.67,648.72Z"
        transform="translate(160 19)"
        fill="#8c9396"
      />
      <path
        id="Path_664"
        data-name="Path 664"
        d="M220.1,650.05c1.23-.71,3.54,2.48,2.59,3.5C221.26,654.24,219.41,651.22,220.1,650.05Z"
        transform="translate(160 19)"
        fill="#8c8a8a"
      />
      <path
        id="Path_665"
        data-name="Path 665"
        d="M246.82,653.75a1.634,1.634,0,0,1-1.1,2.21A1.792,1.792,0,0,1,246.82,653.75Z"
        transform="translate(160 19)"
        fill="#837888"
      />
      <path
        id="Path_666"
        data-name="Path 666"
        d="M807.2,653.92a2.9,2.9,0,0,1-2.2,3.21A2.9,2.9,0,0,1,807.2,653.92Z"
        transform="translate(160 19)"
        fill="#5e5b5c"
      />
      <path
        id="Path_667"
        data-name="Path 667"
        d="M784.14,654.45a2.817,2.817,0,0,1,1.66,3.01A2.51,2.51,0,0,1,784.14,654.45Z"
        transform="translate(160 19)"
        fill="#8c9294"
      />
      <path
        id="Path_668"
        data-name="Path 668"
        d="M223.86,655.7a2.743,2.743,0,0,1,2.27,3.35C224.54,658.97,223.57,657.15,223.86,655.7Z"
        transform="translate(160 19)"
        fill="#797777"
      />
      <path
        id="Path_669"
        data-name="Path 669"
        d="M243.43,659.42l.22-.27-.18.3-.23.3Z"
        transform="translate(160 19)"
        fill="#6b636c"
      />
      <path
        id="Path_670"
        data-name="Path 670"
        d="M803.95,659.06c-.05,2.1-.8,3.37-2.88,3.96C801.19,660.96,801.89,659.64,803.95,659.06Z"
        transform="translate(160 19)"
        fill="#7a7879"
      />
      <path
        id="Path_671"
        data-name="Path 671"
        d="M787.81,659.64a4.563,4.563,0,0,1,1.26,2.27C787.51,662.44,786.24,660.61,787.81,659.64Z"
        transform="translate(160 19)"
        fill="#8a9497"
      />
      <path
        id="Path_672"
        data-name="Path 672"
        d="M227.16,661.09c1.88-.64,2.99,2.31,2.83,3.8C228.43,664.55,226.48,662.78,227.16,661.09Z"
        transform="translate(160 19)"
        fill="#8d8b8c"
      />
      <path
        id="Path_673"
        data-name="Path 673"
        d="M242.4,661.22l-.01.12-.23.31Z"
        transform="translate(160 19)"
        fill="#67586e"
      />
      <path
        id="Path_674"
        data-name="Path 674"
        d="M239.87,664.84c.66,1.62-.12,2.73-1.41,3.68C237.79,666.92,238.67,665.81,239.87,664.84Z"
        transform="translate(160 19)"
        fill="#8a7c92"
      />
      <path
        id="Path_675"
        data-name="Path 675"
        d="M800.02,664.93c.03,1.19-.99,3.94-2.54,3.19C796.92,666.64,798.76,665.28,800.02,664.93Z"
        transform="translate(160 19)"
        fill="#6d6a6b"
      />
      <path
        id="Path_676"
        data-name="Path 676"
        d="M231.1,666.47c1.56-.51,2.62,2.42,2.81,3.58C232.34,669.82,230.26,668.21,231.1,666.47Z"
        transform="translate(160 19)"
        fill="#7c797a"
      />
      <path
        id="Path_677"
        data-name="Path 677"
        d="M791.59,665.77a6,6,0,0,1,1.51,3.27C791.6,668.55,789.69,667.16,791.59,665.77Z"
        transform="translate(160 19)"
        fill="#93989a"
      />
      <path
        id="Path_678"
        data-name="Path 678"
        d="M796.14,670.28a2.783,2.783,0,0,1-1.29,2.65l-.57-.3-.47-1.42A4.605,4.605,0,0,1,796.14,670.28Z"
        transform="translate(160 19)"
        fill="#646566"
      />
      <path
        id="Path_679"
        data-name="Path 679"
        d="M236.1,670.77c1.56.74.57,2.49-.92,2.22l-.25-.74Z"
        transform="translate(160 19)"
        fill="#7f7881"
      />
      <path
        @click="changeColor('Path_680')"
        id="Path_680"
        data-name="Path 680"
        d="M794.28,672.63l.57.3c21.18,36.19,36.1,76.64,41.82,118.25-13.26.25-26.92-2.89-39.74-6.03-28.27-7.46-55.47-19.71-80.7-34.41l-.51-.56c10.33-7.8,20.57-15.59,30.1-24.37C764.06,709.87,779.57,691.8,794.28,672.63Z"
        transform="translate(160 19)"
        fill="#cfdde0"
      />
      <path
        @click="changeColor('Path_681')"
        id="Path_681"
        data-name="Path 681"
        d="M235.31,673.37a30.518,30.518,0,0,1,6.44,6.93A344.607,344.607,0,0,0,314.31,750c-36.06,20.68-76.92,36.31-118.3,41.44.89-17.91,4.75-35.29,10.12-52.35C213.3,716.04,223.65,694.44,235.31,673.37Z"
        transform="translate(160 19)"
        fill="#c3acd2"
      />
      <path
        id="Path_702"
        data-name="Path 702"
        d="M529.93,682.09c1.75-.37,1.94,2.5,2.42,3.61-1.89,0-3.79-.02-5.68-.06C527.58,683.95,527.38,681.64,529.93,682.09Z"
        transform="translate(160 19)"
        fill="#6371b6"
      />
      <path
        id="Path_703"
        data-name="Path 703"
        d="M269.91,683.04a49.922,49.922,0,0,1,.23,8.2c-1.61-.1-3.21-.21-4.82-.34a41.977,41.977,0,0,1,.18-6.54C266.09,682.46,268.39,683.3,269.91,683.04Z"
        transform="translate(160 19)"
        fill="#ac8cc0"
      />
      <path
        id="Path_705"
        data-name="Path 705"
        d="M296.13,683.03a13.05,13.05,0,0,1,2.58.11c.54,1.03.98,2.11,1.43,3.18-1.79.04-3.57.08-5.36.11C295.17,685.36,295.2,683.75,296.13,683.03Z"
        transform="translate(160 19)"
        fill="#ac8cc0"
      />
      <path
        id="Path_718"
        data-name="Path 718"
        d="M498.56,686.47c-.65,1.53-.14,3.53-2.08,3.95-1.55.52-3.55-.06-3.09-2.02C494.13,686.64,497,686.99,498.56,686.47Z"
        transform="translate(160 19)"
        fill="#6371b6"
      />
      <path
        id="Path_720"
        data-name="Path 720"
        d="M388.62,695.87c.87.46,3.59,1.58,3.01,2.87a5.122,5.122,0,0,1-3.07-.84C388.06,696.96,388.08,696.28,388.62,695.87Z"
        transform="translate(160 19)"
        fill="#7d7a7c"
      />
      <path
        id="Path_721"
        data-name="Path 721"
        d="M641.58,696.34l1.32,1.01-.33.75a2.553,2.553,0,0,1-2.16-.48Z"
        transform="translate(160 19)"
        fill="#767374"
      />
      <path
        id="Path_722"
        data-name="Path 722"
        d="M639.06,698.91a12.335,12.335,0,0,1-5.38,3.03C635.31,699.47,635.97,698.67,639.06,698.91Z"
        transform="translate(160 19)"
        fill="#a2a1a1"
      />
      <path
        id="Path_723"
        data-name="Path 723"
        d="M393.89,699.45c1.06-.65,4.29.88,3.71,2.3C396.57,702.67,393.08,700.87,393.89,699.45Z"
        transform="translate(160 19)"
        fill="#908e8e"
      />
      <path
        id="Path_724"
        data-name="Path 724"
        d="M646.02,699.68a3.019,3.019,0,0,1,2.12,2.54C646.42,701.87,645.71,701.03,646.02,699.68Z"
        transform="translate(160 19)"
        fill="#81898c"
      />
      <path
        id="Path_725"
        data-name="Path 725"
        d="M383.28,700.9a2.728,2.728,0,0,1-2.38,2.34A2.274,2.274,0,0,1,383.28,700.9Z"
        transform="translate(160 19)"
        fill="#938e95"
      />
      <path
        id="Path_726"
        data-name="Path 726"
        d="M399.61,701.65a9.917,9.917,0,0,1,4.78,3.47C402.43,704.38,399.22,704.64,399.61,701.65Z"
        transform="translate(160 19)"
        fill="#9b9999"
      />
      <path
        id="Path_727"
        data-name="Path 727"
        d="M633.1,702.32a36.138,36.138,0,0,1-5.63,2.91C628.84,702.04,629.91,702.02,633.1,702.32Z"
        transform="translate(160 19)"
        fill="#a4a2a3"
      />
      <path
        id="Path_728"
        data-name="Path 728"
        d="M654.27,706.84c-1.65.12-2.7-.77-3.67-1.99C652.61,703.32,653.42,705.28,654.27,706.84Z"
        transform="translate(160 19)"
        fill="#98a0a3"
      />
      <path
        id="Path_729"
        data-name="Path 729"
        d="M405.65,704.6a8.414,8.414,0,0,1,4.65,3.45C408.79,707.41,404.66,707.06,405.65,704.6Z"
        transform="translate(160 19)"
        fill="#9a9898"
      />
      <path
        id="Path_730"
        data-name="Path 730"
        d="M626.71,704.81a15.755,15.755,0,0,1-5.39,3.29C622.36,705.17,623.98,705.3,626.71,704.81Z"
        transform="translate(160 19)"
        fill="#939191"
      />
      <path
        id="Path_731"
        data-name="Path 731"
        d="M416.03,709.77c-.66,1.47-3.62-.43-4.31-1.17C412.99,707.34,415.43,708.28,416.03,709.77Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        id="Path_732"
        data-name="Path 732"
        d="M619.42,708.16c.18,1.39-3.12,2.47-4.13,1.77C614.98,708.59,618.46,707.5,619.42,708.16Z"
        transform="translate(160 19)"
        fill="#787576"
      />
      <path
        id="Path_733"
        data-name="Path 733"
        d="M659.26,710.82a3.712,3.712,0,0,1-3.71-1.87C657.52,707.55,658.36,709.28,659.26,710.82Z"
        transform="translate(160 19)"
        fill="#959da0"
      />
      <path
        id="Path_734"
        data-name="Path 734"
        d="M418.01,710.49c1.04-.74,4.47.74,3.83,2.19C420.84,713.43,417.23,711.95,418.01,710.49Z"
        transform="translate(160 19)"
        fill="#898787"
      />
      <path
        id="Path_735"
        data-name="Path 735"
        d="M612.44,710.54c2.34.59-3.75,3.73-3.51,1.74C609.47,711.04,611.18,710.49,612.44,710.54Z"
        transform="translate(160 19)"
        fill="#7e7b7c"
      />
      <path
        id="Path_736"
        data-name="Path 736"
        d="M664.26,714.84a3.68,3.68,0,0,1-3.69-1.92C662.52,711.49,663.36,713.36,664.26,714.84Z"
        transform="translate(160 19)"
        fill="#989d9f"
      />
      <path
        id="Path_737"
        data-name="Path 737"
        d="M424.16,713.13c.96-.76,4.35.25,3.76,1.72C427.06,715.53,423.59,714.43,424.16,713.13Z"
        transform="translate(160 19)"
        fill="#757273"
      />
      <path
        id="Path_738"
        data-name="Path 738"
        d="M604.32,713.11a2.42,2.42,0,0,1,2.08.13c.55,1.46-2.59,2.26-3.59,1.99C600.86,714.56,603.54,713.29,604.32,713.11Z"
        transform="translate(160 19)"
        fill="#868484"
      />
      <path
        id="Path_739"
        data-name="Path 739"
        d="M367.22,714.68a3.914,3.914,0,0,1-2.44,1.3C364.92,714.62,366.2,712.84,367.22,714.68Z"
        transform="translate(160 19)"
        fill="#7b6885"
      />
      <path
        id="Path_740"
        data-name="Path 740"
        d="M430.28,715.13c1.55-1.02,3.39.47,4.33,1.69C433.23,717.61,429.84,717.18,430.28,715.13Z"
        transform="translate(160 19)"
        fill="#8f8d8d"
      />
      <path
        id="Path_741"
        data-name="Path 741"
        d="M600.46,714.88c-1.34,2.49-3.32,2.57-5.81,3.12C596.05,715.73,598.05,715.47,600.46,714.88Z"
        transform="translate(160 19)"
        fill="#a5a4a4"
      />
      <path
        id="Path_742"
        data-name="Path 742"
        d="M669.26,718.84a3.6,3.6,0,0,1-3.66-1.9C667.64,715.52,668.33,717.31,669.26,718.84Z"
        transform="translate(160 19)"
        fill="#959da0"
      />
      <path
        id="Path_743"
        data-name="Path 743"
        d="M436.93,717.28c.98-.75,3.78.31,4.17,1.44C440.61,720.21,436,718.94,436.93,717.28Z"
        transform="translate(160 19)"
        fill="#8c8a8a"
      />
      <path
        id="Path_744"
        data-name="Path 744"
        d="M362.2,718.66a4.115,4.115,0,0,1-2.27,1.33C359.49,718.51,361.26,717.03,362.2,718.66Z"
        transform="translate(160 19)"
        fill="#7e6c86"
      />
      <path
        id="Path_745"
        data-name="Path 745"
        d="M592.83,717.96c.49,1.57-3.06,1.87-4.06,1.72A2.775,2.775,0,0,1,592.83,717.96Z"
        transform="translate(160 19)"
        fill="#797777"
      />
      <path
        @click="changeColor('Path_746')"
        id="Path_746"
        data-name="Path 746"
        d="M446.27,719.98c22.29,5.81,45.66,9.5,68.73,8.93,6.88-.09,13.47.36,20.32-.46,16.42-1.39,33.03-3.85,48.85-8.52a1.647,1.647,0,0,0,.59,2.12c-.55,3.26-.64,6.6-.96,9.88-1.18.81-1.93,2.67,0,2.97-.29,1.38-.52,2.76-.72,4.15a2.492,2.492,0,0,0-.96,3.06c.91,2.88.96,4.34-.32,7.14-1.2,1.63-2.18,3.42-.03,4.79-.19.7-.38,1.4-.57,2.09l-.43,1.69a3.6,3.6,0,0,0-.86,3.26c.04.98.06,1.97.08,2.95-1.23.71-2.05,2.5-.16,2.89-.4,1.02-.81,2.04-1.24,3.05a6.1,6.1,0,0,0-.69,4.1c.06.94.11,1.87.15,2.81a2.539,2.539,0,0,0-1.04,3.31,16,16,0,0,1-1.84,8.55,6.131,6.131,0,0,0-1.19,4.39c.08.96.15,1.93.23,2.9-1.32.36-1.69,1.41-1.09,3.16a39.4,39.4,0,0,1-2.12,9.23l-1.27,1.4A391.6,391.6,0,0,1,529,813.99c-13.32-.33-26.79.68-40-.86a260.5,260.5,0,0,1-30-3.65c-.27-.91-.53-1.81-.78-2.72v-.08l-.1-.28c-.45-1.4-.83-2.83-1.18-4.26-.25-.74-.5-1.47-.75-2.21,1.6-.82,1.51-1.39-.29-1.72-.16-1.5-.38-2.98-.67-4.46l.02-.08-.1-.3a48.727,48.727,0,0,1-1.08-5.35,4.733,4.733,0,0,0-.61-4.24c-.44-.95-.86-1.91-1.27-2.87a1.355,1.355,0,0,0-.39-2.67c.11-1.06.22-2.13.34-3.19a3.217,3.217,0,0,0-1.07-3.16c-.27-1.32-.54-2.64-.88-3.94,1.62-.87,1.52-1.44-.29-1.73-.3-3.96-1.91-7.1-.96-11.11a2.635,2.635,0,0,0-1.06-3.17c.11-1.01.23-2.03.35-3.04a4.871,4.871,0,0,0-.92-4.07l-.34-1.78c-.25-1.01-.53-2.02-.82-3.02a1.5,1.5,0,0,0,.68-2.18,18.406,18.406,0,0,1-1.13-7.98c-.17-.7-.34-1.39-.51-2.09,1.79-.23.91-2.4.26-3.36l.05-.6C445.74,722.54,446.01,721.26,446.27,719.98Z"
        transform="translate(160 19)"
        fill="#838bc4"
      />
      <path
        id="Path_747"
        data-name="Path 747"
        d="M584.17,719.93l1.63-.17a3.6,3.6,0,0,1-1.04,2.29A1.647,1.647,0,0,1,584.17,719.93Z"
        transform="translate(160 19)"
        fill="#57555c"
      />
      <path
        id="Path_748"
        data-name="Path 748"
        d="M673.86,723.1a3.2,3.2,0,0,1-2.21-1.31C672.61,720.17,674.29,721.63,673.86,723.1Z"
        transform="translate(160 19)"
        fill="#97999b"
      />
      <path
        id="Path_749"
        data-name="Path 749"
        d="M356.78,722.41l-.19.16-.19.14Z"
        transform="translate(160 19)"
        fill="#746e75"
      />
      <path
        id="Path_750"
        data-name="Path 750"
        d="M676.11,723.71c1.2.19,1.8,1.08,1.82,2.66C676.42,725.8,675.81,724.91,676.11,723.71Z"
        transform="translate(160 19)"
        fill="#899094"
      />
      <path
        id="Path_751"
        data-name="Path 751"
        d="M445.45,724.42c.65.96,1.53,3.13-.26,3.36A4.685,4.685,0,0,1,445.45,724.42Z"
        transform="translate(160 19)"
        fill="#676774"
      />
      <path
        id="Path_752"
        data-name="Path 752"
        d="M351.23,726.71a4.112,4.112,0,0,1-2.44,1.29C348.89,726.63,350.25,724.86,351.23,726.71Z"
        transform="translate(160 19)"
        fill="#7e6a87"
      />
      <path
        id="Path_753"
        data-name="Path 753"
        d="M681.18,727.74c1.32.24,3.89.98,2.13,2.63A3.357,3.357,0,0,1,681.18,727.74Z"
        transform="translate(160 19)"
        fill="#939799"
      />
      <path
        id="Path_754"
        data-name="Path 754"
        d="M583.8,731.93a5.349,5.349,0,0,1,0,2.97C581.87,734.6,582.62,732.74,583.8,731.93Z"
        transform="translate(160 19)"
        fill="#5d5d6d"
      />
      <path
        id="Path_755"
        data-name="Path 755"
        d="M689,734.01a6.542,6.542,0,0,1-2.26-1.35C687.68,730.99,689.53,732.5,689,734.01Z"
        transform="translate(160 19)"
        fill="#868c90"
      />
      <path
        id="Path_756"
        data-name="Path 756"
        d="M339.56,734.6l.24-.15-.44.3Z"
        transform="translate(160 19)"
        fill="#686069"
      />
      <path
        id="Path_757"
        data-name="Path 757"
        d="M694.98,738.05a3.821,3.821,0,0,1-2.24-1.35C693.66,735.04,695.4,736.61,694.98,738.05Z"
        transform="translate(160 19)"
        fill="#8a9194"
      />
      <path
        id="Path_758"
        data-name="Path 758"
        d="M335.26,737.69a6.768,6.768,0,0,1-2.25,1.31C332.44,737.42,334.29,736.04,335.26,737.69Z"
        transform="translate(160 19)"
        fill="#816f8a"
      />
      <path
        id="Path_759"
        data-name="Path 759"
        d="M446.83,737.85a1.5,1.5,0,0,1-.68,2.18C445.63,738.9,445.85,738.17,446.83,737.85Z"
        transform="translate(160 19)"
        fill="#7f7d83"
      />
      <path
        id="Path_760"
        data-name="Path 760"
        d="M583.08,739.05q.825,2.565-.96,3.06A2.492,2.492,0,0,1,583.08,739.05Z"
        transform="translate(160 19)"
        fill="#646787"
      />
      <path
        id="Path_761"
        data-name="Path 761"
        d="M697.18,738.58c1.79.24,2.37,1.14,2.81,2.79C698.53,740.92,697.05,740.4,697.18,738.58Z"
        transform="translate(160 19)"
        fill="#939698"
      />
      <path
        id="Path_762"
        data-name="Path 762"
        d="M329.18,741.65a4.675,4.675,0,0,1-2.31,1.28C326.45,741.44,328.31,740,329.18,741.65Z"
        transform="translate(160 19)"
        fill="#82718a"
      />
      <path
        id="Path_763"
        data-name="Path 763"
        d="M703.06,742.77c1.45.29,2.84.58,3.03,2.31A2.62,2.62,0,0,1,703.06,742.77Z"
        transform="translate(160 19)"
        fill="#889092"
      />
      <path
        id="Path_764"
        data-name="Path 764"
        d="M324.26,744.72a6.5,6.5,0,0,1-2.19,1.37C321.42,744.52,323.23,743.11,324.26,744.72Z"
        transform="translate(160 19)"
        fill="#84718f"
      />
      <path
        id="Path_765"
        data-name="Path 765"
        d="M447.31,744.83a4.871,4.871,0,0,1,.92,4.07C446.35,748.16,446.77,746.36,447.31,744.83Z"
        transform="translate(160 19)"
        fill="#7d7c88"
      />
      <path
        id="Path_766"
        data-name="Path 766"
        d="M708.07,745.64a3.543,3.543,0,0,1,3.07,2.54C709.56,747.98,707.99,747.57,708.07,745.64Z"
        transform="translate(160 19)"
        fill="#939799"
      />
      <path
        id="Path_767"
        data-name="Path 767"
        d="M318.21,748.14c-.89,1.1-1.77,2.07-3.32,1.88A2.339,2.339,0,0,1,318.21,748.14Z"
        transform="translate(160 19)"
        fill="#8c8190"
      />
      <path
        id="Path_768"
        data-name="Path 768"
        d="M581.8,749.25c.21,1.66.54,3.15-.03,4.79C579.62,752.67,580.6,750.88,581.8,749.25Z"
        transform="translate(160 19)"
        fill="#81839d"
      />
      <path
        id="Path_769"
        data-name="Path 769"
        d="M714.02,749.97l1.7.21.51.56a2.4,2.4,0,0,1-2.18.93Z"
        transform="translate(160 19)"
        fill="#5b595a"
      />
      <path
        id="Path_770"
        data-name="Path 770"
        d="M314.94,750.91a2.682,2.682,0,0,1,3.28,2.32A2.738,2.738,0,0,1,314.94,750.91Z"
        transform="translate(160 19)"
        fill="#7c797a"
      />
      <path
        id="Path_771"
        data-name="Path 771"
        d="M447.88,751.94a2.635,2.635,0,0,1,1.06,3.17C447.82,754.73,447.47,753.67,447.88,751.94Z"
        transform="translate(160 19)"
        fill="#5a5c77"
      />
      <path
        id="Path_772"
        data-name="Path 772"
        d="M712.04,752.95c-.17,1.54-2.11,3.52-3.7,2.69C707.65,753.86,710.63,752.67,712.04,752.95Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_773"
        data-name="Path 773"
        d="M320.73,754.93a3.06,3.06,0,0,1,3.29,2.15A2.911,2.911,0,0,1,320.73,754.93Z"
        transform="translate(160 19)"
        fill="#5f5c5d"
      />
      <path
        id="Path_774"
        data-name="Path 774"
        d="M706.24,756.91a2.871,2.871,0,0,1-3.28,2.12A3,3,0,0,1,706.24,756.91Z"
        transform="translate(160 19)"
        fill="#5d5a5b"
      />
      <path
        id="Path_775"
        data-name="Path 775"
        d="M326.11,758.19c1.11-.48,4,1.33,3.56,2.63C328.59,761.44,325.63,759.45,326.11,758.19Z"
        transform="translate(160 19)"
        fill="#7c7a7a"
      />
      <path
        id="Path_776"
        data-name="Path 776"
        d="M580.77,757.82c.73,1.77.45,2.86-.86,3.26A3.6,3.6,0,0,1,580.77,757.82Z"
        transform="translate(160 19)"
        fill="#5d5d6f"
      />
      <path
        id="Path_778"
        data-name="Path 778"
        d="M701.02,760.05c-.4,1.48-2.07,3.32-3.71,2.69C696.76,761.03,699.72,760.05,701.02,760.05Z"
        transform="translate(160 19)"
        fill="#7c797a"
      />
      <path
        id="Path_779"
        data-name="Path 779"
        d="M331.84,761.96a3.991,3.991,0,0,1,3.65,2C333.67,764.99,332.28,763.77,331.84,761.96Z"
        transform="translate(160 19)"
        fill="#726f6f"
      />
      <path
        id="Path_780"
        data-name="Path 780"
        d="M696.09,763.1a11.054,11.054,0,0,1-4.55,3.88C692.17,764.03,693.53,763.89,696.09,763.1Z"
        transform="translate(160 19)"
        fill="#a4a3a3"
      />
      <path
        id="Path_792"
        data-name="Path 792"
        d="M579.99,764.03a4.154,4.154,0,0,1-.16,2.89C577.94,766.53,578.76,764.74,579.99,764.03Z"
        transform="translate(160 19)"
        fill="#5b5c73"
      />
      <path
        id="Path_793"
        data-name="Path 793"
        d="M337.22,765.28c1.11-.75,4.33,1.2,3.68,2.57C339.8,768.56,336.57,766.65,337.22,765.28Z"
        transform="translate(160 19)"
        fill="#898788"
      />
      <path
        id="Path_794"
        data-name="Path 794"
        d="M472.51,765.69a22.262,22.262,0,0,1,3.27.19q.285,1.575.54,3.15c-1.87-.07-3.74-.15-5.62-.26A31.447,31.447,0,0,1,472.51,765.69Z"
        transform="translate(160 19)"
        fill="#838bc4"
      />
      <path
        id="Path_795"
        data-name="Path 795"
        d="M520.45,765.55a21.339,21.339,0,0,1,3.29.34c.18,1.04.36,2.08.53,3.13-1.91-.04-3.81-.09-5.72-.16C519.14,767.79,519.54,766.39,520.45,765.55Z"
        transform="translate(160 19)"
        fill="#838bc4"
      />
      <path
        id="Path_796"
        data-name="Path 796"
        d="M449.9,766.22c1.81.29,1.91.86.29,1.73Z"
        transform="translate(160 19)"
        fill="#595b77"
      />
      <path
        id="Path_798"
        data-name="Path 798"
        d="M541.78,765.69c1.4.03,2.79.07,4.19.1.26,1.01.51,2.03.76,3.04l-5.27.27C541.56,767.96,541.67,766.83,541.78,765.69Z"
        transform="translate(160 19)"
        fill="#7c83b8"
      />
      <path
        id="Path_799"
        data-name="Path 799"
        d="M689.77,767.15c.39,1.28-2.67,2.83-3.71,2.36C685.56,768.12,688.68,766.49,689.77,767.15Z"
        transform="translate(160 19)"
        fill="#797677"
      />
      <path
        id="Path_800"
        data-name="Path 800"
        d="M343.06,768.63c1.54-.64,3.34.98,3.93,2.32C345.74,771.2,342.43,770.38,343.06,768.63Z"
        transform="translate(160 19)"
        fill="#787677"
      />
      <path
        id="Path_801"
        data-name="Path 801"
        d="M578.59,769.97c.86,1.3,1.1,3.49-.69,4.1A6.1,6.1,0,0,1,578.59,769.97Z"
        transform="translate(160 19)"
        fill="#64657d"
      />
      <path
        id="Path_802"
        data-name="Path 802"
        d="M682.57,770.13c4.23-.68-1.96,4.68-2.53,2.16A3.9,3.9,0,0,1,682.57,770.13Z"
        transform="translate(160 19)"
        fill="#8b898a"
      />
      <path
        id="Path_803"
        data-name="Path 803"
        d="M349.08,772.09c1.44-.82,3.02.84,3.68,2C351.62,774.23,348.58,773.74,349.08,772.09Z"
        transform="translate(160 19)"
        fill="#6a6868"
      />
      <path
        id="Path_804"
        data-name="Path 804"
        d="M451.07,771.89a3.217,3.217,0,0,1,1.07,3.16C450.85,774.74,450.49,773.69,451.07,771.89Z"
        transform="translate(160 19)"
        fill="#61637c"
      />
      <path
        id="Path_805"
        data-name="Path 805"
        d="M677.48,773.31c2.44.59-3.09,4.14-3.18,2.09A4.308,4.308,0,0,1,677.48,773.31Z"
        transform="translate(160 19)"
        fill="#7b7879"
      />
      <path
        id="Path_806"
        data-name="Path 806"
        d="M354.95,775.17c1.07-.68,3.7.63,3.87,1.88C358.41,778.79,354.07,776.58,354.95,775.17Z"
        transform="translate(160 19)"
        fill="#918f90"
      />
      <path
        id="Path_807"
        data-name="Path 807"
        d="M671.53,776.46c2.58.64-3.06,3.94-3.15,1.92A3.909,3.909,0,0,1,671.53,776.46Z"
        transform="translate(160 19)"
        fill="#807d7e"
      />
      <path
        id="Path_808"
        data-name="Path 808"
        d="M578.05,776.88a2.919,2.919,0,0,1-1.04,3.31A2.539,2.539,0,0,1,578.05,776.88Z"
        transform="translate(160 19)"
        fill="#686877"
      />
      <path
        id="Path_809"
        data-name="Path 809"
        d="M360.83,778.04a3.153,3.153,0,0,1,3.41,1.98A2.878,2.878,0,0,1,360.83,778.04Z"
        transform="translate(160 19)"
        fill="#5e5b5b"
      />
      <path
        id="Path_810"
        data-name="Path 810"
        d="M451.8,778.24a1.355,1.355,0,0,1,.39,2.67A4.011,4.011,0,0,1,451.8,778.24Z"
        transform="translate(160 19)"
        fill="#5e607a"
      />
      <path
        id="Path_811"
        data-name="Path 811"
        d="M665.35,779.18c.76-.14,1.14.04,1.16.52-.15,1.25-2.94,2.74-4.01,2.06C661.98,780.49,664.4,779.4,665.35,779.18Z"
        transform="translate(160 19)"
        fill="#8e8b8c"
      />
      <path
        id="Path_812"
        data-name="Path 812"
        d="M366.85,781.02a3.14,3.14,0,0,1,3.46,1.97A3.03,3.03,0,0,1,366.85,781.02Z"
        transform="translate(160 19)"
        fill="#5b5859"
      />
      <path
        id="Path_813"
        data-name="Path 813"
        d="M660.31,782.21c-.45,1.86-1.89,2.39-3.65,2.4C657.09,782.76,658.54,782.19,660.31,782.21Z"
        transform="translate(160 19)"
        fill="#767374"
      />
      <path
        id="Path_814"
        data-name="Path 814"
        d="M376.86,785.91c-1.35.21-3.8-.04-4.13-1.65C373.77,782.61,376.35,784.61,376.86,785.91Z"
        transform="translate(160 19)"
        fill="#828080"
      />
      <path
        id="Path_815"
        data-name="Path 815"
        d="M453.46,783.78a4.733,4.733,0,0,1,.61,4.24C452.87,786.75,452.31,785.31,453.46,783.78Z"
        transform="translate(160 19)"
        fill="#878690"
      />
      <path
        id="Path_816"
        data-name="Path 816"
        d="M654.23,785.08c-.52,1.45-2.28,2.65-3.76,1.84C650.1,785.51,653.25,784.92,654.23,785.08Z"
        transform="translate(160 19)"
        fill="#6f6c6d"
      />
      <path
        id="Path_817"
        data-name="Path 817"
        d="M379.01,786.32c.96-.76,4.53.86,3.81,2.21C381.73,789.28,378.3,787.85,379.01,786.32Z"
        transform="translate(160 19)"
        fill="#8c8a8b"
      />
      <path
        id="Path_818"
        data-name="Path 818"
        d="M647.5,787.44c2.62.69-3.15,3.71-3.22,1.85C644.72,788.1,646.32,787.47,647.5,787.44Z"
        transform="translate(160 19)"
        fill="#7f7d7d"
      />
      <path
        id="Path_819"
        data-name="Path 819"
        d="M385.09,789.1c1.46-.88,3.46.36,3.98,1.85C387.97,791.26,384.5,790.79,385.09,789.1Z"
        transform="translate(160 19)"
        fill="#716f6f"
      />
      <path
        id="Path_820"
        data-name="Path 820"
        d="M575.17,788.74c.78,1.83,1.09,3.59-1.19,4.39A6.131,6.131,0,0,1,575.17,788.74Z"
        transform="translate(160 19)"
        fill="#85869b"
      />
      <path
        id="Path_821"
        data-name="Path 821"
        d="M639.48,790.32a2.715,2.715,0,0,1,2.44-.18c.62,1.47-2.2,2.54-3.32,2.28C636.99,791.93,638.74,790.63,639.48,790.32Z"
        transform="translate(160 19)"
        fill="#8c8a8b"
      />
      <path
        id="Path_822"
        data-name="Path 822"
        d="M390.68,791.01c2.21.55,4.02.57,4.94,3.01C393.61,793.2,391.41,793.44,390.68,791.01Z"
        transform="translate(160 19)"
        fill="#969494"
      />
      <path
        id="Path_823"
        data-name="Path 823"
        d="M636.59,791.75c-1.1,2.45-2.88,2.51-5.2,3.15C632.6,792.76,634.43,792.46,636.59,791.75Z"
        transform="translate(160 19)"
        fill="#949292"
      />
      <path
        id="Path_824"
        data-name="Path 824"
        d="M455.15,793.37l.1.3-.02.08Z"
        transform="translate(160 19)"
        fill="#595b79"
      />
      <path
        id="Path_825"
        data-name="Path 825"
        d="M397.83,793.97c1.46-.37,3.05.08,3.51,1.64C400.03,795.78,398.24,795.41,397.83,793.97Z"
        transform="translate(160 19)"
        fill="#666464"
      />
      <path
        id="Path_826"
        data-name="Path 826"
        d="M628.5,794.39c.86-.09,1.35.12,1.47.64-.3,1.16-2.99,2.35-4,1.62C625.32,795.34,627.53,794.48,628.5,794.39Z"
        transform="translate(160 19)"
        fill="#8e8c8d"
      />
      <path
        id="Path_827"
        data-name="Path 827"
        d="M407.8,797.86c-1.23.74-3.09-.48-4.06-1.26C404.72,795.2,408.3,796.04,407.8,797.86Z"
        transform="translate(160 19)"
        fill="#7a7879"
      />
      <path
        id="Path_828"
        data-name="Path 828"
        d="M574.21,796.03a3.309,3.309,0,0,1-1.09,3.16C572.52,797.44,572.89,796.39,574.21,796.03Z"
        transform="translate(160 19)"
        fill="#66667a"
      />
      <path
        id="Path_829"
        data-name="Path 829"
        d="M623.78,797.05c-.86,1.28-2.81,2.79-4.34,1.76C618.7,796.68,622.5,796.59,623.78,797.05Z"
        transform="translate(160 19)"
        fill="#8b8989"
      />
      <path
        id="Path_830"
        data-name="Path 830"
        d="M409.96,798.08c1.1-.34,4.67-.03,3.96,1.77C412.48,800.68,410.52,799.52,409.96,798.08Z"
        transform="translate(160 19)"
        fill="#777575"
      />
      <path
        id="Path_831"
        data-name="Path 831"
        d="M455.9,798.21c1.8.33,1.89.9.29,1.72Z"
        transform="translate(160 19)"
        fill="#5b5b70"
      />
      <path
        id="Path_832"
        data-name="Path 832"
        d="M617.08,799.58c-.64,1.09-2.81,1.84-3.92,1.12C612.11,798.95,617.07,797.63,617.08,799.58Z"
        transform="translate(160 19)"
        fill="#8e8b8c"
      />
      <path
        id="Path_833"
        data-name="Path 833"
        d="M420.67,801.73c-1.18.96-3.22-.24-4.17-1.07C417.39,799.1,421.42,799.63,420.67,801.73Z"
        transform="translate(160 19)"
        fill="#939192"
      />
      <path
        id="Path_834"
        data-name="Path 834"
        d="M609.5,800.36c.9-.08,1.42.16,1.55.71-.29,1.19-3.12,2.11-4.08,1.37C606.28,801.16,608.6,800.44,609.5,800.36Z"
        transform="translate(160 19)"
        fill="#8a8889"
      />
      <path
        id="Path_835"
        data-name="Path 835"
        d="M426.94,803.5c-1.17.72-3.05-.28-4.07-.94C423.66,801.01,427.57,801.62,426.94,803.5Z"
        transform="translate(160 19)"
        fill="#7d7b7c"
      />
      <path
        id="Path_836"
        data-name="Path 836"
        d="M604.59,802.4c.44,1.86-2.94,2.19-3.91,1C601.69,802.81,603.43,801.85,604.59,802.4Z"
        transform="translate(160 19)"
        fill="#777475"
      />
      <path
        id="Path_837"
        data-name="Path 837"
        d="M429.23,803.47c1.15-.8,3.07.22,4.09.88C432.47,805.74,428.57,805.45,429.23,803.47Z"
        transform="translate(160 19)"
        fill="#817e7f"
      />
      <path
        id="Path_838"
        data-name="Path 838"
        d="M598.07,804.07c-.46,1.78-2.56,2.06-4.11,1.81C594.32,803.76,596.33,803.62,598.07,804.07Z"
        transform="translate(160 19)"
        fill="#7b797a"
      />
      <path
        id="Path_839"
        data-name="Path 839"
        d="M439.88,806.72c-1.23.88-3.18-.27-4.18-1.08C436.63,804.11,440.74,804.56,439.88,806.72Z"
        transform="translate(160 19)"
        fill="#888586"
      />
      <path
        id="Path_840"
        data-name="Path 840"
        d="M591.75,805.48c.64,1.97-3.24,2.32-4.06.87C588.7,805.69,590.62,804.69,591.75,805.48Z"
        transform="translate(160 19)"
        fill="#838081"
      />
      <path
        id="Path_841"
        data-name="Path 841"
        d="M442.24,806.26c1.21-.95,3.19.25,4.16,1.08C445.48,808.85,441.43,808.41,442.24,806.26Z"
        transform="translate(160 19)"
        fill="#8d8b8c"
      />
      <path
        id="Path_842"
        data-name="Path 842"
        d="M458.12,806.4l.1.28v.08Z"
        transform="translate(160 19)"
        fill="#585a77"
      />
      <path
        id="Path_843"
        data-name="Path 843"
        d="M585.13,807.06c-.1,1.67-3.74,3.06-4.19,1.04C581.42,806.5,583.86,806.67,585.13,807.06Z"
        transform="translate(160 19)"
        fill="#898687"
      />
      <path
        id="Path_844"
        data-name="Path 844"
        d="M448.93,808.15c1.32-.51,3.16-.48,3.99.85C451.66,809.49,449.69,809.47,448.93,808.15Z"
        transform="translate(160 19)"
        fill="#4e4b4c"
      />
      <path
        id="Path_845"
        data-name="Path 845"
        d="M578.75,808.19c.96,1.56-3.94,2.88-4,1.04C575.31,807.89,577.58,807.43,578.75,808.19Z"
        transform="translate(160 19)"
        fill="#8e8c8c"
      />
      <path
        id="Path_846"
        data-name="Path 846"
        d="M455.13,809.12a4.232,4.232,0,0,1,3.87.36,19.136,19.136,0,0,1-1.99.96A2.361,2.361,0,0,1,455.13,809.12Z"
        transform="translate(160 19)"
        fill="#615e5f"
      />
      <path
        @click="changeColor('Path_847')"
        id="Path_847"
        data-name="Path 847"
        d="M459,809.48a260.5,260.5,0,0,0,30,3.65c13.21,1.54,26.68.53,40,.86a391.6,391.6,0,0,0,40.73-4.17l.07.94a97.278,97.278,0,0,1-3.02,11.06c-10.93,36.39-27.9,71.9-51.07,102.11a168.055,168.055,0,0,1-18.43-24.23c-17.3-26.98-29.24-57.62-37.6-88.47-.89-.26-1.78-.52-2.67-.79A19.136,19.136,0,0,0,459,809.48Z"
        transform="translate(160 19)"
        fill="#a7acd5"
      />
      <path
        id="Path_848"
        data-name="Path 848"
        d="M571,808.42c1.68,1.26.89,3.27-1.2,2.34l-.07-.94Z"
        transform="translate(160 19)"
        fill="#78767b"
      />
      <text
        id="optimizëm"
        transform="translate(444 131)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">optimism</tspan>
      </text>
      <text
        id="dashuri"
        transform="translate(823 131)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">love</tspan>
      </text>
      <text
        id="nënshtrimi"
        transform="translate(1002 345)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">submission</tspan>
      </text>
      <text
        id="agresiviteti"
        transform="translate(238 347)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">aggressiveness</tspan>
      </text>
      <text
        id="përbuzje"
        transform="translate(236 636)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">contempt</tspan>
      </text>
      <text
        id="frikë"
        transform="translate(1027 641)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">awe</tspan>
      </text>
      <text
        id="pendesë"
        transform="translate(474 846)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">remorse</tspan>
      </text>
      <text
        id="mosmiratim"
        transform="translate(796 842)"
        font-size="20"
        font-family="SegoeUI-Bold, Segoe UI"
        font-weight="700"
      >
        <tspan x="0" y="0">disapproval</tspan>
      </text>
      <text
        id="interes"
        transform="translate(429 284)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_103')"
      >
        <tspan x="0" y="0">interest</tspan>
      </text>
      <text
        id="qetësia"
        transform="translate(645 191)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_22')"
      >
        <tspan x="0" y="0">serenity</tspan>
      </text>
      <text
        id="pranimi"
        transform="translate(859 279)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_105')"
      >
        <tspan x="0" y="0">acceptance</tspan>
      </text>
      <text
        id="gëzimi"
        transform="translate(648 277)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_119')"
      >
        <tspan x="0" y="0">joy</tspan>
      </text>
      <text
        id="parashikim"
        transform="translate(473 341)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_163')"
      >
        <tspan x="0" y="0">anticipation</tspan>
      </text>
      <text
        id="besim"
        transform="translate(806 342)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_164')"
      >
        <tspan x="0" y="0">trust</tspan>
      </text>
      <text
        id="ekstazë"
        transform="translate(645 369)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_210')"
      >
        <tspan x="0" y="0">ecstasy</tspan>
      </text>
      <text
        id="vigjilence"
        transform="translate(550 420)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_262')"
      >
        <tspan x="0" y="0">vigilance</tspan>
      </text>
      <text
        id="admirim"
        transform="translate(727 420)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_266')"
      >
        <tspan x="0" y="0">admiration</tspan>
      </text>
      <text
        id="bezdi"
        transform="translate(349 493)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_388')"
      >
        <tspan x="-30" y="0">annoyance</tspan>
      </text>
      <text
        id="zemërimi"
        transform="translate(420 489)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_382')"
      >
        <tspan x="0" y="0">anger</tspan>
      </text>
      <text
        id="tërbim"
        transform="translate(528 495)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_390')"
      >
        <tspan x="0" y="0">rage</tspan>
      </text>
      <text
        id="terrori"
        transform="translate(732 495)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_395')"
      >
        <tspan x="0" y="0">terror</tspan>
      </text>
      <text
        id="frikë-2"
        data-name="frikë"
        transform="translate(867 498)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_384')"
      >
        <tspan x="0" y="0">fear</tspan>
      </text>
      <text
        id="të_kuptuarit_"
        data-name="të kuptuarit
"
        transform="translate(962 498)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_385')"
      >
        <tspan x="0" y="0">apprehension</tspan>
        <tspan x="0" y="27"></tspan>
      </text>
      <text
        id="urrejtje"
        transform="translate(551 573)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_477')"
      >
        <tspan x="0" y="0">loathing</tspan>
      </text>
      <text
        id="neveri"
        transform="translate(494 646)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_515')"
      >
        <tspan x="0" y="0">disgust</tspan>
      </text>
      <text
        id="hidhërimi"
        transform="translate(634 629)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_480')"
      >
        <tspan x="20" y="0">grief</tspan>
      </text>
      <text
        id="habia"
        transform="translate(743 573)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_478')"
      >
        <tspan x="-20" y="0">amazement</tspan>
      </text>
      <text
        id="befasi"
        transform="translate(805 646)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_527')"
      >
        <tspan x="0" y="0">surprise</tspan>
      </text>
      <text
        id="mërzinë"
        transform="translate(434 714)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_587')"
      >
        <tspan x="0" y="0">boredom</tspan>
      </text>
      <text
        id="trishtim"
        transform="translate(643 711)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_648')"
      >
        <tspan x="0" y="0">sadness</tspan>
      </text>
      <text
        id="shpërqendrim"
        transform="translate(825 720)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_586')"
      >
        <tspan x="0" y="0">distraction</tspan>
      </text>
      <text
        id="mendueshmëria"
        transform="translate(612 792)"
        font-size="20"
        font-family="SegoeUI-Semilight, Segoe UI"
        font-weight="300"
        @click="changeColor('Path_746')"
      >
        <tspan x="10" y="0">Pensiveness</tspan>
      </text>
    </g>
  </svg>
</template>
<script>
export default {
  name: "wheel-of-emtions",
  methods: {
    changeColor(id) {
      var curve_line = document.getElementById(id);
      if (curve_line.style.filter) {
        curve_line.style.filter = null;
        curve_line.style.strokeDasharray = null;
        curve_line.style.stroke = null;
        curve_line.style.strokeWidth = null;
        curve_line.style.opacity = 0.4;
      } else {
        curve_line.style.filter = "drop-shadow(2px -4px 6px black)";
        curve_line.style.opacity = 1;
      }
    }
  }
};
</script>
