<template>
  <div
    class="flexi-container page__internal page__unit-4-part-a"
    v-show="showPage"
  >
    <!-- Top Card -->
    <flexi-top-card
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[9].top_card"
      @button-clicked="openModal"
    >
      <template v-slot:media="slotProps">
        <div class="sub-header">
          <div class="sub-header--smallmenu">
            <div
              v-for="(value, key) in subHeaderFourthUnit"
              :key="key"
              :class="{ active: subHeaderKey === key }"
              class="sub-header--smallmenu__item"
            >
              <a
                v-text="value.name"
                @click="goToRoute(value.href)"
                class="sub-header__unit"
              />
            </div>
          </div>
          <div class="sub-header__pagination">
            <span
              @click="goToRoute('/unit-4')"
              class="sub-header__pagination--item"
              v-text="'Previous'"
            />
            <span v-text="'|'" />
            <span
              v-text="'Next'"
              @click="goToRoute('/unit-4/part-b')"
              class="sub-header__pagination--item"
            />
          </div>
        </div>
        <img class="image" :src="slotProps.inputData.img" />
      </template>
    </flexi-top-card>

    <flexi-scrolling-slider
      v-if="inputData.internalPages"
      :sections="unitsSections[0].fourthUnitPartA"
      :activeSection="activeSection"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[9].sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <div
          v-if="!showPopUpFirstQuestion"
          class="hidden"
          v-view="popUpFirstQuestion"
        />
        <h3
          class="flexi-wrapper__section--title--left"
          v-text="slotProps.inputData.title"
        />
        <h3
          class="flexi-wrapper__section--title--right"
          v-text="slotProps.inputData.subTitle"
        />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--img">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
          />
        </div>
        <div class="flexi-wrapper__section--content--content-title">
          <span v-text="slotProps.inputData.contentTitle" />
          <a
            v-text="slotProps.inputData.buttonLink"
            :href="slotProps.inputData.buttonLink"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[9].sections[1]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
      v-sectionview
    >
      <template v-slot:title="slotProps">
        <h3 v-text="slotProps.inputData.title" />
      </template>
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--description">
          <p
            v-html="slotProps.inputData.description"
            data-aos="fade-down"
            :data-aos-duration="!isMobile ? 1500 : 1000"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!--Vocabulary Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[9].sections[2]"
      data-aos="fade-up"
      :data-aos-duration="!isMobile ? 1500 : 1000"
    >
      <template v-slot:content="slotProps">
        <div class="content" :class="{ opened: openContent }">
          <div class="content--title" @click="openVocabulary">
            <h3 v-text="slotProps.inputData.title" />
          </div>

          <div class="content--container">
            <div
              v-for="(value, key) in slotProps.inputData.widgets"
              :key="key"
              class="content--container__item"
            >
              <span
                v-text="value.title"
                class="content--container__item--title"
              />
              <span
                class="content--container__item--subtitle"
                v-text="value.subTitle"
              />
              <span
                class="content--container__item--paragraph"
                v-html="value.paragraph"
              />
            </div>
            <div class="content--container__item--source">
              <span v-text="slotProps.inputData.widgets[10].footerText" />
              <a
                v-text="slotProps.inputData.widgets[10].buttonText"
                :href="slotProps.inputData.widgets[10].buttonLink"
              />
            </div>
          </div>
        </div>
      </template>
    </flexi-wrapper>

    <!-- Unit Button Section -->
    <flexi-wrapper
      v-if="inputData.internalPages"
      :inputData="inputData.internalPages[9].sections[3]"
    >
      <template v-slot:content="slotProps">
        <div
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          class="bottom-buttons"
        >
          <flexi-button
            :text="value.buttonText"
            @button-clicked="goToRoute(value.buttonLink)"
            class="flexi-btn"
          />
        </div>
      </template>
    </flexi-wrapper>

    <flexi-modal :name="'pop-up-question'">
      <template v-slot:content>
        <div class="flexi-modal__content" :class="additionalModalClass">
          <flexi-pop-up-question-multi
            v-if="showPopUpFirstQuestion"
            :question="inputData.quizzes[3].questions[6]"
            @closeModal="closeModal"
            @removeModalClass="additionalModalClass = ''"
            @scoreIsCalculated="addModalClass"
          />
        </div>
      </template>
    </flexi-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { subHeaderFourthUnit } from "../../../static/data/subHeaders";

import FlexiPopUpQuestionMulti from "../../../components/FlexiPopUpQuestionMulti.vue";
import FlexiScrollingSlider from "../../../components/FlexiScrollingSlider.vue";

export default {
  name: "part-a",
  metaInfo() {
    return {
      title: "VERY VERIFIED - Unit 4. Misinformation and Manipulation",
      meta: [
        {
          name: "description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        {
          name: "og:title",
          content: "VERY VERIFIED - Unit 4. Misinformation and Manipulation"
        },
        {
          name: "og:description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/6wU_1638501998_seo_ks_u4a.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED - Unit 4. Misinformation and Manipulation"
        },
        {
          name: "twitter:description",
          content:
            "Learn how to distinguish between different types of manipulation and misinformation on traditional, online and social media: fake news, misinformation, bots, trolls and more."
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/6wU_1638501998_seo_ks_u4a.png"
        }
      ]
    };
  },
  components: {
    FlexiPopUpQuestionMulti,
    FlexiScrollingSlider
  },
  computed: {
    ...mapGetters(["inputData", "language", "isMobile"])
  },
  directives: {
    sectionview: {
      inViewport(el) {
        var rect = el.getBoundingClientRect();
        var elemTop = rect.top;
        var elemBottom = rect.bottom;
        return elemTop < window.innerHeight && elemBottom >= 0;
      },
      bind(el, binding, vnode) {
        el.$onScroll = function() {
          if (binding.def.inViewport(el)) {
            var id = el.getAttribute("id");
            vnode.context.activeSection = id;
          }
        };
        document.addEventListener("scroll", el.$onScroll);
      },
      inserted(el) {
        el.$onScroll();
      },
      unbind(el) {
        document.removeEventListener("scroll", el.$onScroll);
        delete el.$onScroll;
      }
    }
  },
  data() {
    return {
      activeSection: "",
      additionalModalClass: "",
      answeredPopUpFirstQuestion: false,
      openContent: false,
      showPopUpFirstQuestion: false,
      subHeaderKey: 0,
      subHeaderFourthUnit: subHeaderFourthUnit
    };
  },
  methods: {
    addModalClass(boolean) {
      if (boolean) {
        this.additionalModalClass = "correct-modal-score";
      } else {
        this.additionalModalClass = "wrong-modal-score";
      }
    },
    closeModal(data) {
      if (!this.score.scorePogress.unit4PartA) {
        this.setSelectedAnswersPopUp(data);
      }

      this.answeredPopUpFirstQuestion = true;
      location.hash = "#types-of-information-disorder";
      this.$router.push("/unit-4/part-a");
    },
    popUpFirstQuestion(e) {
      if (!this.score.scorePogress.unit4PartA) {
        if (!this.answeredPopUpFirstQuestion) {
          if (e.percentTop > 0.658 && e.percentTop < 0.8) {
            this.showPopUpFirstQuestion = true;
            this.openPopUpModal();
          }
        }
      }
    }
  },
  mounted() {
    this.showPage = true;
    this.activeSection = this.unitsSections[0].fourthUnitPartA[0].string_id;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 800);
  },
  beforeDestroy() {
    if (
      this.answeredPopUpFirstQuestion &&
      !this.score.scorePogress.unit4PartA
    ) {
      this.setScore(
        parseInt(this.score.progress) + parseInt(this.score.scorePoints)
      );
      this.setUnitScore("unit4PartA");
      this.setLastUnit("/unit-4/part-b");
    }

    if (this.user) {
      const form = {
        email: this.user.email,
        last_unit: this.score.last_unit,
        scorePogress: this.score.scorePogress,
        progress: this.score.progress,
        popUpQuestions: this.selectedAnswersPopUp
      };
      this.updateProgress(form);
    }
  }
};
</script>
