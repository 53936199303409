<template>
  <div class="flexi-container page__home" v-show="showPage">
    <!-- Top Card  -->
    <flexi-top-card
      v-if="inputData.homePage"
      :inputData="inputData.homePage.top_card"
      @button-clicked="openModal"
    />

    <!-- Go To TOP ( ARROWZ ) -->
    <flexi-visible when-hidden="#top-card">
      <a @click.prevent="smoothScroll('top-card')">
        <div class="arrow-down">
          <span class="text" v-text="'Go to top'"></span>
          <div class="svg"></div>
        </div>
      </a>
    </flexi-visible>

    <!-- First Section HomePage -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[0]"
      :data-aos="!isMobile ? 'flip-up' : ''"
      :data-aos-duration="!isMobile ? 1500 : ''"
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--block">
          <img
            :src="slotProps.inputData.img"
            :alt="slotProps.inputData.title"
            class="flexi-wrapper__section--content--block__img"
            :data-aos="!isMobile ? 'fade-right' : ''"
            :data-aos-offset="200"
            :data-aos-duration="!isMobile ? 1500 : 0"
            data-aos-easing="ease-in"
          />
          <p
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content--block__description"
            :data-aos="!isMobile ? 'fade-left' : ''"
            :data-aos-duration="!isMobile ? 1500 : 0"
            data-aos-easing="ease-in"
          />
        </div>
      </template>
    </flexi-wrapper>

    <!-- Second Section HomePage -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[1]"
    >
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :options="secondSection.options"
          :inputData="value"
          :data-aos="expandedAos(key)"
          :data-aos-duration="!isMobile ? 800 : 800"
          data-aos-easing="ease-in"
        />
      </template>
    </flexi-wrapper>

    <!-- Third Section HomePage -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[2]"
    >
      <template v-slot:content="slotProps">
        <flexi-column
          v-for="(value, key) in slotProps.inputData.widgets"
          :key="key"
          :options="thirdSection.options"
          :inputData="value"
          @button-clicked="goToRouteFromObject"
          :data-aos="expandedAosZoom(key)"
          :data-aos-duration="!isMobile ? 800 : 800"
          data-aos-easing="ease-in"
        />
      </template>
    </flexi-wrapper>

    <!-- Fourth Section HomePage -->
    <flexi-wrapper
      v-if="inputData.homePage"
      :inputData="inputData.homePage.sections[3]"
      :id="inputData.homePage.sections[3].string_id"
    >
      <template v-slot:content="slotProps">
        <div class="flexi-wrapper__section--content--block">
          <div
            v-html="slotProps.inputData.description"
            class="flexi-wrapper__section--content--block__description"
            :data-aos="'fade-down'"
            :data-aos-duration="!isMobile ? 1200 : 800"
            data-aos-easing="ease-in"
          />
          <div class="button-div">
            <button
              @click="goToExternalPage(slotProps.inputData.buttonLink)"
              v-text="slotProps.inputData.buttonText"
              :data-aos="'fade-upp'"
              :data-aos-duration="!isMobile ? 1200 : 100"
              data-aos-easing="ease-in"
            />
          </div>
        </div>
      </template>
    </flexi-wrapper>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FlexiTopCard from "../components/FlexiTopCard.vue";

export default {
  name: "home",
  components: {
    FlexiTopCard
  },
  metaInfo() {
    return {
      title: "VERY VERIFIED",
      meta: [
        {
          name: "description",
          content: "Online course on media literacy"
        },
        {
          name: "og:title",
          content: "VERY VERIFIED"
        },
        {
          name: "og:description",
          content: "Online course on media literacy"
        },
        { name: "og:type", content: "website" },
        {
          name: "og:image",
          content:
            "https://app.imgforce.com/images/user/lI0_1638501999_seo_ks_1.png"
        },
        { name: "twitter:card", content: "summary" },
        {
          name: "twitter:title",
          content: "VERY VERIFIED"
        },
        {
          name: "twitter:description",
          content: "Online course on media literacy"
        },
        {
          name: "twitter:image",
          content:
            "https://app.imgforce.com/images/user/lI0_1638501999_seo_ks_1.png"
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["inputData", "language", "user", "isMobile"])
  },
  data() {
    return {
      secondSection: {
        options: {
          button: false,
          date: false,
          description: false,
          img: true,
          paragraphOn: true,
          subTitle: true,
          titleClickable: false
        }
      },
      thirdSection: {
        options: {
          button: false,
          date: false,
          img: true,
          description: false,
          paragraphOn: true,
          subTitle: false,
          titleClickable: true
        }
      }
    };
  },
  mounted() {
    this.showPage = true;
    setTimeout(() => {
      document.getElementById("top-card").scrollIntoView();
    }, 1000);
  }
};
</script>

<style lang="scss">
// @import "~@schnapsterdog/flexi-vue/src/assets/scss/parts/_media-mixins";
</style>
